import { Row, Col, Form, Button, Input, Checkbox, TimePicker, InputNumber, Upload, Typography, Card } from 'antd';
import {
    PlusCircleOutlined,
    DeleteOutlined,
    CheckSquareTwoTone,
    CloseSquareOutlined,
    PlusOutlined,
    InboxOutlined
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment';
import '../../../../../assets/resources/style/activityForm.css'
import useRequest from '../../../../../hooks/use-request';
import API_CALL from "../../../../../helpers/api";
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import Modal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';
const { Text } = Typography;
const { Dragger } = Upload;
const types = ["video/mp4"];

const getBase64 = (file) => {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
}

const WhackAMole = ({ type, subjectId, activityId, topicId, module, onFormBack, viewAdd, onSubmit }) => {
    const authContext = useContext(AuthContext);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [uploadedImage, setUploadedImage] = useState();
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [scoreValidation, setScoreValidation] = useState(false);
    const [choices, setChoices] = useState([false, false])
    const [form] = Form.useForm();

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleWAM`,
            headers: { "Content-Type": "multipart/form-data" }
        },
        (data) => {
            setMessage(data)
            viewAdd === true && onSubmit()
            setIsLoading(false);
            setIsModalVisible(true);
            setChoices([false, false])
            form.resetFields();
        },
        (err) => {
            console.log(err)
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )
    const format = 'mm:ss';

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        let formData = new FormData();

        console.log(choices)
        let time = moment(values.time._d).format('HH:mm:ss')
        for (let i = 0; i < choices.length; i++) {
            formData.append(
                `${(choices[i] === true && 'correct') ||
                (choices[i] === false && 'incorrect')}`,
                values.choices[i].file
            );
        }
        formData.append("subjectId", subjectId);
        formData.append("topicId", topicId);
        formData.append("directions", values.instruction);
        formData.append("lecture", values?.lecture ? values?.lecture : null);
        formData.append("retryLimit", values.retryLimit);
        formData.append("time", moment.duration(time).asMilliseconds());
        formData.append("scoreValidation", scoreValidation === true ? 1 : 0);
        formData.append("type", type)
        formData.append("image", uploadedImage)
        formData.append("activityId", activityId)
        setIsLoading(true);
        addRequest.sendRequest(formData)
    };

    const handleCancel = () => setPreviewVisible(false);

    useEffect(() => {
        console.log(choices)
    }, [choices])

    const props = {
        accept: ".png, .jpg, .jpeg, .gif, .tiff",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        // return false;
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
        async onPreview(file) {
            setPreviewImage(file.url || file.thumbUrl);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        },
    };

    const normImage = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        if (types.includes(e.fileList[0].type)) {
            e.fileList.shift();
        }
        return e?.fileList;
    };

    const propsImage = {
        name: '.jpg',
        accept: ".jpg",
        multiple: false,
        beforeUpload: (file) => {
            if (types.includes(file.type)) {
                setMessage({ message: 'File is MP4' })
                setIsModalVisible(true);
            }
            return false
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
                setUploadedImage(info.file);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Row>
                <Col>
                    <Button type='primary' onClick={onFormBack}>{`<`}Back to Module</Button>
                </Col>
            </Row>
            <br></br>
            <Form
                name="dynamic_form_item"
                onFinish={onFinish}
                initialValues={{ choices: ["", ""] }}
                form={form}
            >
                <Form.Item
                    label="Instruction"
                    name="instruction"
                    rules={[
                        {
                            required: true,
                            message: "Please input question",
                        },
                    ]}
                >
                    <Input
                        placeholder="Instruction"
                        style={{
                            width: '90%',
                        }}
                    />
                </Form.Item>
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item name="time" label="Time" {...config}>
                            <TimePicker
                                style={{
                                    width: '90%',
                                }}
                                format={format}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            label="Retry Limit"
                            name="retryLimit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Retry limit is required'
                                },
                            ]}
                        >
                            <InputNumber min={1} max={10} style={{
                                width: '90%',
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            label="Score Validation"
                            tooltip="(If checked) Teacher will validate the score"
                        >
                            <Checkbox
                                name='scoreValidation'
                                style={{ float: 'left' }}
                                onChange={(e) => {
                                    setScoreValidation(e.target.checked)
                                }}
                            >
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24} lg={24}>
                        <p>Image: </p>
                        <Form.Item>
                            <Form.Item
                                name="image"
                                label="Upload Image: "
                                valuePropName="fileListImage"
                                getValueFromEvent={normImage}
                                noStyle
                                multiple="false"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Please attach a video."
                            //     },
                            // ]}
                            >
                                <Dragger
                                    {...propsImage}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                        band files
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List
                    name="choices"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <Card
                            size="small"
                            title="Choices"
                            extra={
                                fields.length !== 12 ? <Button type="primary" onClick={() => {
                                    add()
                                    setChoices(
                                        [...choices,
                                            false]
                                    )
                                }}>Add</Button> : null}
                        >
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={true}
                                    key={field.key}
                                    label={"Choice " + (+index + 1)}
                                >
                                    <>
                                        <Row>
                                            <Col>
                                                <li>
                                                    {/* <Text>Choice is correct</Text> */}
                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => {
                                                        let arrayCopy = [...choices]
                                                        arrayCopy[index] = e.target.checked
                                                        // arrayCopy[e.target.id.split("_")[4]] = e.target.checked
                                                        const newForm = form.getFieldsValue('choices');
                                                        newForm.choices[index] = newForm.choices[index]
                                                        setChoices(arrayCopy);
                                                        form.setFieldsValue(newForm.choices);
                                                    }
                                                    } />
                                                    <label htmlFor={`cb` + index}>
                                                        <div className="icon">
                                                            {choices[index] === true ?
                                                                <CheckSquareTwoTone
                                                                    style={{ fontSize: '32px' }}
                                                                /> :
                                                                <CloseSquareOutlined
                                                                    style={{ fontSize: '32px' }}
                                                                />}
                                                        </div>
                                                    </label>
                                                </li>
                                                {/* <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    key={index}
                                                >
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            let arrayCopy = [...choices]
                                                            arrayCopy[e.target.id.split("_")[4]] = e.target.checked
                                                            setChoices(arrayCopy)
                                                        }}
                                                        checked={choices[index]}
                                                        style={{
                                                            backgroundImage: choices[index] === true ? (
                                                                require('../../../../assets/resources/Images/answer_correct.webp')
                                                            ) : (
                                                                require('../../../../assets/resources/Images/answer_wrong.webp')
                                                            )
                                                        }}
                                                    >
                                                    </Checkbox>
                                                </Form.Item> */}
                                            </Col>
                                            <Col>
                                                <Form.Item
                                                    name="upload-form"
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please attach an image.",
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        listType="picture-card"
                                                        {...props}
                                                        maxCount={1}
                                                        name="upload"
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div
                                                                style={{
                                                                    marginTop: 8,
                                                                }}
                                                            >
                                                                Upload
                                                            </div>
                                                        </div>
                                                    </Upload>
                                                </Form.Item>
                                                <Modal
                                                    visible={previewVisible}
                                                    title={previewTitle}
                                                    footer={null}
                                                    onCancel={handleCancel}
                                                >
                                                    <img
                                                        alt="example"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        src={previewImage}
                                                    />
                                                </Modal>
                                            </Col>
                                            <Col>
                                                {fields.length !== 1 ? (<DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name)
                                                        let formList = [...choices];
                                                        formList.splice(index, 1);
                                                        setChoices(formList);
                                                    }}
                                                />) : (
                                                    <DeleteOutlined
                                                        className="dynamic-delete-button"
                                                        disabled
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                </Form.Item>
                            ))}
                        </Card>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form >
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleOk}
                title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
            >
                <Text>{message?.message}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading} />
        </>
    )
}

export default WhackAMole