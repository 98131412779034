import React, { useState, useEffect, useContext } from 'react'
import FormInput from './Form'
import { Form, Col, Input, Select } from 'antd'
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
import useRequest from '../../../../hooks/use-request';
import API_CALL from '../../../../helpers/api';
import { AuthContext } from '../../../../store/use-context';
const { Option } = Select;

const School = () => {
    const authContext = useContext(AuthContext);
    const [form] = Form.useForm();
    const [institution, setInstitution] = useState(null);
    const [isLoading, setIsLoading] = useState(authContext.userType === '0' ? true : false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);

    const getInstitutions = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getInstitutions`,
        },
        (data) => {
            setInstitution(data);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addSchool`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    useEffect(() => {
        authContext.userType === '0' && getInstitutions.sendRequest();
    }, [])

    if (authContext.userType === '0' && isLoading) {
        return <CustomSpinnerModal isLoading={isLoading} />
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setMessage(null);
    };

    const onFinish = (values) => {
        console.log(values)
        addRequest.sendRequest({
            institutionIdNumber: values.institutionIdNumber,
            schoolIdNumber: values.School,
            name: values.name,
            address: values.address,
            email: values.email,
            contactNumber: values.contactNumber,
            headName: values.headName,
            headContactNumber: values.prefix + values.headContactNumber
        }, null)
        setIsLoading(true);
    };

    return (
        <FormInput
            schoolName={'School '}
            handleOk={handleOk}
            onFinish={onFinish}
            isLoading={isLoading}
            isModalVisible={isModalVisible}
            message={message}
            form={form}
        >
            {authContext.userType === '0' && <Col xs={24} md={12} lg={8}>
                <Form.Item
                    name={"institutionIdNumber"}
                    label={"Institution Id Number"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ margin: 5 }}
                >
                    <Select
                        showSearch
                        placeholder="Select school"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {institution.map((obj, index) => {
                            return (
                                <Option key={index} value={obj.institutionId}>{obj.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Col>}
            <CustomSpinnerModal isLoading={isLoading} />

        </FormInput>
    )
}

export default School