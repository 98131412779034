import React, {
    useState,
    useEffect,
    useContext
} from "react";
import useRequest from "../../../hooks/use-request";
import {
    Button,
    Tag,
    Dropdown,
    Menu,
    Space,
    Row,
    Col,
    Card,
    InputNumber,
    Input,
    Form,
    Typography,
    Popconfirm,
    Select
} from "antd";
import { Colors } from "../../../assets/Constants/Colors";
import {
    FileExcelOutlined,
    SearchOutlined,
    EditOutlined,
    DownOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../store/use-context";
const { Option } = Select



const Section = () => {
    const [section, setSection] = useState([]);
    const [searchSectionDataSource, setSearchSectionDataSource] = useState(section);
    const [teacher, setTeacher] = useState([]);
    const [isTeacherLoading, setIsTeacherLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);
    const getSections = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSections`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                console.log(e)
                return e.status === 0;
            })
            setSection(sortActive);
            setSearchSectionDataSource(sortActive);
            setIsLoading(false);
            setIsModalVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getUsers = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/getUsers`,
        },
        (data) => {
            let teachers = data.filter((e) => {
                return e.userType === "4" && e.status === 0;
            })
            setTeacher(teachers)
            setMessage(data);
            setIsTeacherLoading(false);
        },
        (err) => {
            setIsTeacherLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const editSection = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/editSection`,
        },
        (data) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(data);
            getSections.sendRequest();
        },
        (err) => {
            setIsModalVisible(true);
            setIsLoading(false);
            setMessage(err)
        }
    )

    const deactivateSection = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/removeSection`,
        },
        (data) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(data);
            getSections.sendRequest();
        },
        (err) => {
            setIsModalVisible(true);
            setIsLoading(false);
            setMessage(err)
        }
    )

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onEditSection = ({ data, sectionId }) => {
        setIsLoading(true)
        editSection.sendRequest({ ...data }, { sectionId });
    }

    const onDeactivateSection = (sectionId) => {
        setIsLoading(true)
        deactivateSection.sendRequest(null, { sectionId });
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = (inputType === 'number' && <InputNumber min={1} max={12} />) ||
            (inputType === 'text' && <Input />) ||
            (inputType === 'select' && teacher && <Select
                showSearch
                placeholder="Select adviser"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
                {teacher.map((obj, index) => {
                    return (
                        <Option key={index} value={obj.accountId}>{obj.lastName + ', ' + obj.firstName}</Option>
                    )
                })}
            </Select>
            );
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    useEffect(() => {
        getSections.sendRequest();
        // getUsers.sendRequest();
    }, [])

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({
            name: '',
            gradeLevel: '',
            adviserId: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const columns = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '150px',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={async () => {
                                await onEditSection({ data: form.getFieldsValue(), sectionId: record.sectionId })
                                await setEditingKey('');
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Dropdown overlay={
                        <Menu
                            items={
                                [
                                    {
                                        label: 'Edit',
                                        key: '1',
                                        icon: <EditOutlined />,
                                        onClick: () => {
                                            edit(record)
                                        }
                                    },
                                    // (record.status === 1 && {
                                    //     label: 'Activate',
                                    //     key: '2',
                                    //     icon: <UserOutlined />,
                                    // }) ||
                                    (record.status === 0 && {
                                        label: 'Deactivate',
                                        key: '3',
                                        icon: <DeleteOutlined />,
                                        onClick: () => onDeactivateSection(record.sectionId)
                                    })
                                ]}
                        />
                    }
                        trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            render: (tags) => (
                <span>
                    {
                        (tags === 0 && <Tag color={Colors.blue} key={tags}>
                            {'ACTIVE'}
                        </Tag>) ||
                        (tags === 1 && <Tag color={Colors.grey} key={tags}>
                            {'DEACTIVATED'}
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '150px',
            editable: true,
        },
        {
            title: 'Grade Level',
            dataIndex: 'gradeLevel',
            width: '150px',
            editable: true,
        },
        {
            title: 'Adviser',
            dataIndex: 'adviserId',
            width: '150px',
            render: (_, record, item) => {
                return record.adviserLastName + ', ' + record.adviserFirstName
            },
            editable: true,
        },
        {
            title: 'School',
            dataIndex: 'schoolName',
            width: '150px',
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'gradeLevel' && 'number' || col.dataIndex === 'name' && 'text' || col.dataIndex === 'adviserId' && 'select',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Card title="Section" extra={<Link to="/section/add"><Button type="primary">Add</Button></Link>}>
            <Row justify="space-between">
                <Col span={7}>
                    <ExportTableButton
                        dataSource={section}
                        columns={columns}
                        btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                        showColumnPicker
                    >
                        Export to CSV
                    </ExportTableButton>
                </Col>
                <Col span={7} offset={8}>
                    <SearchTableInput
                        columns={columns}
                        dataSource={section}
                        setDataSource={setSearchSectionDataSource}
                        inputProps={{
                            placeholder: "Search this table...",
                            prefix: <SearchOutlined />,
                        }}
                    />
                </Col>
            </Row>
            <Form form={form} component={false}>
                <Table
                    dataSource={searchSectionDataSource}
                    columns={mergedColumns}
                    rowKey="id"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    pagination={{
                        // paginationSize: 10,
                        onChange: cancel,
                    }}
                    scroll={{
                        y: 350,
                    }}
                    loading={isLoading}
                />
            </Form>
            {/* <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={(isModalClicked.id === 2 && 'Activate') || (isModalClicked.id === 3 && 'Deactivate')}
            >
                <Text>Are you sure you want to {(isModalClicked.id === 2 && 'activate') || (isModalClicked.id === 3 && 'deactivate')} this account ?</Text>
            </Modal> */}
            {/* <CustomSpinnerModal
                isLoading={isModalLoading}
            /> */}
        </Card>
    )
}

export default Section