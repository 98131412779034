import { Card } from 'antd';
const { Meta } = Card;

const HoverableCard = ({ logo, title, description, onClick }) => (
    <Card
        hoverable
        style={{
            width: 200,
        }}
        cover={<img alt="example" src={logo ? logo : undefined} />}
        onClick={onClick}
    >
        <Meta title={title} description={description} />
    </Card>
);

export default HoverableCard;