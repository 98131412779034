let refregion = require('./refregion.json')
let refprovince = require('./refprovince.json')
let refcitymun = require('./refcitymun.json')
let refbrgy = require('./refbrgy.json')

refregion = refregion.map(obj => { return { ...obj, label: obj.regDesc, value: obj.regCode } })
    .sort(function (a, b) {
        if (a.regDesc < b.regDesc) { return -1; }
        if (a.regDesc > b.regDesc) { return 1; }
        return 0;
    })

refprovince = refprovince.map(obj => { return { ...obj, label: obj.provDesc, value: obj.provCode } })
    .sort(function (a, b) {
        if (a.provDesc < b.provDesc) { return -1; }
        if (a.provDesc > b.provDesc) { return 1; }
        return 0;
    })

refcitymun = refcitymun.map(obj => { return { ...obj, label: obj.citymunDesc, value: obj.citymunCode } })
    .sort(function (a, b) {
        if (a.citymunDesc < b.citymunDesc) { return -1; }
        if (a.citymunDesc > b.citymunDesc) { return 1; }
        return 0;
    })

refbrgy = refbrgy.map(obj => { return { ...obj, label: obj.brgyDesc, value: obj.brgyCode } })
    .sort(function (a, b) {
        if (a.brgyDesc < b.brgyDesc) { return -1; }
        if (a.brgyDesc > b.brgyDesc) { return 1; }
        return 0;
    })

export {
    refregion, refprovince, refcitymun, refbrgy
}