const convertImgToBase64 = (url, callback, outputFormat) => {
	return new Promise((resolve, reject) => {
		var canvas = document.createElement('CANVAS');
		var ctx = canvas.getContext('2d');
		var img = new Image;
		img.crossOrigin = 'Anonymous';
		img.onload = function () {
			canvas.height = img.height;
			canvas.width = img.width;
			ctx.drawImage(img, 0, 0);
			var dataURL = canvas.toDataURL('image/png');
			// callback.call(this, dataURL);
			resolve(dataURL);
			// Clean up
			canvas = null;
		};
		img.src = url;
	})
}

export default convertImgToBase64;