import {
    Row,
    Button,
    Col,
    Form,
    Input,
    Select,
    DatePicker,
    Typography,
} from 'antd'
import { useEffect, useState, useContext } from 'react'
import '../../../../assets/resources/style/form.css'
import moment from 'moment';
import useRequest from '../../../../hooks/use-request';
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
import Modal from '../../../Layout/Modal/Modal';
import API_CALL from "../../../../helpers/api";
import { AuthContext } from '../../../../store/use-context';
const { Text } = Typography
const { Option } = Select;

const School = () => {
    const [school, setSchool] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const handleOk = () => {
        setIsModalVisible(false);
        setMessage(null);
    };

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/addAdminSchoolUser`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSchools = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSchools`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setSchool(sortActive);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    );

    useEffect(() => {
        getSchools.sendRequest();
    }, [])

    if (isLoading) {
        return <CustomSpinnerModal isLoading={isLoading} />
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="63">+63</Option>
            </Select>
        </Form.Item>
    );

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select date!',
            },
        ],
    };

    const onFinish = (values) => {
        addRequest.sendRequest({
            firstName: values.firstName,
            middleName: values?.middleName ? values?.middleName : '',
            lastName: values.lastName,
            email: values.email,
            mobileNumber: values.prefix + values.phone,
            birthdate: moment(values.birthdate._d).format('yyyy-MM-DD'),
            schoolId: values?.schoolId ? values?.schoolId : '',
        }, null)
        setIsLoading(true);
    };

    return (
        <>
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    prefix: '63',
                }}
                scrollToFirstError
            >
                <Row className='row'>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="firstName"
                            label="First Name"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name',
                                },
                            ]}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="middleName"
                            label="Middle Name"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="lastName"
                            label="Last Name"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your last name',
                                },
                            ]}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="phone"
                            label="Mobile Number"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your phone number!',
                                },
                            ]}
                            style={{ margin: 5 }}
                        >
                            <Input
                                addonBefore={prefixSelector}
                                style={{
                                    width: '100%',
                                }}
                                maxLength={10}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="email"
                            label="E-mail"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                },
                            ]}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="birthdate"
                            label="Birthdate"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            {...config}
                            style={{ width: '100%' }}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="schoolId"
                            label="School"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                placeholder="Select school"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {school.map((obj, index) => {
                                    return (
                                        <Option key={index} value={obj.schoolId}>{obj.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='button'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
                <Modal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </Modal>
            </Form>
        </>
    )
}

export default School