import React, { useState, useEffect, useContext, useCallback } from "react";
import useRequest from "../../../hooks/use-request";
import {
    Button,
    Tag,
    Dropdown,
    Menu,
    Space,
    Row,
    Col,
    Typography,
    Input,
    InputNumber,
    Popconfirm,
    Card,
    Drawer,
    Form,
    Image,
    Upload
} from "antd";
import { Colors } from "../../../assets/Constants/Colors";
import {
    FileExcelOutlined,
    SearchOutlined,
    EditOutlined,
    FundViewOutlined,
    DownOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import CustomSpinnerModal from "../../Layout/Modal/Spinner";
import { Link } from "react-router-dom";
import AddTopic from "./AddTopic";
import Topic from "./Topic";
import CustomModal from "../../Layout/Modal/Modal";
import { AuthContext } from "../../../store/use-context";
const { Text } = Typography

const props = {
    accept: ".png, .jpg, .jpeg, .gif, .tiff",
    multiple: false,
    beforeUpload: (file) => {
        const isLt2M = file.size / 300 / 300 < 2;
        if (!isLt2M) {
            console.log('test')
        }
        return false;
    },
    onChange(info) {
        if (info.file.status !== "removed") {
            let reader = new FileReader();
            reader.readAsDataURL(info.file);
        }
    },
};

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' && <InputNumber min={1} max={4} /> || inputType === 'text' && <Input /> || inputType === 'image' && <Upload
        listType="picture-card"
        {...props}
        maxCount={1}
        name="upload"
        defaultFileList={record.image ? [{
            uid: '-1',
            name: 'abc.png',
            status: 'done',
            url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
            // url: 'http://' + window.location.hostname + ':9003/' + record.image,
        }] : null}
        style={{ width: '32px', height: '32px' }}
        rules={[
            {
                required: true,
                message: 'Please attach an image.',
            },
        ]}
    >
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    </Upload>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Subject = () => {
    const [subject, setSubject] = useState(null);
    const [searchSubjectDataSource, setSearchSubjectDataSource] = useState(subject);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubject, setIsLoadingSubject] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageTable, setMessageTable] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTableModalVisible, setIsTableModalVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [parentMenuTitle, setParentMenuTitle] = useState(null);
    const [subjectId, setSubjectId] = useState(null);
    const [topics, setTopics] = useState(null);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const getSubjects = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/getSubjects`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setSubject(sortActive);
            setSearchSubjectDataSource(sortActive);
            setMessage(sortActive);
            setIsLoadingSubject(false);
            // setIsModalVisible(false);
        },
        (err) => {
            setIsLoadingSubject(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getTopics = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/data/getTopics`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setTopics(sortActive);
            setMessage(sortActive);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const addTopicRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addTopic`,
        },
        (data) => {
            setVisible(false);
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setVisible(false);
            setIsLoading(false);
            setMessage(err.response.data)
            setIsModalVisible(true);
        }
    )

    const deactivateSubject = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/removeSubject`,
        },
        (data) => {
            setVisible(false);
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            getSubjects.sendRequest();
        },
        (err) => {
            setVisible(false);
            setIsLoading(false);
            setMessage(err.response.data)
            setIsModalVisible(true);
        }
    )

    const deactivateTopic = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/removeTopic`,
        },
        (data) => {
            setIsTableLoading(false);
            setVisible(false);
            setMessageTable(data);
            setIsTableModalVisible(true);
            getTopics.sendRequest(null, { subjectId: subjectId })
        },
        (err) => {
            setVisible(false);
            setIsTableLoading(false);
            setMessageTable(err)
            setIsTableModalVisible(true);
        }
    )

    const editTopic = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/editTopic`,
        },
        (data) => {
            setIsTableLoading(false);
            setVisible(false);
            setMessageTable(data);
            setIsTableModalVisible(true);
            getTopics.sendRequest(null, { subjectId: subjectId })
        },
        (err) => {
            setVisible(false);
            setIsTableLoading(false);
            setMessageTable(err)
            setIsTableModalVisible(true);
        }
    )

    const editSubject = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/editSubject`,
        },
        (data) => {
            setIsLoadingSubject(false);
            setIsModalVisible(true);
            setMessage(data);
            getSubjects.sendRequest();
        },
        (err) => {
            setIsModalVisible(true);
            setIsLoadingSubject(false);
            setMessage(err)
        }
    )

    const handleOkTable = () => {
        setIsTableModalVisible(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const memorizedValue = useCallback(() => {
        if (topics && parentMenuTitle && subjectId) {
            showDrawer();
        } else {
            getSubjects.sendRequest();
        }
    })

    useEffect(() => {
        memorizedValue()
    }, [topics])


    const onViewTopic = async (title, subject) => {
        await getTopics.sendRequest(null, { subjectId: subject })
        setParentMenuTitle(title);
        setSubjectId(subject)
    }

    const onEditSubject = ({ data, subjectId }) => {
        setIsLoadingSubject(true)
        let formData = new FormData();
        formData.append("gradeLevel", data.gradeLevel ? data.gradeLevel : '');
        formData.append("name", data.name ? data.name : '');
        formData.append("description", data.description ? data.description : '');
        formData.append("image", data.image?.fileList?.[0] === undefined ? '' : data.image?.fileList[0].originFileObj);
        editSubject.sendRequest(formData, { subjectId })
    }

    const onEditTopic = ({ data, topicId }) => {
        setIsTableLoading(true)
        editTopic.sendRequest({ title: data.title, description: data.description, quarter: data.quarter }, { topicId })
    }

    const onDeactivate = (subjectId) => {
        setIsLoading(true)
        deactivateSubject.sendRequest(null, { subjectId })
    }

    const onDeactivateTopic = (topicId) => {
        setIsTableLoading(true)
        deactivateTopic.sendRequest(null, { topicId })
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setParentMenuTitle(null);
        setSubjectId(null);
    };

    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onFinishTopic = (values) => {
        addTopicRequest.sendRequest({
            subjectId: subjectId,
            title: values.title,
            description: values.description,
            quarter: values.quarter
        }, null);
        setIsLoading(true);
        setChildrenDrawer(false);
    }

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({
            name: '',
            gradeLevel: '',
            description: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const columns = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '100px',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={async () => {
                                await onEditSubject({ data: form.getFieldsValue(), subjectId: record.subjectId })
                                await setEditingKey('');
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Dropdown overlay={
                        <Menu
                            items={
                                [
                                    {
                                        label: 'View',
                                        key: '0',
                                        icon: <FundViewOutlined />,
                                        onClick: () => {
                                            onViewTopic(record.name, record.subjectId);
                                        }
                                    },
                                    {
                                        label: 'Edit',
                                        key: '1',
                                        icon: <EditOutlined />,
                                        onClick: () => {
                                            edit(record)
                                        }
                                    },
                                    {
                                        label: 'Remove',
                                        key: '2',
                                        icon: <EditOutlined />,
                                        onClick: () => {
                                            onDeactivate(record.subjectId);
                                        }
                                    },
                                    (
                                        (record.status === 'Deactivated' && {
                                            label: 'Activate',
                                            key: '2',
                                        }) ||
                                        (record.status === 'Active' && {
                                            label: 'Deactivate',
                                            key: '3',
                                        })
                                    )
                                ]}
                        />
                    }
                        trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }
        },
        {
            title: 'Subject ID',
            key: 'subjectId',
            dataIndex: 'subjectId',
            hidden: true,
        },
        {
            title: 'Image',
            key: 'image',
            dataIndex: 'image',
            width: '100px',
            editable: true,
            render: (item) => {
                return item ? (
                    <Image
                        width={100}
                        src={process.env.REACT_APP_SCHOOL_BOOK + item}
                    // src={'http://' + window.location.hostname + ':9003/' + item}
                    />
                ) : (
                    <Typography>No data available</Typography>
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '150px',
            editable: true,
        },
        {
            title: 'Grade Level',
            dataIndex: 'gradeLevel',
            width: '150px',
            editable: true,
            filters: [
                {
                    text: 1,
                    value: 1,
                },
                {
                    text: 2,
                    value: 2,
                },
                {
                    text: 3,
                    value: 3,
                },
                {
                    text: 4,
                    value: 4,
                },
                {
                    text: 5,
                    value: 5,
                },
                {
                    text: 6,
                    value: 6,
                },
                {
                    text: 7,
                    value: 7,
                },
                {
                    text: 8,
                    value: 8,
                },
                {
                    text: 9,
                    value: 9,
                },
                {
                    text: 10,
                    value: 10,
                },
            ],
            onFilter: (value, record) => record.gradeLevel === value,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '150px',
            editable: true,
        },
        // {
        //     title: 'Adviser',
        //     dataIndex: 'adviserId',
        //     width: '150px',
        // },
        // {
        //     title: 'School',
        //     dataIndex: 'schoolName',
        //     width: '150px',
        // },
    ].filter(item => !item.hidden);

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'gradeLevel' && 'number' || col.dataIndex === 'image' && 'image' || col.dataIndex === 'name' && 'text' || col.dataIndex === 'description' && 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <Card
                title="Subject"
                extra={
                    <Link to={'/subject/add'}>
                        <Button type="primary">Add</Button>
                    </Link>
                }
            >
                <Row justify="space-between">
                    <Col span={7}>
                        <ExportTableButton
                            dataSource={subject}
                            columns={columns}
                            btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                            showColumnPicker
                        >
                            Export to CSV
                        </ExportTableButton>
                    </Col>
                    <Col span={7} offset={8}>
                        <SearchTableInput
                            columns={columns}
                            dataSource={subject}
                            setDataSource={setSearchSubjectDataSource}
                            inputProps={{
                                placeholder: "Search this table...",
                                prefix: <SearchOutlined />,
                            }}
                        />
                    </Col>
                </Row>
                <Form form={form} component={false}>
                    <Table
                        dataSource={searchSubjectDataSource}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        rowKey="id"
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        pagination={{
                            // paginationSize: 10,
                            onChange: cancel,
                        }}
                        scroll={{
                            y: 350,
                        }}
                        loading={isLoadingSubject}
                    />
                </Form>
            </Card>
            <Drawer
                title={parentMenuTitle}
                placement="bottom"
                closable={false}
                onClose={onClose}
                visible={visible}
                key={parentMenuTitle}
                className="demo"
                height={childrenDrawer ? "70%" : "90%"}
                extra={
                    <Button type="primary" onClick={showChildrenDrawer}>
                        Add
                    </Button>
                }
            >
                {topics && <Topic
                    topics={topics}
                    deactivateTopic={onDeactivateTopic}
                    editTopic={onEditTopic}
                    isLoading={isTableLoading}
                    message={messageTable}
                    visible={isTableModalVisible}
                    handleOk={handleOkTable}
                />}
                <Drawer
                    title={parentMenuTitle}
                    placement="bottom"
                    width={320}
                    closable={false}
                    onClose={onChildrenDrawerClose}
                    visible={childrenDrawer}
                >
                    <AddTopic
                        form={form}
                        onFinishTopic={onFinishTopic}
                    />
                </Drawer>
            </Drawer>
            <CustomModal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
            >
                <Text>{message?.message}</Text>
            </CustomModal>
            <CustomSpinnerModal
                isLoading={isLoading}
            />
        </>
    )
}

export default Subject