import { Layout, Row, Col, Select, Typography, Card, Button } from 'antd';
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import API_CALL from '../../../helpers/api';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import Institution from './Forms/Institution';
import School from './Forms/School';
import { AuthContext } from '../../../store/use-context';
const { Option } = Select;
const { Content } = Layout;
const { Text } = Typography;

const Add = () => {
    const authContext = useContext(AuthContext)
    const [selectedForm, setSelectedForm] = useState(authContext.userType === 'superadmin' ? 1 : 2);

    const onChange = (value) => {
        setSelectedForm(+value);
    };

    return (
        <Card title={
            <Link to="/school">
                <Button type="primary">{"< Back to School"}</Button>
            </Link>
        }
        >
            <Row>
                <Text style={{ textAlign: 'center' }}>Scool Type: </Text>
                <Select
                    showSearch
                    placeholder="Select a user"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{ width: 200 }}
                    value={selectedForm}
                >
                    {authContext.userType === '0' && <Option value={1}>Institution</Option>}
                    <Option value={2}>School</Option>
                </Select>
            </Row>
            <Col>
                {(authContext.userType === '0' && selectedForm === 1) && <Institution />}
                {selectedForm === 2 && <School />}
            </Col>
        </Card>
    )
}

export default Add