import { useState, useContext } from "react";
import { AuthContext } from "../store/use-context";
import axios from "axios";
import 'dotenv'
const useRequest = (requestConfig, callback, errorCallback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const authContext = useContext(AuthContext);

  const sendRequest = async (data = null, params = null) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await axios({
        method: requestConfig.method,
        // url: requestConfig.baseURL || 'http://' + window.location.hostname + ':9003/' + requestConfig.endPoint,
        url: requestConfig.baseURL || process.env.REACT_APP_SCHOOL_BOOK + requestConfig.endPoint,
        data: data ? data : requestConfig.data,
        headers: {
          ...requestConfig.headers,
          authorization: "Bearer " + authContext.token,
        },
        params: params ? params : requestConfig.params
      });
      setIsLoading(false);
      callback(result.data);
    } catch (err) {
      setIsLoading(false);
      setError(err.response?.data?.message || err);
      errorCallback(err);
    }
  };

  return {
    isLoading,
    error,
    sendRequest,
    setError,
  };
};

export default useRequest;
