import { Form, Row, Col, Input, Select, Typography, Button, Card, Upload, message as ErrorMessage, Space } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import {
  PlusOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom'
import useRequest from '../../../hooks/use-request'
import Modal from '../../Layout/Modal/Modal'
import CustomSpinnerModal from '../../Layout/Modal/Spinner'
import { AuthContext } from '../../../store/use-context'
const { Option } = Select
const { Text } = Typography
const { TextArea } = Input

const props = {
  multiple: false,
  beforeUpload: (file) => {
    const isLt2M = file.size / 300 / 300 < 2;
    if (!isLt2M) {
      console.log('test')
    }
    return false;
  },
  onChange(info) {
    if (info.file.status !== "removed") {
      let reader = new FileReader();
      reader.readAsDataURL(info.file);
    }
  },
};

const EditLibrary = ({ item, form }) => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subject, setSubject] = useState([]);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
  // const [form] = Form.useForm();
  const authContext = useContext(AuthContext);

  const addRequest = useRequest(
    {
      method: "POST",
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addFileLibrary`,
    },
    (data) => {
      setMessage(data);
      setIsLoading(false);
      setIsModalVisible(true);
      form.resetFields();
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const handleOk = () => {
    setIsModalVisible(false);
  }

  const getSubject = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjectsPerGrade`
    },
    (data) => {
      const filteredData = data.filter((subject) => {
        return subject.status === 0
      })
      setSubject(filteredData)
      setIsLoadingActivity(false);
    },
    (err) => {
      setIsLoadingActivity(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const gradeLevelOnChange = (value) => {
    if (value === "") {
      setSubject([]);
      setSelectedSubjectFilter(null);
      form.setFieldsValue({ subjectId: "" });
    } else {
      setIsLoadingActivity(true)
      setSelectedSubjectFilter(null);
      getSubject.sendRequest(null, { gradeLevel: value })
      form.setFieldsValue({ subjectId: "" });
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      title: item?.title,
      subjectId: item?.subjectId,
      description: item?.description,
      credits: item?.credits,
      reference: item?.reference,
      description: item?.description,
    })
    form.setFieldsValue({ "gradeLevelFilter": item.gradeLevel ? String(item.gradeLevel) : "" })
    if (item.gradeLevel) {
      gradeLevelOnChange(String(item.gradeLevel))
    }
    form.setFieldsValue({ subjectId: item.subjectId })
  }, [item])

  return (
    <Form
      form={form}
    >
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Title: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="title"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
                rules={[
                  {
                    required: true,
                    message: `Please input your title`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Grade Level: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="gradeLevelFilter"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
              >
                <Select
                  placeholder="Select a grade"
                  style={{ width: '100%' }}
                  onChange={gradeLevelOnChange}
                >
                  <Option value="">All</Option>
                  <Option value="1">Grade 1</Option>
                  <Option value="2">Grade 2</Option>
                  <Option value="3">Grade 3</Option>
                  <Option value="4">Grade 4</Option>
                  <Option value="5">Grade 5</Option>
                  <Option value="6">Grade 6</Option>
                  <Option value="7">Grade 7</Option>
                  <Option value="8">Grade 8</Option>
                  <Option value="9">Grade 9</Option>
                  <Option value="10">Grade 10</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Subject: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="subjectId"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
              >
                <Select
                  placeholder="Select a subject"
                  style={{ width: '100%' }}
                  loading={isLoadingActivity}
                >
                  <Option value="" disabled>Select a subject</Option>
                  {subject?.map((sub, i) => (
                    <Option key={i} value={sub.subjectId}>{sub.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Description: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
                rules={[
                  {
                    required: true,
                    message: `Please input your description`,
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Credits: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="credits"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Reference: </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="reference"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '600px' }}
              >
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>File: {item?.file ? <a
            href={process.env.REACT_APP_SCHOOL_BOOK + item?.file}
            target="_blank"
            // href={'http://' + window.location.hostname + ':9003/' + item}
            download="file"
          >View file</a> : "No Uploaded File."} </Text>
        </Col>
        <Space>
          <Col style={{ float: 'left' }}>
            <div style={{ maxWidth: '600px', float: 'left' }}>
              <Form.Item
                name="file"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ margin: 5 }}
              >
                <Upload
                  listType="picture-card"
                  {...props}
                  maxCount={1}
                  name="upload"
                  style={{ width: '32px', height: '32px' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please attach an file.',
                    },
                  ]}
                >
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload New File
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </Space>
      </Row>
      <br />
    </Form>
  )
}

export default EditLibrary