let API_CALL;

const getData = JSON.parse(localStorage.getItem("userData"));

if (+getData?.userType === 0) {
    API_CALL = 'superadmin'
}

if (+getData?.userType === 1) {
    API_CALL = 'admin-insti'
}

if (+getData?.userType === 2) {
    API_CALL = 'admin-school'
}

if (+getData?.userType === 3) {
    API_CALL = 'encoder'
}

export default API_CALL