import { Form, Row, Checkbox, Input, Typography, Upload, Button, Card, Radio, Col } from 'antd'
import {
  PlusOutlined,
  DeleteOutlined,
  InboxOutlined,
  CheckSquareTwoTone,
  CloseSquareOutlined
} from '@ant-design/icons';
import React, { useState, useContext } from 'react'
import API_CALL from '../../../../../helpers/api';
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import CustomModal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';
const { Text } = Typography;

const DDQuestion = ({ moduleId, module }) => {
  const authContext = useContext(AuthContext);
  const [choices, setChoices] = useState([false, false])
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState([[1], [1]]);

  const addRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/addModuleDDQuestion`,
    },
    (data) => {
      setMessage(data);
      setIsModalVisible(true);
      setIsLoading(false);
      setChoices([false, false])
      form.resetFields();
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const handleOk = () => setIsModalVisible(false)
  const handleCancel = () => setIsModalVisible(false)

  const onAddKey = () => {
    setValue([...value, [1]])
  }

  const onRemoveKey = (index) => {
    let formList = [...value];
    formList.splice(index, 1);
    setValue(formList);
  }

  const onAddChoices = (index) => {
    let formList = [...value]
    formList[index] = [...formList[index], 1]
    setValue(formList)
  }

  const onRemoveChoices = (index, idx) => {
    let formList = [...value];
    formList[index].splice(idx, 1);
    setValue(formList);
  }

  const onChangeRadio = (e, index, idx) => {
    let arrayValue = [...value]
    arrayValue[index][idx] = e.target.value
    setValue(arrayValue)
    let arrayChoices = [...value]
    arrayChoices[index] = ''
    const newForm = form.getFieldsValue();
    console.log(newForm?.key[index])
    newForm.key[index].choice[idx] = ''
    if (e.target.value === 2) {
      form.setFieldsValue(newForm);
    }
  }

  const onFinish = (values) => {
    console.log('Received values of form:', values);
    let formData = new FormData();

    const answer = values?.key?.map(({ choice, ...rest }) => ({ [rest.keyName]: choice }))
    const answers = answer.map((obj, index) => {
      const key = Object.keys(obj)[0];
      const choices = obj[key].map((item, index) => {
        console.log(item)
        if (typeof item.choice === 'string') {
          return item.choice; // If it's a string, return it as is
        } else if (typeof item.choice === 'object') {
          formData.append([key], item.choice ? item.choice?.file : '');
          return { index };
        }
      })

      return { [key]: choices };
    })

    formData.append("answers", JSON.stringify(answers));
    formData.append("question", values.question);
    setIsLoading(true);
    addRequest.sendRequest(formData, { moduleId: moduleId })
  };

  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const props = {
    accept: ".png, .jpg, .jpeg, .gif, .tiff",
    multiple: false,
    beforeUpload: (file) => {
      const isLt2M = file.size / 300 / 300 < 2;
      if (!isLt2M) {
        console.log('test')
      }
      return false;
    },
    onChange(info) {
      if (info.file.status !== "removed") {
        let reader = new FileReader();
        reader.readAsDataURL(info.file);
      }
    },
    // async onPreview(file) {
    // setPreviewImage(file.url || file.thumbUrl);
    // setPreviewVisible(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // },
  };

  return (
    <>
      <div className="heading">
        <h1>{module}</h1>
      </div>
      <Form
        name="dynamic_form_item"
        onFinish={onFinish}
        initialValues={{ key: ["", ""], choices: ["", ""] }}
        form={form}
      >
        {/* <Form.Item
          name="image"
          label="Upload Image: "
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
          multiple="false"
        >
          <Upload.Dragger
            {...props}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Upload.Dragger>
        </Form.Item> */}
        <br /><br />
        <Form.Item
          name="question"
          label="Question"
          rules={[
            {
              required: true,
              message: 'Please input question',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.List
          name="key"
        >
          {(fields, { add, remove }, { errors }) => (
            <Card
              size="small"
              title="Boxes"
              extra={
                fields.length !== 4 ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      add();
                      onAddKey();
                    }}
                  >
                    Add
                  </Button>
                ) : (null)
              }
            >
              {fields.map((field, index) => (
                <>
                  <Row>
                    <Form.Item
                      {...field}
                      name={[field.name, "keyName"]}
                      label={'Key ' + (+index + 1)}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input text or delete this field.",
                        },
                      ]}
                      style={{
                        width: '80%',
                      }}
                    >
                      <Input
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    {fields.length !== 2 ? (<DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                        onRemoveKey(index);
                      }}
                    />) : (
                      <DeleteOutlined
                        className="dynamic-delete-button"
                        disabled
                      />
                    )}
                  </Row>
                  <Form.List
                    name={[field.name, 'choice']}
                    initialValue={[""]}
                  >
                    {(choices, { add, remove }, { errors }) => (
                      <>
                        <Card
                          size="small"
                          title="Choices"
                        >
                          {choices.map((choice, idx) => (
                            <Row>
                              <Col>
                                <Form.Item
                                  {...choice}
                                  validateTrigger={['onChange', 'onBlur']}
                                  key={`radio` + idx}
                                >
                                  <Text>Type of choice: </Text>
                                  <Radio.Group onChange={(e) => onChangeRadio(e, index, idx)} value={value[index][idx]}>
                                    <Radio value={1}>Text</Radio>
                                    <Radio value={2}>Image</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              {value[index][idx] === 2 ? (
                                <Form.Item
                                  {...choice}
                                  name={[choice.name, 'choice']}
                                  label={'Choice ' + (+idx + 1)}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                  style={{
                                    width: '80%',
                                  }}
                                >
                                  <Upload
                                    listType="picture-card"
                                    {...props}
                                    maxCount={1}
                                    name="upload"
                                    style={{ width: '32px', height: '32px' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please attach an image.',
                                      },
                                    ]}
                                  >
                                    <div>
                                      <PlusOutlined />
                                      <div
                                        style={{
                                          marginTop: 8,
                                        }}
                                      >
                                        Upload
                                      </div>
                                    </div>
                                  </Upload>
                                </Form.Item>
                              ) : (
                                <Form.Item
                                  {...choice}
                                  name={[choice.name, 'choice']}
                                  label={'Choice ' + (+idx + 1)}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "Please input text or delete this choice.",
                                    },
                                  ]}
                                  style={{
                                    width: '80%',
                                  }}
                                >
                                  <Input
                                    style={{
                                      width: '100%',
                                    }}
                                  />
                                </Form.Item>
                              )}
                              {choices.length !== 1 ? (<DeleteOutlined
                                className="dynamic-delete-button"
                                onClick={() => {
                                  remove(choice.name);
                                  onRemoveChoices(index, idx)
                                }}
                              />) : (
                                <DeleteOutlined
                                  className="dynamic-delete-button"
                                  disabled
                                />
                              )}
                            </Row>
                          ))}
                          {choices.length !== 4 ? (
                            <Button
                              type="primary"
                              onClick={() => {
                                add();
                                onAddChoices(index);
                              }}
                              style={{
                                float: 'left'
                              }}
                            >
                              Add
                            </Button>
                          ) : null}
                        </Card>
                        <br /><br />
                      </>
                    )}
                  </Form.List>
                </>
              ))}
            </Card>
          )
          }
        </Form.List>
        <br></br>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
            Submit
          </Button>
        </Form.Item>
        <CustomModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
        >
          <Text>{message?.message}</Text>
        </CustomModal>
        <CustomSpinnerModal isLoading={isLoading} />
      </Form>
    </>
  )
}

export default DDQuestion