import {
    Row,
    Button,
    Col,
    Form,
    Input,
    Select,
    DatePicker,
    Typography,
} from 'antd'
import { useEffect, useState, useContext } from 'react'
import '../../../../assets/resources/style/form.css'
import moment from 'moment';
import useRequest from '../../../../hooks/use-request';
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
import Modal from '../../../Layout/Modal/Modal';
import { refregion, refprovince, refcitymun, refbrgy } from '../../../../assets/Constants/Address';
import API_CALL from "../../../../helpers/api";
import { AuthContext } from '../../../../store/use-context';
const { Text } = Typography
const { Option } = Select;

const Teacher = () => {
    const [school, setSchool] = useState([]);
    const [subject, setSubject] = useState([]);
    const [profile, setProfile] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSubjectLoading, setIsSubjectLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const [form] = Form.useForm();
    const [regionId, setRegionId] = useState(null);
    const [provinceId, setProvinceId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [brgyId, setBrgyId] = useState(null);
    const [address, setAddress] = useState({
        region: refregion,
        province: [],
        city: [],
        brgy: []
    });
    const authContext = useContext(AuthContext);

    const filterAddress = async (code, input) => {
        switch (input) {
            case 'regionId': {
                setAddress((prevState) => ({
                    region: refregion,
                    province: refprovince.filter(obj => obj.regCode === code),
                    city: [],
                    brgy: []
                })
                )
                setProvinceId(null);
                setCityId(null);
                setBrgyId(null);
                break;
            };
            case 'provinceId': {
                setAddress((prevState) => ({
                    region: prevState.region,
                    province: prevState.province,
                    city: refcitymun.filter(obj => obj.provCode === code),
                    brgy: []
                })
                )
                setCityId(null);
                setBrgyId(null);
                break;
            };
            case 'cityId': {
                setAddress((prevState) => ({
                    region: prevState.region,
                    province: prevState.province,
                    city: prevState.city,
                    brgy: refbrgy.filter(obj => obj.citymunCode === code)
                })
                )
                setBrgyId(null);
                break;
            };
            default: {

            }
        }
    }

    const handleOk = () => {
        setIsModalVisible(false);
        setMessage(null);
    };

    const getProfile = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "2" && 'admin-school')}/profile/getProfile`,
        },
        (data) => {
            setProfile(data);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/addTeacher`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSchools = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSchools`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setSchool(sortActive);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    );

    const getSubjects = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjects`,
        },
        (data) => {
            const filterData = data.filter((subject) => {
                return subject.status === 0
            })
            setSubject(filterData);
            setIsSubjectLoading(false);
        },
        (err) => {
            setIsSubjectLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
        
    );

    useEffect(() => {
        if ((authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti')) {
            getSchools.sendRequest();
            getSubjects.sendRequest();
        } else {
            getSubjects.sendRequest();
            getProfile.sendRequest();
        }
    }, [])

    if (isLoading) {
        return <CustomSpinnerModal isLoading={isLoading} />
    }

    if (isSubjectLoading) {
        return <CustomSpinnerModal isLoading={isSubjectLoading} />
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="63">+63</Option>
            </Select>
        </Form.Item>
    );

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select date!',
            },
        ],
    };

    const onFinish = (values) => {
        console.log(values)
        console.log(form.getFieldsValue());
        let subjects = [];

        for (let index = 0; index < values.subjects.length; index++) {
            subjects.push({ subjectId: values.subjects[index] })
        }

        addRequest.sendRequest({
            teacherIdNumber: values.teacherIdNumber,
            gender: values.gender,
            schoolId: values.schoolId,
            subjects: JSON.stringify(subjects),
            regionId: values.regionId,
            provinceId: values.provinceId,
            cityId: values.cityId,
            brgyId: values.brgyId,
            firstName: values.firstName,
            middleName: values?.middleName ? values?.middleName : '',
            lastName: values.lastName,
            email: values.email,
            mobileNumber: values.prefix + values.phone,
            birthdate: moment(values.birthdate._d).format('yyyy-MM-DD'),
            schoolId: values?.schoolId ? values?.schoolId : '',
        }, null)
        setIsLoading(true);
    };

    return (
        <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                prefix: '63',
                schoolId: profile.schoolId
            }}
            scrollToFirstError
        >
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="teacherIdNumber"
                        label="Teacher Id Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="schoolId"
                        label="School"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select school"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {profile && <Option value={profile?.schoolId}>{profile?.name}</Option>}
                            {school.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.schoolId}>{obj.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="subjects"
                        label="Subject"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            mode="tags"
                            placeholder="Select school"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {subject.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.subjectId}>{obj.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your first name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="middleName"
                        label="Middle Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your last name',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="phone"
                        label="Mobile Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <Form.Item
                        name="gender"
                        label="Gender"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your gender'
                            }
                        ]}
                    >
                        <Select
                            initialvalues={0}
                        >
                            <Option value={0}>Male</Option>
                            <Option value={1}>Female</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <Form.Item
                        name="birthdate"
                        label="Birthdate"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        {...config}
                        style={{ width: '100%', margin: 5 }}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="regionId"
                        label="Region"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your region',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select region"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={(e) => {
                                console.log(e);
                                filterAddress(e, 'regionId')
                                setRegionId(e)
                            }}
                            value={regionId || null}
                        >
                            {address.region.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.regCode}>{obj.regDesc}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="provinceId"
                        label="Province"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your province',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select province"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={(e) => {
                                filterAddress(e, 'provinceId')
                                setProvinceId(e)
                            }}
                            value={provinceId}
                        >
                            <Option value="" disabled>Select</Option>
                            {address.province.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.provCode}>{obj.provDesc}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="cityId"
                        label="City"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your city',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select city"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={(e) => {
                                filterAddress(e, 'cityId')
                                setCityId(e)
                            }}
                            value={cityId}
                        >
                            {address.city.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.citymunCode}>{obj.citymunDesc}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="brgyId"
                        label="Baranggay"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your baranggay',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select baranggay"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            value={brgyId}
                            onChange={(e) => {
                                filterAddress(e, 'brgyId')
                                setBrgyId(e)
                            }}
                        >
                            {address.brgy.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.brgyCode}>{obj.brgyDesc}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24} lg={12}>
                    <Form.Item
                        name="address"
                        label="Address"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5, paddingRight: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='button'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Row>
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
            >
                <Text>{message?.message}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading} />
        </Form>
    )
}

export default Teacher