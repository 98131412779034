import { Form, Row, Col, Input, Select, Typography, Button, Card, Upload } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import {
    PlusOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom'
import useRequest from '../../../hooks/use-request'
import Modal from '../../Layout/Modal/Modal'
import CustomSpinnerModal from '../../Layout/Modal/Spinner'
import { AuthContext } from '../../../store/use-context'
const { Option } = Select
const { Text } = Typography

const props = {
    accept: ".png, .jpg, .jpeg, .gif, .tiff",
    multiple: false,
    beforeUpload: (file) => {
        const isLt2M = file.size / 300 / 300 < 2;
        if (!isLt2M) {
            console.log('test')
        }
        return false;
    },
    onChange(info) {
        if (info.file.status !== "removed") {
            let reader = new FileReader();
            reader.readAsDataURL(info.file);
        }
    },
};

const Add = () => {
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addSubject`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const onFinish = (values) => {
        let formData = new FormData();
        formData.append("gradeLevel", values.gradeLevel ? values.gradeLevel : '');
        formData.append("name", values.name ? values.name : '');
        formData.append("description", values.description ? values.description : '');
        formData.append("image", values.image?.fileList?.[0] === undefined ? '' : values.image?.fileList[0].originFileObj);
        addRequest.sendRequest(formData, null);
        setIsLoading(true)
    }

    return (
        <Card
            title={
                <Link to='/subject'>
                    <Button type='primary'>{"< Back to Subject"}</Button>
                </Link>
            }
        >
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    prefix: '63',
                }}
                scrollToFirstError
            >
                <Row className='row'>
                    <Col xs={24} md={12} lg={6}>
                        <Form.Item
                            name="name"
                            label="Subject Name"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your subject name`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Form.Item
                            name="description"
                            label="Description"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your description`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Form.Item
                            name="gradeLevel"
                            label="Grade Level"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please select grade level`,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select grade"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                <Option value={1}>Grade 1</Option>
                                <Option value={2}>Grade 2</Option>
                                <Option value={3}>Grade 3</Option>
                                <Option value={4}>Grade 4</Option>
                                <Option value={5}>Grade 5</Option>
                                <Option value={6}>Grade 6</Option>
                                <Option value={7}>Grade 7</Option>
                                <Option value={8}>Grade 8</Option>
                                <Option value={9}>Grade 9</Option>
                                <Option value={10}>Grade 10</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Form.Item
                            name="image"
                            label="Image"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Upload
                                listType="picture-card"
                                {...props}
                                maxCount={1}
                                name="upload"
                                style={{ width: '32px', height: '32px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please attach an image.',
                                    },
                                ]}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='button'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
                <Modal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </Modal>
                <CustomSpinnerModal isLoading={isLoading} />
            </Form>
        </Card>
    )
}

export default Add