import React, { useState } from 'react'
import '../../../../assets/resources/style/subject.css'
import { Table } from 'ant-table-extensions'
import {
    EditOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { Colors } from '../../../../assets/Constants/Colors';
import { Menu, Tag, Button, Space, Dropdown, Form, Typography, Input, InputNumber, Popconfirm } from 'antd';
import CustomModal from '../../../Layout/Modal/Modal';

const { Text } = Typography

const ArchiveTopic = ({
    topics,
    isLoading,
    visible,
    message,
    handleOk,
}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(topics);

    const columns = [

        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            render: (tags) => (
                <span>
                    {
                        (tags === 0 && <Tag color={Colors.blue} key={tags}>
                            {'ACTIVE'}
                        </Tag>) ||
                        (tags === 1 && <Tag color={Colors.grey} key={tags}>
                            {'DEACTIVATED'}
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            width: '100px',
            editable: true,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: '30%',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '45%',
            editable: true,
        },
    ];
    return (
        <>
            <Table
                id="topicId"
                rowKey="topicId"
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
                loading={isLoading}
            />
            {visible && (
                <CustomModal
                    isModalVisible={visible}
                    handleOk={handleOk}
                    handleCancel={handleOk}
                    title={message?.message === 'Updated successfully' || message?.message === 'Updated successfully.' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </CustomModal>
            )}
        </>
    );
}

export default ArchiveTopic 