import {
    Form,
    Button,
    Input,
    TimePicker,
    Radio,
    Row,
    Col,
    Typography,
    Checkbox,
    Upload,
    Card
} from 'antd';
import {
    PlusCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    CloseSquareOutlined,
    CheckSquareTwoTone,
    InboxOutlined
} from '@ant-design/icons';
import React, { useState, useContext, useEffect } from 'react'
import '../../../../../assets/resources/style/activityForm.css'
import useRequest from '../../../../../hooks/use-request';
import API_CALL from "../../../../../helpers/api";
import CustomModal from '../../../../Layout/Modal/Modal';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import TrueFalseQuestionForm from '../TrueFalse/TrueFalseQuestionForm';
import { AuthContext } from '../../../../../store/use-context';
import '../forms.css'
import moment from 'moment';

const { Text } = Typography;
const { TextArea } = Input;

const getBase64 = (file) => {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
}

const OTBQuestion = ({ moduleId, type, subjectId, topicId, module }) => {
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [scoreValidation, setScoreValidation] = useState(false);
    const [value, setValue] = useState([1, 1]);
    const [choices, setChoices] = useState([false, false])
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleOTBQuestion`,
            headers: { "Content-Type": "multipart/form-data" }
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            setIsModalVisible(true);
            setChoices([false, false]);
            setValue([1, 1]);
            form.resetFields();
        },
        (err) => {
            console.log(err)
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const format = 'mm:ss';

    const handleOk = () => setIsModalVisible(false);

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        let formData = new FormData();
        let corrects = [];
        let incorrects = [];
        for (let index = 0; index < values.choices.length; index++) {
            if (values.choices[index]?.file !== undefined) {
                formData.append(choices[index] === true ? 'correct' : 'incorrect', values.choices[index].file)
            } else {
                choices[index] === true ? corrects.push({ description: values.choices[index] }) : incorrects.push({ description: values.choices[index] })
            }
        }

        formData.append("corrects", JSON.stringify(corrects));
        formData.append("incorrects", JSON.stringify(incorrects));
        formData.append("question", values.question);
        formData.append("image", values?.image ? values.image[0].originFileObj : '');
        setIsLoading(true);
        addRequest.sendRequest(formData, { moduleId: moduleId })
    };

    const handleCancel = () => setPreviewVisible(false);

    const props = {
        accept: ".png, .jpg, .jpeg, .gif, .tiff",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
        async onPreview(file) {
            setPreviewImage(file.url || file.thumbUrl);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        },
    };

    useEffect(() => {
        if (choices) {
            const newForm = form.getFieldsValue('choices');
            form.setFieldsValue(newForm.choices);
            console.log(newForm)
        }
    }, [choices])

    const checkBoxImage = (e, index) => {
        let arrayCopy = [...choices]
        arrayCopy[index] = e.target.checked
        // arrayCopy[e.target.id.split("_")[4]] = e.target.checked
        const newForm = form.getFieldsValue('choices');
        newForm.choices[index] = newForm.choices[index]
        setChoices(arrayCopy);
        form.setFieldsValue(newForm.choices);
    }

    const onChangeRadio = (e, index) => {
        let arrayValue = [...value]
        arrayValue[index] = e.target.value
        setValue(arrayValue)
        let arrayChoices = [...value]
        arrayChoices[index] = ''
        const newForm = form.getFieldsValue('choices');
        newForm.choices[index] = ''
        if (e.target.value === 2) {
            form.setFieldsValue(newForm);
        }
    }

    const onAdd = () => {
        setChoices(
            [...choices,
                false]
        )
        setValue([
            ...value,
            1
        ])

    }

    const onRemove = (index) => {
        let formList = [...choices];
        formList.splice(index, 1);
        setChoices(formList);
        let formListValue = [...value];
        formListValue.splice(index, 1);
        setValue(formListValue);

    }

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Form
                name="dynamic_form_item"
                onFinish={onFinish}
                initialValues={{ choices: ["", ""] }}
                form={form}
            >
                <Form.Item
                    name="image"
                    label="Upload Image: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple="false"
                >
                    <Upload.Dragger
                        {...props}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <br></br>
                <>
                    <Form.Item
                        label="Question"
                        name="question"
                    >
                        <TextArea
                            placeholder="Question"
                            style={{
                                width: '90%',
                            }}
                            rows={4}
                        />
                    </Form.Item>
                </>
                <Form.List
                    name="choices"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <Card
                            size="small"
                            title="Choices"
                            extra={<Button type="primary" onClick={() => {
                                add();
                                onAdd();
                            }}>Add</Button>}
                        >
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={true}
                                    key={field.key}
                                    label={"Choice " + (+index + 1)}
                                >
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    key={`radio` + index}
                                                >
                                                    <Text>Type of choice: </Text>
                                                    <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value[index]}>
                                                        <Radio value={1}>Text</Radio>
                                                        <Radio value={2}>Image</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <li>
                                                    {/* <Text>Choice is correct</Text> */}
                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                    <label htmlFor={`cb` + index}>
                                                        <div className="icon">
                                                            {choices[index] === true ?
                                                                <CheckSquareTwoTone
                                                                    style={{ fontSize: '32px' }}
                                                                /> :
                                                                <CloseSquareOutlined
                                                                    style={{ fontSize: '32px' }}
                                                                />}
                                                        </div>
                                                    </label>
                                                </li>
                                            </Col>
                                            <Col style={{ width: value[index] === 1 && '50%' }}>
                                                {value[index] === 2 ? (<Form.Item
                                                    name="upload-form"
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please attach an image.',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        listType="picture-card"
                                                        {...props}
                                                        maxCount={1}
                                                        name="upload"
                                                        style={{ width: '32px', height: '32px' }}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please attach an image.',
                                                            },
                                                        ]}
                                                    >
                                                        <div>
                                                            <PlusOutlined />
                                                            <div
                                                                style={{
                                                                    marginTop: 8,
                                                                }}
                                                            >
                                                                Upload
                                                            </div>
                                                        </div>
                                                    </Upload>
                                                </Form.Item>) : (
                                                    <Form.Item
                                                        {...field}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input text or delete this field.",
                                                            },
                                                        ]}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Input
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </Form.Item>
                                                )}
                                            </Col>
                                            <Col>
                                                {fields.length !== 1 ? (<DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name);
                                                        onRemove(index)
                                                    }}
                                                />) : (
                                                    <DeleteOutlined
                                                        className="dynamic-delete-button"
                                                        disabled
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                </Form.Item>
                            ))}
                        </Card>
                    )
                    }
                </Form.List >
                <Form.Item
                    style={{ float: 'right', marginTop: 8 }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form >
            <CustomModal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleOk}
                title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
            >
                <Text>{message?.message}</Text>
            </CustomModal>
            <CustomSpinnerModal isLoading={isLoading} />
        </>
    )
}

export default OTBQuestion