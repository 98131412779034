import {
    Form,
    Button,
    Input,
    Typography,
    Upload,
    Checkbox,
    Row,
    Col,
    Radio
} from 'antd';
import {
    InboxOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import React, { useContext, useState } from 'react'
import '../../../../../assets/resources/style/activityForm.css'
import useRequest from '../../../../../hooks/use-request';
import API_CALL from "../../../../../helpers/api";
import CustomModal from '../../../../Layout/Modal/Modal';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import { AuthContext } from '../../../../../store/use-context';

const { Text } = Typography;

const getBase64 = (file) => {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });
}

const TrueFalseQuestionForm = ({ moduleId, type, subjectId, topicId, module }) => {
    const authContext = useContext(AuthContext)
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [value, setValue] = useState(1);
    const [valueF, setValueF] = useState(1);
    const [choices, setChoices] = useState(false);
    const [form] = Form.useForm();

    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleTFQuestion`,
            headers: { "Content-Type": "multipart/form-data" }
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            setIsModalVisible(true);
            setChoices(false)
            form.resetFields();
        },
        (err) => {
            console.log(err)
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => setIsModalVisible(false);

    const onFinish = (values) => {
        console.log('Received values of form:', values);

        let formData = new FormData();

        formData.append("question", values.question);
        formData.append("isTrue", choices === true ? 1 : 0);
        formData.append("image", values.image?.[0] === undefined ? '' : values.image?.[0].originFileObj);
        formData.append("t", values.t === undefined ? values.tName : values.t?.fileList[0].originFileObj);
        formData.append("f", values.f === undefined ? values.fName : values.f?.fileList[0].originFileObj);
        setIsLoading(true);
        addRequest.sendRequest(formData, { moduleId: moduleId })
    };

    const props = {
        accept: "image/png",
        multiple: false,
        beforeUpload: (file) => {
            console.log(file.type)
            const isJPG = file.type === "image/jpeg";
            if (!isJPG) {
                message.error('You can only upload JPG file!');
            }

            return isJPG;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const beforeUpload = (file) => {
        console.log(file.type)
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
            message.error('You can only upload JPG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJPG && isLt2M;
    }

    const checkBoxImage = (e) => {
        console.log(e)
        setChoices(e.target.checked)
        // let arrayCopy = [...choices]
        // arrayCopy[e.target.id.split("_")[4]] = e.target.checked
        // setChoices(arrayCopy)
    }

    const onChangeRadio = (e) => {
        let arrayValue
        arrayValue = e.target.value
        setValue(arrayValue)
        let arrayChoices
        arrayChoices = ''
        const newForm = form.getFieldsValue('choices');
        newForm.choices = ''
        if (e.target.value === 2) {
            form.setFieldsValue(newForm);
        }
    }

    const onChangeRadioF = (e) => {
        let arrayValue
        arrayValue = e.target.value
        setValueF(arrayValue)
        let arrayChoices
        arrayChoices = ''
        const newForm = form.getFieldsValue('choices');
        newForm.choices = ''
        if (e.target.value === 2) {
            form.setFieldsValue(newForm);
        }
    }

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Form
                name="true_false"
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    name="image"
                    label="Upload Image: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple="false"
                >
                    <Upload.Dragger
                        {...props}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <br></br>
                <Form.Item
                    name="question"
                    label="Question"
                    rules={[
                        {
                            required: true,
                            message: 'Please input question',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                    label="True or False: "
                    tooltip={'If checked the question is true, otherwise false.'}
                >
                    <Checkbox
                        style={{ float: 'left' }}
                        onChange={checkBoxImage}
                        checked={choices}
                    >
                    </Checkbox>
                </Form.Item>
                <Col>
                    <Form.Item
                        validateTrigger={['onChange', 'onBlur']}
                        key={`radio`}
                    >
                        <Text>Set True or False: </Text>
                        <Radio.Group onChange={(e) => onChangeRadio(e)} value={value}>
                            <Radio value={1}>Text</Radio>
                            <Radio value={2}>Image</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Row>
                    <Col>
                        <Text>True: </Text>
                    </Col>
                    <Col style={{ width: value === 1 && '50%' }}>
                        {value === 2 ? (<Form.Item
                            name="t"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please attach an image.',
                                },
                            ]}
                        >
                            <Upload
                                listType="picture-card"
                                {...props}
                                beforeUpload={beforeUpload}
                                maxCount={1}
                                name="upload"
                                style={{ width: '32px', height: '32px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please attach an image.',
                                    },
                                ]}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            </Upload>
                        </Form.Item>) : (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please input text or delete this.",
                                    },
                                ]}
                                style={{
                                    width: '100%',
                                }}
                                name="tName"
                            >
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text>False: </Text>
                    </Col>
                    <Col style={{ width: value === 1 && '50%' }}>
                        {value === 2 ? (<Form.Item
                            name="f"
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please attach an image.',
                                },
                            ]}
                        >
                            <Upload
                                listType="picture-card"
                                {...props}
                                beforeUpload={beforeUpload}
                                maxCount={1}
                                name="upload"
                                style={{ width: '32px', height: '32px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please attach an image.',
                                    },
                                ]}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            </Upload>
                        </Form.Item>) : (
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please input text or delete this.",
                                    },
                                ]}
                                style={{
                                    width: '100%',
                                }}
                                name="fName"
                            >
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form >
            <CustomModal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleOk}
                title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
            >
                <Text>{message?.message}</Text>
            </CustomModal>
            <CustomSpinnerModal isLoading={isLoading} />
        </>
    )
}

export default TrueFalseQuestionForm