import { Drawer, Form, Select } from 'antd';
import PowtoonEdit from "./Forms/Powtoon/PowtoonEdit";
import QuestionForm from './Forms/QuestionForm';
import { AuthContext } from '../../../store/use-context';
import React, { useState, useContext } from 'react';
import moment from 'moment';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import CustomModal from '../../Layout/Modal/Modal';
import CRANK_UP from '../../../assets/resources/Images/crank_up.png';
import TUNE_UP from '../../../assets/resources/Images/tune_up.png';
import SPARK_UP from '../../../assets/resources/Images/spark_up.png';
import CATCH_UP from '../../../assets/resources/Images/catch_up.png';
import OPEN_UP from '../../../assets/resources/Images/open_up.png';
import STEP_UP from '../../../assets/resources/Images/step_up.png';
import PUT_UP from '../../../assets/resources/Images/put_up.png';
import WIND_UP from '../../../assets/resources/Images/wind_up.png';
const { Option } = Select;

const activityList = [
    {
        id: '1',
        title: 'Crank Up',
        message: 'Pick and crack the egg that has a big and small letter of the alphabet.',
        image: CRANK_UP
    },
    {
        id: '2',
        title: 'Spark Up',
        message: 'Pop each colorful balloon and find out if the letters inside are written correctly in uppercase and lowercase.',
        image: SPARK_UP
    },
    {
        id: '3',
        title: 'Tune Up',
        message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
        image: TUNE_UP
    },
    {
        id: '4',
        title: 'Catch Up',
        message: 'Tell the beginning letter of each picture.',
        image: CATCH_UP
    },
    {
        id: '5',
        title: 'Open Up',
        message: 'Do your best to catch the letters of the alphabet.',
        image: OPEN_UP
    },
    {
        id: '6',
        title: 'Step Up',
        message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
        image: STEP_UP
    },
    {
        id: '7',
        title: 'Put Up',
        message: 'Tell the beginning letter of each picture.',
        image: PUT_UP
    },
    {
        id: '8',
        title: 'Wind Up',
        message: 'Do your best to catch the letters of the alphabet.',
        image: WIND_UP
    },
    {
        id: '9',
        title: 'Exit Test',
        message: 'Do your best because it is a test.',
        image: WIND_UP
    },
    {
        id: '10',
        title: 'Performance Task',
        message: 'Performance Task',
        image: WIND_UP
    }
];

const ActivityEdit = ({
    parentMenuTitle,
    childrenVisible,
    onModuleEditDirectionClose,
    visible,
    activityType,
    moduleId,
    viewActivityModule,
    searchViewDataSource,
    onEdit,
    editRequest,
    editMessage,
    isEditLoading,
    editModalVisible,
    handleEditOk
}) => {
    const authContext = useContext(AuthContext);
    const [saveAnswer, setSaveAnswer] = useState(searchViewDataSource.isSaveAnswers === 1 ? true : false);
    const [scoreValidation, setScoreValidation] = useState(searchViewDataSource.scoreValidation === 1 ? true : false);
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(isEditLoading);
    const [isModalVisible, setIsModalVisible] = useState(editModalVisible);
    const [continueQuestion, setContinueQuestion] = useState(false);

    const onSaveAnswerChange = (e) => {
        setSaveAnswer(e)
    }

    const onScoreValidationChange = (e) => {
        setScoreValidation(e)
    }

    const handleOk = () => {
        setIsModalVisible(false);
    }

    // const onEdit = (values) => {
    //     console.log('Received values of form:', values);
    //     editRequest.sendRequest({
    //         type: searchViewDataSource.type,
    //         directions: values.instruction,
    //         retryLimit: values.retryLimit,
    //         scoreValidation: scoreValidation === true ? 1 : 0,
    //         isSaveAnswers: saveAnswer === true ? 1 : 0,
    //         isUploadRequired: searchViewDataSource.isUploadRequired === 1 ? true : false
    //     }, { moduleId })
    //     setIsLoading(true);
    // }

    return (
        <Drawer
            title={parentMenuTitle}
            placement="bottom"
            width={320}
            height={childrenVisible ? "80%" : "0%"}
            closable={false}
            onClose={onModuleEditDirectionClose}
            visible={visible}
        >
            {activityType === 'POWTOON' && (
                <PowtoonEdit moduleId={moduleId} module={'POWTOON'} />
            )}
            {activityType === 'Performance Task' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'Performance Task'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    retryLimit={searchViewDataSource.retryLimit}
                    lecture={searchViewDataSource}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    isGroup={searchViewDataSource.isGroup}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                    groupings={true}
                ></QuestionForm>
            )}
            {activityType === 'Fill in the Blanks' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'FILL IN THE BLANKS'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"
                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Whack A Mole' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'WHACK A MOLE'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'True or False' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'True or False'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'QuizShow' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'QUIZ SHOW'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Open The Box' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'OPEN THE BOX'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Maze Chase' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'Maze Chase'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                    isMaze={true}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Multiple Choice' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'MULTIPLE CHOICE'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Spin A Wheel' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'SPIN A WHEEL'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Matching Type' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'MATCHING TYPE'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Word Sentence Completion' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'WORD SENTENCE COMPLETION'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    time={moment.utc(searchViewDataSource.activity.time).format('mm:ss')}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Jumbled Letters' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'JUMBLED LETTERS'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {activityType === 'Drag and Drop' && (
                <QuestionForm
                    moduleId={moduleId}
                    module={'DRAG AND DROP'}
                    onSaveAnswerChange={onSaveAnswerChange}
                    onScoreValidationChange={onScoreValidationChange}
                    instruction={searchViewDataSource.directions}
                    retryLimit={searchViewDataSource.retryLimit}
                    isUploadRequired={searchViewDataSource.isUploadRequired === 1 ? true : false}
                    scoreValidation={scoreValidation}
                    isSaveAnswers={saveAnswer}
                    viewEdit={true}
                    onEdit={(values) => onEdit({ values, scoreValidation, saveAnswer })}
                    onSubmit={() => {
                        viewActivityModule.sendRequest(null, { moduleId })
                    }}
                >
                    <Form.Item
                        label="Type"
                        name="type"

                    >
                        <Select
                            placeholder="Select a type"
                            defaultValue={searchViewDataSource.type}
                        >
                            {activityList.map((act) => (
                                <Option value={act.title.toUpperCase()}>{act.title.toUpperCase()}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </QuestionForm>
            )}
            {isEditLoading && <CustomSpinnerModal isLoading={isEditLoading} />}
            {<CustomModal
                isModalVisible={editModalVisible}
                handleOk={handleEditOk}
                handleCancel={handleEditOk}
            >
                {editMessage?.message}
            </CustomModal>
            }
        </Drawer>
    )
}

export default ActivityEdit