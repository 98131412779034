import { Form, Row, Checkbox, Input, Typography, Upload, Button, Card } from 'antd'
import {
    PlusCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    CheckSquareTwoTone,
    CloseSquareOutlined
} from '@ant-design/icons';
import React, { useState, useContext } from 'react'
import API_CALL from '../../../../../helpers/api';
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import CustomModal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';

const { Text } = Typography;

const SpinAWheelQuestion = ({ moduleId, module }) => {
    const authContext = useContext(AuthContext);
    const [choices, setChoices] = useState([false, false])
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleSAWQuestion`,
        },
        (data) => {
            console.log(data)
            setMessage(data);
            setIsModalVisible(true);
            setIsLoading(false);
            setChoices([false, false])
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => setIsModalVisible(false)
    const handleCancel = () => setIsModalVisible(false)

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const onAdd = () => {
        setChoices(
            [...choices,
                false]
        )
    }

    const onRemove = (index) => {
        let formList = [...choices];
        formList.splice(index, 1);
        setChoices(formList);
    }

    const checkBoxImage = (e, index) => {
        // let arrayCopy = [...choices]
        // arrayCopy[e.target.id.split("_")[4]] = e.target.checked
        // setChoices(arrayCopy)
        let arrayCopy = [...choices]
        arrayCopy[index] = e.target.checked
        // arrayCopy[e.target.id.split("_")[4]] = e.target.checked
        const newForm = form.getFieldsValue('choices');
        newForm.choices[index] = newForm.choices[index]
        setChoices(arrayCopy);
        form.setFieldsValue(newForm.choices);
    }

    const props = {
        accept: ".png, .jpg, .jpeg, .gif, .tiff",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    const onFinish = (values) => {
        console.log('Received values of form:', values);

        let formData = new FormData();

        console.log(choices)

        let choice = [];
        for (let i = 0; i < choices.length; i++) {
            choices[i] === true ? choice.push({ title: values.choices[i], isCorrect: 1 }) : choice.push({ title: values.choices[i], isCorrect: 0 })
        }

        formData.append("choices", JSON.stringify(choice));
        formData.append("image", values.image?.[0] === undefined ? '' : values.image?.[0].originFileObj);
        formData.append("question", values.question);
        setIsLoading(true);
        addRequest.sendRequest(formData, { moduleId: moduleId })
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Form
                name="dynamic_form_item"
                onFinish={onFinish}
                initialValues={{ choices: ["", ""] }}
                form={form}
            >
                <Form.Item
                    name="image"
                    label="Upload Image: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple="false"
                >
                    <Upload.Dragger
                        {...props}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <br></br>
                <Form.Item
                    name="question"
                    label="Question"
                    rules={[
                        {
                            required: true,
                            message: 'Please input question',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.List
                    name="choices"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <Card
                            size="small"
                            title="Choices"
                            extra={
                                fields.length !== 12 ? (
                                    <Button type="primary" onClick={() => {
                                        add();
                                        onAdd();
                                    }}>Add</Button>) : (null)}
                        >
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={true}
                                    key={field.key}
                                    label={"Choice " + (+index + 1)}
                                >
                                    <>
                                        <Row>
                                            <li>
                                                {/* <Text>Choice is correct</Text> */}
                                                <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                <label htmlFor={`cb` + index}>
                                                    <div className="icon">
                                                        {choices[index] === true ?
                                                            <CheckSquareTwoTone
                                                                style={{ fontSize: '32px' }}
                                                            /> :
                                                            <CloseSquareOutlined
                                                                style={{ fontSize: '32px' }}
                                                            />}
                                                    </div>
                                                </label>
                                            </li>
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: "Please input text or delete this field.",
                                                    },
                                                ]}
                                                style={{
                                                    width: '80%',
                                                }}
                                            >
                                                <Input
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </Form.Item>
                                            <PlusCircleOutlined
                                                className="dynamic-add-button"
                                                onClick={() => {
                                                    add();
                                                    onAdd();
                                                }}
                                            />
                                            {fields.length !== 1 ? (<DeleteOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => {
                                                    remove(field.name);
                                                    onRemove()
                                                }}
                                            />) : (
                                                <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    disabled
                                                />
                                            )}
                                        </Row>
                                    </>
                                </Form.Item>
                            ))}
                        </Card>
                    )
                    }
                </Form.List >
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <CustomModal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}, Do you wish to continue adding questions?</Text>
                </CustomModal>
                <CustomSpinnerModal isLoading={isLoading} />
            </Form>
        </>
    )
}

export default SpinAWheelQuestion