import { Form, Row, Col, Input, Select, Typography, Button, Card } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useRequest from '../../../hooks/use-request'
import Modal from '../../Layout/Modal/Modal'
import CustomSpinnerModal from '../../Layout/Modal/Spinner'
const { Option } = Select
const { Text } = Typography

const AddTopic = ({ form, onFinishTopic }) => {
    return (
        <Form
            form={form}
            name="register"
            onFinish={onFinishTopic}
            initialValues={{
                prefix: '63',
            }}
            scrollToFirstError
        >
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="title"
                        label="Topic Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: `Please input your topic name`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="description"
                        label="Description"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: `Please input your description`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="quarter"
                        label="Quarter"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: `Please select quarter`,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select grade"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            <Option value={1}>Quarter 1</Option>
                            <Option value={2}>Quarter 2</Option>
                            <Option value={3}>Quarter 3</Option>
                            <Option value={4}>Quarter 4</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='button'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    )
}

export default AddTopic