import { Collapse, Row, Col, Typography, Button, Space } from 'antd';
import React, { Children } from 'react';
import {
    EditOutlined,
} from "@ant-design/icons";
import moment from 'moment';
const { Panel } = Collapse;
const { Text } = Typography;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const ViewActivity = ({ searchViewDataSource, onModuleEditDirection, children }) => {
    return (
        <>
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Directions: </Text>
                </Col>
                <Space>
                    <Col style={{ float: 'left' }}>
                        <div style={{ maxWidth: '600px', float: 'left' }}>
                            <Text>
                                {searchViewDataSource?.directions}
                            </Text>
                            <Button
                                style={{
                                    border: 0,
                                    borderRadius: 0,
                                    boxShadow: 0
                                }}
                                onClick={onModuleEditDirection}
                            >
                                <EditOutlined
                                    style={{ fontSize: 14 }}
                                />
                            </Button>
                        </div>
                    </Col>
                </Space>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Retry Limit: </Text>
                </Col>
                <Col>
                    <div style={{ maxWidth: '600px' }}>
                        <Text>{searchViewDataSource?.retryLimit}</Text>
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Lecture: </Text>
                </Col>
                <Col>
                    <div style={{ maxWidth: '600px' }}>
                        <Text>{searchViewDataSource?.lecture}</Text>
                    </div>
                </Col>
            </Row>
            <br />
            {/* <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Time Limit: </Text>
                </Col>
                <Col>
                    <div style={{ maxWidth: '600px' }}>
                        <Text>{convertMiliseconds()}</Text>
                    </div>
                </Col>
            </Row> */}
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Question/s: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <div>
                        {children}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ViewActivity;