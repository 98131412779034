import { Card } from 'antd';
const { Meta } = Card;

const HoverableCardQuarter = ({ logoClose, logoOpen, title, description, onClick }) => (
    <Card
        hoverable
        style={{
            width: 200,
        }}
        cover={<img alt="example" src={logoClose} />}
        onClick={onClick}
    >
        <Meta title={title} description={description} />
    </Card>
);

export default HoverableCardQuarter;