import React, { useContext, useState } from "react";
import "antd/dist/antd.min.css";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Popover,
  Avatar,
  Typography,
  Drawer,
} from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  FormOutlined,
  BankOutlined,
  MenuOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import "./index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../assets/Constants/Colors";
import logo from "../../../src/assets/resources/Images/schoolbook.png";
import { AuthContext } from "../../store/use-context";

const { Text } = Typography;
const { Sider, Header, Content } = Layout;

const SchoolSVG = () => {
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M21 10H17V8L12.5 6.2V4H15V2H11.5V6.2L7 8V10H3C2.45 10 2 10.45 2 11V22H10V17H14V22H22V11C22 10.45 21.55 10 21 10M8 20H4V17H8V20M8 15H4V12H8V15M12 8C12.55 8 13 8.45 13 9S12.55 10 12 10 11 9.55 11 9 11.45 8 12 8M14 15H10V12H14V15M20 20H16V17H20V20M20 15H16V12H20V15Z" />
  </svg>;
};

const HeartSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
  </svg>
);

const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;

const SchoolIcon = (props) => <Icon component={SchoolSVG} {...props} />;

const LayoutWithRoute = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  let screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const authContext = useContext(AuthContext);
  console.log(authContext);
  const [collapsed, setCollapsed] = useState(screenWidth < 741 ? true : false);
  const location = useLocation();

  const logoutHandler = () => {
    authContext.logout();
    navigate("/");
  };

  const items = [
    // {
    //   key: "/dashboard",
    //   icon: <DashboardOutlined />,
    //   label: (
    //     <div>
    //       <h4 style={{ marginBottom: '0px' }}>Dashboard</h4>
    //       <Link to={"/dashboard"}></Link>
    //     </div>
    //   ),
    //   allowed: [0, 1, 2],
    // },
    // {
    //   key: "/",
    //   icon: <DashboardOutlined />,
    //   label: (
    //     <div>
    //       <h4 style={{ marginBottom: '0px' }}>Announcement</h4>
    //       <Link to={"/"}></Link>
    //     </div>
    //   ),
    //   allowed: [0, 1, 2],
    // },
    {
      key: "/users",
      icon: <UserOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>Users</h4>
          <Link to={"/users"}></Link>
        </div>
      ),
      allowed: [0, 1, 2],
    },
    {
      key: "/activity",
      icon: <FormOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>Activity</h4>
          <Link to={"/activity"}></Link>
        </div>
      ),
      allowed: [0, 3],
    },
    {
      key: "/school",
      icon: <BankOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>School</h4>
          <Link to={"/school"}></Link>
        </div>
      ),
      allowed: [0, 1],
    },
    {
      key: "/section",
      icon: <BankOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>Section</h4>
          <Link to={"/section"}></Link>
        </div>
      ),
      allowed: [0, 1, 2],
    },
    {
      key: "/subject",
      icon: <BankOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>Subject</h4>
          <Link to={"/subject"}></Link>
        </div>
      ),
      allowed: [0],
    },
    {
      key: "/subjectactivities",
      icon: <BankOutlined />,
      label: (
        <div>
          <h4 style={{ marginBottom: '0px' }}>Subject Activities</h4>
          <Link to={"/subjectactivities"}></Link>
        </div>
      ),
      allowed: [0],
    },
    // {
    //   key: "/archive",
    //   icon: <DeleteOutlined />,
    //   label: (
    //     <div>
    //       <h4 style={{ marginBottom: '0px' }}>Archived</h4>
    //       <Link to={"/archive"}></Link>
    //     </div>
    //   ),
    //   allowed: [0],
    // },
    // {
    //   key: "/library",
    //   icon: <DeleteOutlined />,
    //   label: (
    //     <div>
    //       <h4 style={{ marginBottom: '0px' }}>Library</h4>
    //       <Link to={"/library"}></Link>
    //     </div>
    //   ),
    //   allowed: [0],
    // },
  ].filter((item) => item.allowed.includes(+authContext.userType));

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="sidebar"
        breakpoint={"lg"}
        theme="light"
        collapsedWidth={0}
        trigger={null}
      >
        <Row>
          <Col>
            <div className="logo-container">
              <img className="logo" alt="logo" src={logo}></img>
            </div>
          </Col>
          <Col className="current-user-container">
            <p className="current-user">{authContext.currentUser}</p>
          </Col>
        </Row>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={items}
        ></Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            backgroundColor: Colors.blue,
          }}
        >
          <div className="button-right">
            <Popover
              placement="bottomRight"
              arrowContent={false}
              title={
                <>
                  <div>
                    <img></img>
                    <Text>{authContext.currentUser}</Text>
                    <br></br>
                    <Text>{authContext.userData.email}</Text>
                    <br></br>
                    <Button>Manage your account</Button>
                  </div>
                </>
              }
              content={
                <Button type="primary" onClick={logoutHandler}>
                  Sign out
                </Button>
              }
              trigger="click"
            >
              <Avatar
                icon={
                  authContext.currentUser.imageUrl === "" ? (
                    <UserOutlined />
                  ) : null
                }
                src={
                  authContext.currentUser.imageUrl
                    ? authContext.currentUser.imageUrl
                    : null
                }
                size={40}
                style={{ backgroundColor: Colors.lightBlue }}
              >
                {!authContext.currentUser.imageUrl &&
                  authContext.currentUser.split(" ")[0].charAt(0) +
                  "" +
                  authContext.currentUser.split(" ")[1].charAt(0)}
              </Avatar>
            </Popover>
          </div>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "menu trigger",
              onClick: () => setVisible(true),
            },
          )}
          <Drawer
            className="layout"
            title={
              <Row>
                <Col>
                  <div className="logo-container">
                    <img className="logo" alt="logo" src={logo}></img>
                  </div>
                </Col>
                <Col className="current-user-container">
                  <p className="current-user">{authContext.currentUser}</p>
                </Col>
              </Row>
            }
            width={250}
            placement="left"
            onClick={() => setVisible(false)}
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              items={items}
            ></Menu>
          </Drawer>
        </Header>
        <Content
          className="site-layout-background"
          style={{ margin: "24px 14px", height: "100%" }}
        >
          {children}
        </Content>
      </Layout>
    </Layout >
  );
};

export default LayoutWithRoute;
