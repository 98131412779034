import { Modal } from 'antd';

const CustomModal = ({ isModalVisible, handleOk, handleCancel, title, children }) => {
    return (
        <>
            <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {children}
            </Modal>
        </>
    );
};

export default CustomModal;