import { Row, Col, Button } from 'antd'
import React from 'react'
import HoverableCardType from '../../Layout/HoverableCard/HoverableCardType'
import GEAR_UP from '../../../assets/resources/Images/gear_up.png';
import CRANK_UP from '../../../assets/resources/Images/crank_up.png';
import SPARK_UP from '../../../assets/resources/Images/spark_up.png';
import TUNE_UP from '../../../assets/resources/Images/tune_up.png';
import CATCH_UP from '../../../assets/resources/Images/catch_up.png';
import OPEN_UP from '../../../assets/resources/Images/open_up.png';
import STEP_UP from '../../../assets/resources/Images/step_up.png';
import PUT_UP from '../../../assets/resources/Images/put_up.png';
import WIND_UP from '../../../assets/resources/Images/wind_up.png';
import { useLocation, useNavigate } from 'react-router-dom';

const ActivityType = ({ onTypeBack, onActivityTypeClick }) => {
    const activityList = [
        {
            id: '0',
            title: 'Gear Up',
            message: 'The letters of the english alphabet.',
            image: GEAR_UP
        },
        {
            id: '1',
            title: 'Crank Up',
            message: 'Pick and crack the egg that has a big and small letter of the alphabet.',
            image: CRANK_UP
        },
        {
            id: '2',
            title: 'Spark Up',
            message: 'Pop each colorful balloon and find out if the letters inside are written correctly in uppercase and lowercase.',
            image: SPARK_UP
        },
        {
            id: '3',
            title: 'Tune Up',
            message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
            image: TUNE_UP
        },
        {
            id: '4',
            title: 'Catch Up',
            message: 'Tell the beginning letter of each picture.',
            image: CATCH_UP
        },
        {
            id: '5',
            title: 'Open Up',
            message: 'Do your best to catch the letters of the alphabet.',
            image: OPEN_UP
        },
        {
            id: '6',
            title: 'Step Up',
            message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
            image: STEP_UP
        },
        {
            id: '7',
            title: 'Put Up',
            message: 'Tell the beginning letter of each picture.',
            image: PUT_UP
        },
        {
            id: '8',
            title: 'Wind Up',
            message: 'Do your best to catch the letters of the alphabet.',
            image: WIND_UP
        },
        {
            id: '9',
            title: 'Exit Test',
            message: 'Do your best because it is a test.',
            image: SPARK_UP
        },
        {
            id: '10',
            title: 'Performance Task',
            message: 'Performance Task',
            image: OPEN_UP
        }
    ];

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={onTypeBack}>{`<`}Back to Topic</Button>
            </Row>
            <Row>
                {activityList.map((activity, index) => {
                    return (
                        <Col
                            key={index}
                            xs={24}
                            md={16}
                            lg={12}
                            xl={8}
                        >
                            <HoverableCardType
                                logo={activity.image}
                                title={activity.title}
                                description={activity.message}
                                onClick={() => {
                                    onActivityTypeClick(activity.title.toUpperCase())
                                }}
                            />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export default ActivityType