import { Button, Col } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { Steps, Card, Typography } from "antd";
import "../../../assets/resources/style/activitySteps.css";
import ActivitySubject from "./ActivitySubject";
import CustomSpinnerModal from "../../Layout/Modal/Spinner";
import useRequest from "../../../hooks/use-request";
import ActivityQuarter from "./ActivityQuarter";
import ActivityTopic from "./ActivityTopic";
import ActivityType from "./ActivityType";
import ActivityModule from "./ActivityModule";
import ActivityForm from "./ActivityForm";
import { Link } from "react-router-dom";
import API_CALL from "../../../helpers/api";
import { AuthContext } from "../../../store/use-context";
const { Step } = Steps;
const { Text } = Typography;

const ActivityStep = () => {
  const authContext = useContext(AuthContext);
  const [current, setCurrent] = useState(0);
  const [subject, setSubject] = useState(null);
  const [subjectDetail, setSubjectDetail] = useState({
    subjectId: null,
    subjectName: null,
  });
  const [quarter, setQuarter] = useState({ quarter: null, subjectName: null });
  const [topic, setTopic] = useState(null);
  const [activityType, setActivityType] = useState(null);
  const [moduleType, setModuleType] = useState(null);
  const [gradeLevel, setGradeLevel] = useState("1");
  const [subjectIsLoading, setSubjectIsLoading] = useState(true);
  const [topicIsLoading, setTopicIsLoading] = useState(false);

  const getSubject = useRequest(
    {
      method: "GET",
      endPoint: `api/${
        (authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
      }/data/getSubjectsPerGrade`,
    },
    (data) => {
      const filteredData = data.filter((subject) => {
        return subject.status === 0;
      });
      setSubject(filteredData);
      setSubjectIsLoading(false);
    },
    (err) => {}
  );

  const getTopic = useRequest(
    {
      method: "POST",
      endPoint: `api/${
        (authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
      }/data/getTopic`,
    },
    (data) => {
      let filteredTopic = data.filter((e) => {
        return e.status != 1;
      });
      setQuarter({
        quarter: filteredTopic,
        subjectName: subjectDetail.subjectName,
      });
      setTopicIsLoading(false);
    }
  );

  const onChangeSubjectHandler = (value) => {
    setGradeLevel(value);
    getSubject.sendRequest(null, { gradeLevel: value });
  };

  const onClickSubjectHandler = (subjectId, subjectName) => {
    setSubjectDetail({ subjectId, subjectName });
  };

  const onClickQuarterHandler = (value) => {
    console.log(value);
    getTopic.sendRequest(
      { subjectId: subjectDetail.subjectId, quarter: value },
      null
    );
    setTopicIsLoading(true);
  };

  const onClickTopicHandler = (data) => {
    console.log(data);
    setTopic(data);
    setCurrent(1);
  };

  const onActivityTypeClick = (type) => {
    setActivityType({ activityType: type, type: topic });
  };

  const onClickModule = (type, activityId) => {
    setCurrent(2);
    setModuleType({
      moduleType: type,
      activityId: activityId,
      module: activityType,
    });
  };

  useEffect(() => {
    getSubject.sendRequest(null, { gradeLevel: gradeLevel });
  }, []);

  if (subjectIsLoading) {
    return <CustomSpinnerModal isLoading={subjectIsLoading} />;
  }

  if (topicIsLoading) {
    return <CustomSpinnerModal isLoading={topicIsLoading} />;
  }

  const onChange = (value) => {
    if (
      subjectDetail.subjectId !== null &&
      quarter.quarter !== null &&
      topic !== null
    ) {
      setCurrent(value);
    }

    if ((activityType !== null, moduleType !== null)) {
      setCurrent(value);
    }

    if (current === 1 && value === 0) {
      setTopic(null);
      setActivityType(null);
      setModuleType(null);
    }

    if (current === 2 && value === 1) {
      setActivityType(null);
      setModuleType(null);
    }

    if (current === 2 && value === 0) {
      setTopic(null);
      setActivityType(null);
      setModuleType(null);
    }

    setCurrent(value);
  };

  return (
    <Card
      title={
        <Link to="/activity">
          <Button type="primary">{"< Back to Activity"}</Button>
        </Link>
      }
      extra={
        <Text>
          Subject Selected: <h6>{subjectDetail.subjectName}</h6> / Quarter:{" "}
          <h6>{quarter?.quarter?.[0]?.quarter}</h6>
        </Text>
      }
    >
      <Steps current={current} onChange={onChange} labelPlacement="vertical">
        <Step title="Step 1" description="Choose Subject, Quarter and Topic" />
        <Step
          title="Step 2"
          disabled={topic === null ? true : false}
          description="Choose Type of Activity and Module"
        />
        <Step
          title="Step 3"
          disabled={moduleType === null ? true : false}
          description="Create Form"
        />
      </Steps>
      <Col xs={24} md={24} lg={24} style={{ height: "80%" }}>
        <div className="steps-content">
          {(current === 0 && subjectDetail.subjectId === null && (
            <ActivitySubject
              onChangeHandler={onChangeSubjectHandler}
              onClickHandler={onClickSubjectHandler}
              subject={subject}
              gradeLevel={gradeLevel}
            />
          )) ||
            (current === 0 &&
              subjectDetail.subjectId !== null &&
              quarter.quarter === null && (
                <ActivityQuarter
                  onBackHandler={() =>
                    setSubjectDetail({ subjectId: null, subjectName: null })
                  }
                  onClickQuarterHandler={onClickQuarterHandler}
                />
              )) ||
            (current === 0 && quarter.quarter !== null && topic === null && (
              <ActivityTopic
                onBackQuarterHandler={() =>
                  setQuarter({ quarter: null, subjectName: null })
                }
                onClickTopicHandler={onClickTopicHandler}
                subjectName={subjectDetail.subjectName}
                quarter={quarter.quarter}
              />
            )) ||
            (current === 1 && activityType === null && (
              <ActivityType
                onTypeBack={() => {
                  setTopic(null);
                }}
                onActivityTypeClick={onActivityTypeClick}
              />
            )) ||
            (current === 1 && activityType !== null && moduleType === null && (
              <ActivityModule
                onModuleBack={() => {
                  setActivityType(null);
                }}
                onClickModule={onClickModule}
                activityType={activityType.activityType}
              />
            )) ||
            (current === 2 && moduleType !== null && (
              <ActivityForm
                module={moduleType}
                onFormBack={() => {
                  setModuleType(null);
                  setCurrent(1);
                }}
              />
            ))}
        </div>
      </Col>
    </Card>
  );
};

export default ActivityStep;
