import React, { useEffect, useState, useContext } from 'react'
import { Card, Table, Tag, Image, Col, Row, Typography } from 'antd'
import { AuthContext } from '../../../store/use-context';
import useRequest from '../../../hooks/use-request';
import { Colors } from '../../../assets/Constants/Colors';
import {
  CloseSquareOutlined,
  CheckSquareTwoTone,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
const { Text } = Typography;

const SubjectActivities = () => {
  const authContext = useContext(AuthContext);
  const [subject, setSubject] = useState(null);
  const [topics, setTopics] = useState(null);
  const [activity, setActivity] = useState(null);
  const [viewActivity, setViewActivity] = useState(null);
  const [searchSubjectDataSource, setSearchSubjectDataSource] = useState(subject);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubject, setIsLoadingSubject] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageTable, setMessageTable] = useState(null);
  const [activeSubject, setActiveSubject] = useState();
  const [activeTopic, setActiveTopic] = useState();
  const [activeActivity, setActiveActivity] = useState();

  const getSubjects = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/data/getSubjects`,
    },
    (data) => {
      const sortActive = data.sort((a, b) => {
        return a.status - b.status
      }).filter((e) => {
        return e.status === 0;
      })
      setSubject(sortActive);
      setSearchSubjectDataSource(sortActive);
      setMessage(sortActive);
      setIsLoadingSubject(false);
    },
    (err) => {
      setIsLoadingSubject(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const getTopics = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/data/getTopics`,
    },
    (data) => {
      const sortActive = data.sort((a, b) => {
        return a.status - b.status
      }).filter((e) => {
        return e.status === 0;
      })
      setTopics(sortActive);
      setMessage(sortActive);
      setIsLoading(false);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const getActivity = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/getModulesPerTopic`
    },
    (data) => {
      const changeName = data.sort((a, b) => {
        return a.status - b.status;
      }).map((e) => {
        return ((e.activityType === 'FITB' && { ...e, activityType: "Fill in the Blanks" }) ||
          (e.activityType === 'WAM' && { ...e, activityType: "Whack A Mole" }) ||
          (e.activityType === 'MC' && { ...e, activityType: "Multiple Choice" }) ||
          (e.activityType === 'WSC' && { ...e, activityType: "Word Sentence Completion" }) ||
          (e.activityType === 'TF' && { ...e, activityType: "True or False" }) ||
          (e.activityType === 'OTB' && { ...e, activityType: "Open The Box" }) ||
          (e.activityType === 'MT' && { ...e, activityType: "Matching Type" }) ||
          (e.activityType === 'POWTOON' && { ...e, activityType: "POWTOON" }) ||
          (e.activityType === 'SAW' && { ...e, activityType: "Spin A Wheel" }) ||
          (e.activityType === 'Maze' && { ...e, activityType: "Maze Chase" }) ||
          (e.activityType === 'QuizShow' && { ...e, activityType: "QuizShow" }))
      }).filter((e) => {
        return e.status === 0;
      })
      setActivity(changeName);
      setIsLoading(false);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const viewActivityModule = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/getModule`
    },
    (data) => {
      setViewActivity(data);
      setIsLoading(false);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const columnsSubject = [
    {
      title: 'Subject Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Grade Level',
      dataIndex: 'gradeLevel',
      key: 'gradeLevel',
      filters: [
        {
          text: 1,
          value: 1,
        },
        {
          text: 2,
          value: 2,
        },
        {
          text: 3,
          value: 3,
        },
        {
          text: 4,
          value: 4,
        },
        {
          text: 5,
          value: 5,
        },
        {
          text: 6,
          value: 6,
        },
        {
          text: 7,
          value: 7,
        },
        {
          text: 8,
          value: 8,
        },
        {
          text: 9,
          value: 9,
        },
        {
          text: 10,
          value: 10,
        },
      ],
      onFilter: (value, record) => record.gradeLevel === value,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const columnsTopic = [
    {
      title: 'Topic Name',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: 'Quarter',
      dataIndex: 'quarter',
      key: 'quarter',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const columnsActivity = [
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '100px',
      fixed: 'left',
      render: (tags, index) => (
        <span>
          {
            tags === 0 && (<Tag color={Colors.blue} key={index.id}>
              {'ACTIVE'}
            </Tag>) ||
            tags === 1 && (<Tag color={Colors.grey} key={index.id}>
              {'DELETED'}
            </Tag>)
          }
        </span>
      ),
    },
    {
      title: 'Activity Type',
      key: 'activityType',
      dataIndex: 'activityType',
      width: '200px',
      sorter: (a, b) => a.activityType.localeCompare(b.activityType),

    },
    {
      title: 'Module Type',
      key: 'type',
      dataIndex: 'type',
      width: '200px',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Direction',
      key: 'directions',
      dataIndex: 'directions',
      width: '200px',
      sorter: (a, b) => a.directions.localeCompare(b.directions),
      ellipsis: true,
    },
  ];

  const columnsQuestion = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'questionId',
      editable: true,
      width: "90%",
      render: (item, record, index) => {
        return (index + 1) + '. ' + item ? item : ''
      }
    },
  ]

  useEffect(() => {
    getSubjects.sendRequest();
  }, [])

  return (
    <Card
      title="Subject Activities"
    >
      <Table
        scroll={{
          y: 450,
          x: '100vw',
        }}
        columns={columnsSubject}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={columnsTopic}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    columns={columnsActivity}
                    expandable={{
                      expandedRowRender: (record) => (
                        viewActivity?.activity?.choices ? (
                          <Table
                            dataSource={viewActivity?.activity?.choices?.filter((choice, index) => {
                              return choice.status != 1
                            })}
                            columns=
                            {[
                              {
                                title: 'Choice',
                                dataIndex: 'choiceId',
                                key: 'choiceId',
                                editable: true,
                                render: (item, record, index) => {
                                  return 'Choice ' + (index + 1) + '.'
                                },
                              },
                            ]}
                            defaultExpandAllRows={true}
                            expandable={{
                              expandedRowRender: (record, index) => {
                                return <>
                                  <Text>Answer: </Text>
                                  <Row>
                                    <Col xs={1} md={1} lg={1}>
                                      {record.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                    <Image
                                      width={100}
                                      src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                    // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                    />
                                  </Row>
                                </>
                              }
                            }}
                            expandIcon={({ expanded, onExpand, record }) =>
                              expanded ? (
                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                              ) : (
                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                              )
                            }
                            rowKey="choiceId"
                            pagination={{
                              paginationSize: 10,
                            }}
                            style={{ minWidth: '600px', maxWidth: '600px' }}
                          />
                        ) : (
                          <Table
                            id="questionId"
                            dataSource={viewActivity?.activity}
                            columns={columnsQuestion}
                            defaultExpandAllRows={true}
                            expandable={{
                              expandedRowRender: (record) => {
                                console.log(record);
                                return <>
                                  <>
                                    <h5>Question Image: </h5>
                                    {record.image ? (
                                      <Image
                                        width={200}
                                        src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                      // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                      />
                                    ) : (
                                      <Text>No Data Available</Text>
                                    )}
                                  </>
                                  <br />
                                  <br />
                                  <Text>Answer: </Text>
                                </>
                              },
                            }}
                            expandIcon={({ expanded, onExpand, record }) =>
                              expanded ? (
                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                              ) : (
                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                              )
                            }
                            rowKey="questionId"
                            key="questionId"
                            style={{ minWidth: '600px', maxWidth: '600px' }}
                          />
                        )
                      ),
                      rowExpandable: (record) => true,
                      expandedRowKeys: activeActivity,
                      onExpand: (expanded, record) => {
                        const keys = [];
                        if (expanded) {
                          keys.push(record.id);
                        }
                        setActiveActivity(keys);
                        setIsLoading(true);
                        viewActivityModule.sendRequest(null, { moduleId: record.moduleId })
                      }
                    }}
                    rowKey="id"
                    id="id"
                    size="small"
                    dataSource={activity}
                  />
                ),
                rowExpandable: (record) => true,
                expandedRowKeys: activeTopic,
                onExpand: (expanded, record) => {
                  const keys = [];
                  if (expanded) {
                    keys.push(record.id);
                  }
                  setActiveTopic(keys);
                  setIsLoading(true);
                  setActivity(null)
                  getActivity.sendRequest(null, { topicId: record.topicId })
                }
              }}
              loading={isLoading}
              rowKey="id"
              id="id"
              size="small"
              dataSource={topics}
            />
          ),
          rowExpandable: (expanded, record) => true,
          onExpandedRowsChange: (expandedRows) => {
            setActiveTopic([])
            setActiveActivity([])
          },
          expandedRowKeys: activeSubject,
          onExpand: (expanded, record) => {
            const keys = [];
            if (expanded) {
              keys.push(record.id);
            }
            setActiveSubject(keys);
            setIsLoading(true);
            setTopics(null)
            getTopics.sendRequest(null, { subjectId: record.subjectId });
          }
        }}
        rowKey="id"
        id="id"
        size="small"
        dataSource={subject}
      />
    </Card >
  )
}

export default SubjectActivities