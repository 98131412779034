import React from 'react'
import { Player } from 'video-react';
import "../../../../../../node_modules/video-react/dist/video-react.css";
import { Col, Row, Typography, Image } from 'antd';
const { Text } = Typography;

const PowtoonView = ({ searchViewDataSource, }) => {
    const textWithLineBreaks = searchViewDataSource.directions;
    const formattedText = textWithLineBreaks.replace(/\r\n\r\n|\r\n/g, '<br>');
    return (
        <>
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Description: </Text>
                </Col>
                <Col>
                    <div style={{ maxWidth: '600px' }}>
                        <Text style={{ whiteSpace: 'pre-line' }}>{searchViewDataSource.directions}</Text>
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Image/PDF: </Text>
                </Col>
                <Col>
                    <div style={{ minWidth: '600px' }}>
                        {searchViewDataSource?.moduleImage && searchViewDataSource?.moduleImage?.includes(".jpg") ? <Image
                            width={200}
                            src={process.env.REACT_APP_SCHOOL_BOOK + searchViewDataSource?.moduleImage}
                        /> : searchViewDataSource?.moduleImage && searchViewDataSource?.moduleImage?.includes(".pdf") ?
                            <a
                                href={process.env.REACT_APP_SCHOOL_BOOK + searchViewDataSource?.moduleImage}
                                target="_blank"
                                // href={'http://' + window.location.hostname + ':9003/' + item}
                                download="file"
                            >View Attachment</a> : "No data available."}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Video: </Text>
                </Col>
                <Col>
                    <div style={{ minWidth: '600px' }}>
                        {searchViewDataSource?.vid ? <Player
                            playsInline
                            src={process.env.REACT_APP_SCHOOL_BOOK + searchViewDataSource?.vid}
                            // src={'http://' + window.location.hostname + ':9003/' + searchViewDataSource.vid}
                            fluid={false}
                            width={"100%"}
                            height={400}
                        /> : "No data available"}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default PowtoonView