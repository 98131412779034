import React from 'react'
import { Row, Col, Layout, Button, Space } from 'antd'
import QuarterCard from '../../Layout/HoverableCard/HoverableCardQuarter'
import QUARTER_CLOSE from '../../../assets/resources/Images/topic_close.png'
import QUARTER_OPEN from '../../../assets/resources/Images/topic_open.png'

const ActivityQuarter = ({ onBackHandler, onClickQuarterHandler }) => {
    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={onBackHandler}>{`<`}Back to Subject</Button>
            </Row>
            <Row>
                <Col xs={16} md={10} lg={6}>
                    <QuarterCard
                        logoClose={QUARTER_CLOSE}
                        logoOpen={QUARTER_OPEN}
                        title={'Quarter 1'}
                        onClick={() => { onClickQuarterHandler(1) }}
                    />
                </Col>
                <Col xs={16} md={10} lg={6}>
                    <QuarterCard
                        logoClose={QUARTER_CLOSE}
                        logoOpen={QUARTER_OPEN}
                        title={'Quarter 2'}
                        onClick={() => { onClickQuarterHandler(2) }}
                    />
                </Col>
                <Col xs={16} md={10} lg={6}>
                    <QuarterCard
                        logoClose={QUARTER_CLOSE}
                        logoOpen={QUARTER_OPEN}
                        title={'Quarter 3'}
                        onClick={() => { onClickQuarterHandler(3) }}
                    />
                </Col>
                <Col xs={16} md={10} lg={6}>
                    <QuarterCard
                        logoClose={QUARTER_CLOSE}
                        logoOpen={QUARTER_OPEN}
                        title={'Quarter 4'}
                        onClick={() => { onClickQuarterHandler(4) }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default ActivityQuarter