import React from 'react'
import { Player } from 'video-react';
import "../../../../../../node_modules/video-react/dist/video-react.css";
import { Col, Row, Typography, Image } from 'antd';
const { Text } = Typography;

const PerformanceTaskView = ({ searchViewDataSource, }) => {
  const textWithLineBreaks = searchViewDataSource?.directions;
  const formattedText = textWithLineBreaks.replace(/\r\n\r\n|\r\n/g, '<br>');
  return (
    <>
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Description: </Text>
        </Col>
        <Col>
          <div style={{ maxWidth: '600px' }}>
            <Text style={{ whiteSpace: 'pre-line' }}>{searchViewDataSource?.directions}</Text>
          </div>
        </Col>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Student Performance: </Text>
        </Col>
        <Col>
          <div style={{ minWidth: '600px' }}>
            {searchViewDataSource?.isGroup === 1 ? "GROUP" : "INDIVIDUAL"}
          </div>
        </Col>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Lecture: </Text>
        </Col>
        <Col>
          <div style={{ minWidth: '600px' }}>
            {searchViewDataSource?.lecture ? searchViewDataSource?.lecture : "No data available."}
          </div>
        </Col>
      </Row>
      <br />
      <Row style={{ maxWidth: '900px' }}>
        <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
          <Text style={{ fontWeight: 500 }}>Image </Text>
        </Col>
        <Col>
          <div style={{ minWidth: '600px' }}>
            {searchViewDataSource?.moduleImage ? <Image
              width={200}
              src={process.env.REACT_APP_SCHOOL_BOOK + searchViewDataSource?.moduleImage}
            /> : "No data available."}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default PerformanceTaskView