import React, { Suspense, useContext, useEffect } from "react";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Layout from "./components/Layout";
import User from "./components/pages/User/User";
import Activity from "./components/pages/Activity/Activity";
import Home from "./components/pages/Home/Home";
import Login from "./components/pages/Login";
import "antd/dist/antd.min.css";

import { AuthContext } from "./store/use-context";
import Add from "./components/pages/User/Add";
import AddSchool from "./components/pages/School/Add";
import AddSection from "./components/pages/Section/Add";
import AddSubject from "./components/pages/Subject/Add";
import ActivitySubject from "./components/pages/Activity/ActivitySubject";
import ActivityStep from "./components/pages/Activity/ActivityStep";
import School from "./components/pages/School/School";
import Section from "./components/pages/Section/Section";
import Subject from "./components/pages/Subject/Subject";
import NotFound from "./components/pages/404";
import Dashboard from "./components/pages/Dashboard";
import Archive from "./components/pages/Archive/Archive";
import SubjectActivities from "./components/pages/SubjectActivities/SubjectActivities";
import { createBrowserHistory } from "history";
import Library from "./components/pages/Library/Library";
import AddLibrary from "./components/pages/Library/Add";
import DDQuestion from "./components/pages/Activity/Forms/DragDrop/DDQuestion";
const App = () => {
  // const history = useHistory();
  const history = createBrowserHistory();
  const authContext = useContext(AuthContext);
  console.log(authContext.userType);

  useEffect(() => {
    console.log(authContext.userType);
    if (authContext.userType === "3") {
      history.push("/activity");
    } else {
      history.push("/users");
    }
  }, []);

  const Unauthenticated = () => {
    return (
      <Router>
        <Routes>
          <Route path={"/"} element={<Login />} />
          <Route path={"*"} element={<Login />} />
        </Routes>
      </Router>
    );
  };

  const Authenticated = () => {
    return (
      <Router>
        <Layout>
          <Suspense fallback={<h1>Loading...</h1>}>
            <Routes>
              <Route
                path={"/"}
                element={
                  (authContext.userType === "0" ||
                    authContext.userType === "1" ||
                    authContext.userType === "2" ||
                    authContext.userType === "3" ||
                    authContext.userType === "4" ||
                    authContext.userType === "5") && (
                    // <DDQuestion />
                    <Home />
                  )
                }
              />
              <Route
                exact
                path={"/dashboard"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "1" ||
                  authContext.userType === "2" ? (
                    <Dashboard />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path={"/users"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "1" ||
                  authContext.userType === "2" ? (
                    <User />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route exact path={"/users/add"} element={<Add />} />
              <Route
                exact
                path={"/activity"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "3" ? (
                    <Activity />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path={"/activity/subject"}
                element={<ActivitySubject />}
              />
              <Route exact path={"/activity/add"} element={<ActivityStep />} />
              <Route
                exact
                path={"/school"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "1" ? (
                    <School />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route exact path={"/school/add"} element={<AddSchool />} />
              <Route
                exact
                path={"/section"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "1" ||
                  authContext.userType === "2" ? (
                    <Section />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route exact path={"/section/add"} element={<AddSection />} />
              <Route
                exact
                path={"/subject"}
                element={
                  authContext.userType === "0" ? <Subject /> : <NotFound />
                }
              />
              <Route exact path={"/subject/add"} element={<AddSubject />} />
              <Route
                exact
                path={"/subjectactivities"}
                element={
                  authContext.userType === "0" ? (
                    <SubjectActivities />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path={"/announcement"}
                element={
                  authContext.userType === "0" ? <Subject /> : <NotFound />
                }
              />
              <Route
                exact
                path={"/archive"}
                element={
                  authContext.userType === "0" ||
                  authContext.userType === "1" ||
                  authContext.userType === "2" ||
                  authContext.userType === "3" ||
                  authContext.userType === "4" ||
                  authContext.userType === "5" ? (
                    <Archive />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path={"/library"}
                element={
                  authContext.userType === "0" ? <Library /> : <NotFound />
                }
              />
              <Route exact path={"/library/add"} element={<AddLibrary />} />
              {/* <Route path={"*"} element={<NotFound />} /> */}
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    );
  };

  return (
    <>
      {!authContext.isAuthenticated && <Unauthenticated />}
      {authContext.isAuthenticated && <Authenticated />}
    </>
  );
};

export default App;
