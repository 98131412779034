import { Button, Card, Col, Drawer, Form, Popconfirm, Typography, Carousel, Row, Image, Dropdown, Menu, Space, Tag } from "antd";
import {
  DownOutlined,
  UserOutlined,
  EditOutlined,
  FileExcelOutlined,
  SearchOutlined,
  FundViewOutlined
} from "@ant-design/icons";
import React, { useState, useEffect, useContext } from "react";
import { EditorState, convertToRaw } from "draft-js";
import API_CALL from "../../../helpers/api";
import Announcement from "./Announcement";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import useRequest from "../../../hooks/use-request";
import moment from "moment";
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import '../../../assets/resources/style/carousel.css'
import { Colors } from "../../../assets/Constants/Colors";
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import { AuthContext } from "../../../store/use-context";
import ViewAnnouncement from "./ViewAnnouncement";
import EditAnnouncement from "./EditAnnouncement";
import CustomModal from "../../Layout/Modal/Modal";
import base64toFile from '../../../helpers/base64toFile';
import base64FromUrl from '../../../helpers/base64FromUrl';
import convertImgToBase64 from '../../../helpers/convertImgToBase64';
import { decode } from 'base64-arraybuffer';
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const { Paragraph } = Typography;
let SCHOOL = []
let INSTITUTION = []
let DGSI = []

const group = (arr, key, key1) => {
  return [...arr.reduce((acc, o) =>
    acc.set(o[key || key1], (acc.get(o[key || key1]) || []).concat(o))
    , new Map).values()];
}

function Home() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [announcement, setAnnouncement] = useState(null);
  const [searchDataSource, setSearchDataSource] = useState(announcement);
  const [viewedAnnouncement, setViewedAnnouncement] = useState(announcement);
  const [editable, setEditable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [author, setAuthor] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [expand, setExpand] = useState([])
  const [expandDGSI, setExpandDGSI] = useState([])
  const [expandINSTITUTION, setExpandINSTITUTION] = useState([])
  const [expandSCHOOL, setExpandSCHOOL] = useState([])
  const authContext = useContext(AuthContext);

  const addRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/news/addAnnouncement`
    },
    (data) => {
      getAnnouncements.sendRequest();
      setMessage(data.message);
      setIsLoading(false);
      setIsModalVisible(true);
      form.resetFields();
    },
    (err) => {
      setVisible(false);
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const editRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/news/editAnnouncement`
    },
    (data) => {
      setViewVisible(false);
      setEditable(false);
      setMessage(data.message);
      setIsLoading(false);
      setIsModalVisible(true);
      getAnnouncements.sendRequest();
      form.resetFields();
    },
    (err) => {
      setVisible(false);
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const getAnnouncements = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/news/getAnnouncements`
    },
    (data) => {
      const expandArr = [];

      for (let index = 0; index < data.length; index++) {
        expandArr.push({ counter: Math.floor(100000000 + Math.random() * 900000000), expand: false })
      }
      const result = group(data, 'institutionId', 'schoolId');
      for (let index = 0; index < result.length; index++) {
        for (let i = 0; i < result[index].length; i++) {
          if (result[index][i].institutionName) {
            SCHOOL.push(result[index][i])
          }

          if (result[index][i].schoolName) {
            INSTITUTION.push(result[index][i])
          }

          if (!result[index][i].schoolName && !result[index][i].institutionName) {
            DGSI.push(result[index][i])
          }
        }
      }
      setSearchDataSource(data);
      setExpand(expandArr);
      setAnnouncement(data);
      setIsLoading(false);
      setVisible(false);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const getAnnouncement = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/news/getAnnouncement`
    },
    (data) => {
      setViewedAnnouncement(data);
      setIsLoading(false);
      setViewVisible(true)
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err.response.data)
    }
  )

  const onFinishAnnouncement = (values) => {
    console.log(values)
    let formData = new FormData();
    formData.append("institutionId", values.institutionId);
    formData.append("schoolId", values.schoolId);
    // formData.append("content", editorState.getCurrentContent().getPlainText());
    formData.append("content", values.content);
    formData.append("dateFrom", moment(values.date[0]._d).format('YYYY-MM-DD'));
    formData.append("dateTo", moment(values.date[1]._d).format('YYYY-MM-DD'));
    for (let i = 0; i < values.image?.fileList?.length; i++) {
      formData.append("image", values.image?.fileList?.[i].originFileObj);
    }
    formData.append("type", values.type);
    setIsLoading(true);
    addRequest.sendRequest(formData, null)
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const onFinishEditAnnouncement = (values) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("institutionId", form.getFieldValue("institutionId") ? form.getFieldValue("institutionId") : viewedAnnouncement[0]?.institutionId);
    formData.append("schoolId", form.getFieldValue("schoolId") ? form.getFieldValue("schoolId") : viewedAnnouncement[0]?.schoolId);
    formData.append("content", form.getFieldValue("content") ? form.getFieldValue("content") : viewedAnnouncement[0]?.content);
    formData.append("dateFrom", moment(form.getFieldValue("date") ? form.getFieldValue("date")[0]?._d : viewedAnnouncement[0]?.dateFrom).format('YYYY-MM-DD'));
    formData.append("dateTo", moment(form.getFieldValue("date") ? form.getFieldValue("date")[1]._d : viewedAnnouncement[0]?.dateTo).format('YYYY-MM-DD'));
    formData.append("type", values.type);
    if (form.getFieldValue("image")?.fileList) {
      for (let i = 0; i < form.getFieldValue("image")?.fileList.length; i++) {
        if (form.getFieldValue("image")?.fileList[i]?.originFileObj) {
          formData.append("image", form.getFieldValue("image")?.fileList[i]?.originFileObj);
        } else {
          const result = convertImgToBase64(form.getFieldValue("image")?.fileList[i]?.url, async function (baseUrl) {
            formData.append("image", base64toFile(result))
            return await baseUrl;
          })
          result.then(async (res) => {
            return new Promise((resolve, reject) => {
              base64toFile(res).then((result) => {
                resolve(result);
                formData.append("image", result)
                editRequest.sendRequest(formData, { announcementId: viewedAnnouncement[0]?.announcementId })
              })
            })
          })
        }
      }
      editRequest.sendRequest(formData, { announcementId: viewedAnnouncement[0]?.announcementId })
    } else {
      editRequest.sendRequest(formData, { announcementId: viewedAnnouncement[0]?.announcementId })
    }
  }

  const onClose = () => {
    setVisible(false);
  };

  const onViewClose = () => {
    setViewVisible(false);
    setEditable(false);
  };

  const typoExpand = (index) => {
    const expandList = [...expand];
    expandList[index].expand = true;
    expandList[index].counter = !expandList[index].expand ? expandList[index].counter + 0 : expandList[index].counter + 1;
    setExpand(expandList);
  };

  const typoClose = (index) => {
    const expandList = [...expand];
    expandList[index].expand = false;
    expandList[index].counter = !expandList[index].expand ? expandList[index].counter + 0 : expandList[index].counter + 1;
    setExpand(expandList)
  };

  const columns = [
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: '150px',
      render: (row) => (
        <Dropdown overlay={
          <Menu
            items={
              [
                {
                  label: 'View',
                  key: '0',
                  icon: <FundViewOutlined />,
                  onClick: () => {
                    setAuthor(row.authorName)
                    getAnnouncement.sendRequest(null, { announcementId: row.announcementId })
                  }
                },
                (
                  row.status === 'Deactivated' && ({
                    label: 'Activate',
                    key: '2',
                    icon: <UserOutlined />,
                  }) ||
                  row.status === 'Active' && ({
                    label: 'Deactivate',
                    key: '3',
                    icon: <UserOutlined />,
                  })
                )
              ]}
          />
        }
          trigger={['click']}
        >
          <Button type="primary">
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '100px',
      render: (tags) => (
        <span>
          {
            tags === 0 && (<Tag color={Colors.blue} key={tags}>
              {'ACTIVE'}
            </Tag>) ||
            tags === 1 && (<Tag color={Colors.grey} key={tags}>
              {'DEACTIVATED'}
            </Tag>)
          }
        </span>
      ),
    },
    {
      title: 'Date From',
      dataIndex: 'dateFrom',
      width: '150px',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Date To',
      dataIndex: 'dateTo',
      width: '150px',
      render: (row) => {
        return moment(row).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      width: '150px',
      render: (row) => {
        let image = row?.split(";");
        return (
          <Carousel
            effect="slide"
            autoplay
            autoplaySpeed={3000}
            style={{
              height: 100,

            }}
          >
            {image && image.map((img, index) => {
              return (
                <Image key={index}
                  // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                  src={process.env.REACT_APP_SCHOOL_BOOK + img}
                  // src={'http://' + window.location.hostname + ':9003/' + img}
                  preview={{
                    src: process.env.REACT_APP_SCHOOL_BOOK + img
                    // src: 'http://' + window.location.hostname + ':9003/' + img,
                  }}
                ></Image>
              )
            })}
            {!image && <div>
              <p>No Image</p>
            </div>}
          </Carousel>
        );
      },
    },
    {
      title: 'Content',
      dataIndex: 'content',
      ellipsis: true
    },
    {
      title: 'Author',
      dataIndex: 'authorName',
      width: '150px',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '150px',
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      width: '150px',
    },
    {
      title: 'Institution',
      dataIndex: 'institutionName',
      width: '150px',
    },
    {
      title: 'Edited By',
      dataIndex: 'editedBy',
      width: '150px',
    },
  ];

  const editAnnouncement = () => {
    setEditable(true);
  }

  const cancel = () => {
    setEditable(false);
  }

  useEffect(() => {
    getAnnouncements.sendRequest();
  }, [])

  if (isLoading) {
    return <CustomSpinnerModal isLoading={isLoading} />
  }

  const modalOnClose = () => {
    setIsModalVisible(false);
  }

  return (
    <Card
      title="Announcement"
      extra={<Button type="primary" onClick={() => { setVisible(true) }}>Add</Button>}
    >
      <Row justify="space-between">
        <Col span={7}>
          <ExportTableButton
            dataSource={announcement}
            columns={columns}
            btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
            showColumnPicker
          >
            Export to CSV
          </ExportTableButton>
        </Col>
        <Col span={7} offset={8}>
          <SearchTableInput
            columns={columns}
            dataSource={announcement}
            setDataSource={setSearchDataSource}
            inputProps={{
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
            }}
          />
        </Col>
      </Row>
      <Table
        id="announcementId"
        dataSource={searchDataSource}
        columns={columns}
        rowKey="announcementId"
        pagination={{
          paginationSize: 10,
        }}
        scroll={{
          y: 350,
          x: '100vw',
        }}
      />
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onViewClose}
        visible={viewVisible}
        key={"id"}
        className="demo"
        height={"90%"}
        title={"Announcement by: " + author}
        extra={
          editable ? (
            <span>
              {/* <Typography.Link
                onClick={async () => {
                  onFinishEditAnnouncement()
                }}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link> */}
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Button type="primary" onClick={editAnnouncement}>Edit</Button>
          )
        }
      >
        {editable ? (
          <EditAnnouncement
            form={form}
            fields={viewedAnnouncement[0]}
            onFinishEditAnnouncement={onFinishEditAnnouncement}
          />
        ) : (
          <ViewAnnouncement
            announcement={viewedAnnouncement}
          />
        )
        }
      </Drawer>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="demo"
        height={"90%"}
      >
        <Announcement
          form={form}
          onFinishAnnouncement={onFinishAnnouncement}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </Drawer>
      <CustomModal isModalVisible={isModalVisible} handleOk={modalOnClose} handleCancel={modalOnClose}>
        {message}
      </CustomModal>
    </Card >
  );
}

export default Home;
