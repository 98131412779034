import React, { useEffect, useState, useContext } from 'react'
import {
    Row,
    Col,
    Drawer,
    Typography,
    Dropdown,
    Menu,
    Tag,
    Button,
    Space
} from 'antd';
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
import CustomModal from '../../../Layout/Modal/Modal';
import { Colors } from '../../../../assets/Constants/Colors';
import useRequest from '../../../../hooks/use-request';
import {
    FileExcelOutlined,
    SearchOutlined,
    EditOutlined,
    FundViewOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { AuthContext } from '../../../../store/use-context';
import ArchiveTopic from './ArchiveTopic';

const { Text } = Typography;

const ArchiveSubject = () => {
    const [subject, setSubject] = useState(null);
    const [searchSubjectDataSource, setSearchSubjectDataSource] = useState(subject);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubject, setIsLoadingSubject] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageTable, setMessageTable] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTableModalVisible, setIsTableModalVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [parentMenuTitle, setParentMenuTitle] = useState(null);
    const [subjectId, setSubjectId] = useState(null);
    const [topics, setTopics] = useState(null);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const authContext = useContext(AuthContext);

    const getSubjects = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/getSubjects`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                console.log(e)
                return e.status === 1;
            })
            setSubject(sortActive);
            setSearchSubjectDataSource(sortActive);
            setMessage(sortActive);
            setIsLoadingSubject(false);
            // setIsModalVisible(false);
        },
        (err) => {
            setIsLoadingSubject(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getTopics = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/data/getTopics`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 1;
            })
            setTopics(sortActive);
            setMessage(sortActive);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const onViewTopic = async (title, subject) => {
        await getTopics.sendRequest(null, { subjectId: subject })
        setVisible(true);
        setParentMenuTitle(title);
        setSubjectId(subject)
    }

    const columns = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '100px',
            render: (_, record) => {
                return (
                    <Dropdown overlay={
                        <Menu
                            items={
                                [
                                    {
                                        label: 'View',
                                        key: '0',
                                        icon: <FundViewOutlined />,
                                        onClick: () => {
                                            onViewTopic(record.name, record.subjectId);
                                        }
                                    },
                                    // {
                                    //     label: 'Remove',
                                    //     key: '2',
                                    //     icon: <EditOutlined />,
                                    //     onClick: () => {
                                    //         // onDeactivate(record.subjectId);
                                    //     }
                                    // },
                                ]}
                        />
                    }
                        trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }
        },
        {
            title: 'Subject ID',
            key: 'subjectId',
            dataIndex: 'subjectId',
            hidden: true,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            render: (tags) => (
                <span>
                    {
                        (tags === 0 && <Tag color={Colors.blue} key={tags}>
                            {'ACTIVE'}
                        </Tag>) ||
                        (tags === 1 && <Tag color={Colors.grey} key={tags}>
                            {'DEACTIVATED'}
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '150px',
            editable: true,
        },
        {
            title: 'Grade Level',
            dataIndex: 'gradeLevel',
            width: '150px',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '150px',
            editable: true,
        },
    ].filter(item => !item.hidden);

    const onClose = () => {
        setVisible(false);
        setParentMenuTitle(null);
        setSubjectId(null);
    };

    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getSubjects.sendRequest();
    }, [])

    return (
        <>
            <Row justify="space-between">
                <Col span={7}>
                    <ExportTableButton
                        dataSource={subject}
                        columns={columns}
                        btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                        showColumnPicker
                    >
                        Export to CSV
                    </ExportTableButton>
                </Col>
                <Col span={7} offset={8}>
                    <SearchTableInput
                        columns={columns}
                        dataSource={subject}
                        setDataSource={setSearchSubjectDataSource}
                        inputProps={{
                            placeholder: "Search this table...",
                            prefix: <SearchOutlined />,
                        }}
                    />
                </Col>
            </Row>
            <Table
                dataSource={searchSubjectDataSource}
                columns={columns}
                rowClassName="editable-row"
                rowKey="id"
                scroll={{
                    y: 350,
                }}
                loading={isLoadingSubject}
            />
            <Drawer
                title={parentMenuTitle}
                placement="bottom"
                closable={false}
                onClose={onClose}
                visible={visible}
                key={parentMenuTitle}
                className="demo"
                height={childrenDrawer ? "70%" : "90%"}
                extra={
                    <Button type="primary" onClick={showChildrenDrawer}>
                        Add
                    </Button>
                }
            >
                {topics && <ArchiveTopic
                    topics={topics}
                    isLoading={isTableLoading}
                    message={messageTable}
                    visible={isTableModalVisible}
                />}
            </Drawer>
            <CustomModal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
            >
                <Text>{message?.message}</Text>
            </CustomModal>
            <CustomSpinnerModal
                isLoading={isLoading}
            />
        </>
    )
}

export default ArchiveSubject