import React, { useState } from 'react'
import { Collapse, Row, Col, Typography, Button, Space, Upload, Modal } from 'antd';
const { Text } = Typography;

const ViewAnnouncement = (announcement) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewVisible(false);

    const getBase64 = (file) => {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });
    }


    const props = {
        name: '.mp4',
        multiple: true,
        beforeUpload: false,
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
        async onPreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        },
    };
    console.log(announcement.announcement)
    return (
        <>
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Date From: </Text>
                </Col>
                <Space>
                    <Col style={{ float: 'left' }}>
                        <div style={{ maxWidth: '600px', float: 'left' }}>
                            <Text>
                                {announcement.announcement[0].dateFrom}
                            </Text>
                        </div>
                    </Col>
                </Space>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Date To: </Text>
                </Col>
                <Col>
                    <div style={{ maxWidth: '600px' }}>
                        <Text>{announcement.announcement[0].dateTo}</Text>
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Author: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <div>
                        {announcement.announcement[0].authorName}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Institution Name: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <Text style={{ color: announcement.announcement[0].institutionName ? '#000' : '#747487' }}>
                        {announcement.announcement[0].institutionName ? announcement.announcement[0].institutionName : 'Not set'}
                    </Text>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>School Name: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <div>
                        {announcement.announcement[0].schoolName ? announcement.announcement[0].schoolName : 'Not set'}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Author: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <div>
                        {announcement.announcement[0].authorName}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Announcement Type: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <div>
                        {announcement.announcement[0].type}
                    </div>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Images: </Text>
                </Col>
                <Col style={{ minWidth: '600px' }}>
                    <Upload
                        listType="picture-card"
                        {...props}
                        defaultFileList={announcement?.announcement[0]?.image?.split(';').map((e, i) => {
                            return {
                                uid: (i - 1),
                                name: e,
                                status: 'done',
                                url: process.env.REACT_APP_SCHOOL_BOOK + e,
                                // url: 'http://' + window.location.hostname + ':9003/' + e
                            }
                        })}
                        maxCount={3}
                        name="upload"
                    />
                    <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </Col>
            </Row>
            <br />
            <Row style={{ maxWidth: '900px' }}>
                <Col style={{ minWidth: '220px', flexBasis: '33%' }}>
                    <Text style={{ fontWeight: 500 }}>Content: </Text>
                </Col>
                <Col style={{ minWidth: '600px', flexBasis: '33%' }}>
                    <div style={{ textAlign: 'justify', width: 'fit-content' }}>
                        {announcement.announcement[0].content}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ViewAnnouncement