import React, { useState } from 'react'
import '../../../assets/resources/style/subject.css'
import { Table } from 'ant-table-extensions'
import { Colors } from '../../../assets/Constants/Colors'
import { Tag, Space, Form, Typography, Input, InputNumber, Popconfirm } from 'antd';
import CustomModal from '../../Layout/Modal/Modal';

const { Text } = Typography

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber min={1} max={4} /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Topic = ({
    topics,
    deactivateTopic,
    editTopic,
    isLoading,
    visible,
    message,
    handleOk,
}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(topics);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            quarter: '',
            title: '',
            description: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const columns = [

        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            render: (tags) => (
                <span>
                    {
                        (tags === 0 && <Tag color={Colors.blue} key={tags}>
                            {'ACTIVE'}
                        </Tag>) ||
                        (tags === 1 && <Tag color={Colors.grey} key={tags}>
                            {'DEACTIVATED'}
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            width: '100px',
            editable: true,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: '30%',
            editable: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '45%',
            editable: true,
        },
        {
            title: 'Action',
            dataIndex: 'topicId',
            width: '100px',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={async () => {
                                await editTopic({ data: form.getFieldsValue(), topicId: record.topicId })
                                await setEditingKey('');
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Popconfirm
                            title="Are you sure you want to delete?"
                            onConfirm={() => deactivateTopic(record.topicId)}
                        >
                            <a style={{ color: '#ff4d4f' }}>Delete</a>
                        </Popconfirm>
                    </Space>
                );
            },
        },

    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'quarter' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <>
            <Form form={form} component={false}>
                <Table
                    id="topicId"
                    rowKey="topicId"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                    loading={isLoading}
                />
            </Form>
            {visible && (
                <CustomModal
                    isModalVisible={visible}
                    handleOk={handleOk}
                    handleCancel={handleOk}
                    title={message?.message === 'Updated successfully' || message?.message === 'Updated successfully.' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </CustomModal>
            )}
        </>
    );
}

export default Topic 