import React, { useContext, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    Button,
    Row,
    Col,
    Form,
    DatePicker,
    Select,
    Upload,
    Modal,
    Typography,
    Input
} from "antd";
import { PlusCircleOutlined, PlusOutlined, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import API_CALL from "../../../helpers/api";
import useRequest from "../../../hooks/use-request";
import CustomSpinnerModal from "../../Layout/Modal/Spinner";
import moment from "moment";
import { AuthContext } from "../../../store/use-context";
const { Option } = Select;
const { Text } = Typography
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Announcement = ({ form, onFinishAnnouncement, editorState, setEditorState }) => {
    const [institution, setInstitution] = useState([]);
    const [school, setSchool] = useState([]);
    const [profile, setProfile] = useState({});
    const [selectedInstitute, setSelectedInstitute] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSchoolLoading, setIsSchoolLoading] = useState(true);
    const [value, setValue] = useState(null);
    const authContext = useContext(AuthContext);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const getProfile = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "2" && 'admin-school')}/profile/getProfile`,
        },
        (data) => {
            setProfile(data);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const getInstitutions = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti')}/data/getInstitutions`,
        },
        (data) => {
            const filteredInstitution = data.filter((e) => {
                return e.status !== 1
            })
            setInstitution(filteredInstitution);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const getInstitutionPerSchool = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti')}/data/getInstitutionPerSchool`,
        },
        (data) => {
            const filteredInstitution = data.filter((e) => {
                return e.status !== 1
            })
            setInstitution(filteredInstitution);
            setIsSchoolLoading(false);
        },
        (err) => {
        }
    );

    const getSchools = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti')}/data/getSchools`,
        },
        (data) => {
            const filteredSchool = data.filter((e) => {
                return e.status !== 1
            })
            setSchool(filteredSchool);
            setIsSchoolLoading(false);
        },
        (err) => {
        }
    );

    const getSchoolPerInstitution = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti')}/data/getSchoolPerInstitution`,
        },
        (data) => {
            const filteredSchool = data.filter((e) => {
                return e.status !== 1
            })
            setSchool(filteredSchool);
            setIsSchoolLoading(false);
        },
        (err) => {
        }
    );

    const handleCancel = () => setPreviewVisible(false);

    const getBase64 = (file) => {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });
    }

    const props = {
        name: '.mp4',
        multiple: false,
        beforeUpload: (file) => {
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
        async onPreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            setPreviewImage(file.url || file.preview);
            setPreviewVisible(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        },
    };

    useEffect(() => {
        const getInstitution = async () => {
            return new Promise(resolve => {
                getInstitutions.sendRequest();
                resolve();
            })
        }

        const getSchool = async () => {
            setIsLoading(true);
            return new Promise(resolve => {
                getSchools.sendRequest();
                resolve();
            })
        }

        if ((authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti')) {
            getInstitution();
            getSchool();
        } else {
            getProfile.sendRequest();
        }
    }, [])

    if (isLoading) {
        return <CustomSpinnerModal isLoading={isLoading} />
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            console.log(reader);
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append("file", file);
                // const res = await uploadFile(form_data);
                setValue("thumbnail", file);
                // resolve({ data: { link: process.env.REACT_APP_API + res.data } });
            };
            reader.readAsDataURL(file);
        });
    };

    const config = {
        image: { uploadCallback: uploadCallback },
    };

    const dateFormat = 'YYYY-MM-DD';

    const institutionOnChange = (value) => {
        if (value === undefined) {
            getSchools.sendRequest();
            getInstitutions.sendRequest()
            form.setFieldsValue({ schoolId: "" });
        } else {
            setSelectedInstitute(value);
            getSchoolPerInstitution.sendRequest(null, { institutionId: value });
            form.setFieldsValue({ schoolId: "" });
        }
    }

    const schoolOnChange = (value, e) => {
        if (e === undefined) {
            getInstitutions.sendRequest()
        } else {
            getInstitutionPerSchool.sendRequest(null, { institutionId: e?.institutionId });
            form.setFieldsValue({ institutionId: e?.institutionId });
        }
    }

    return (
        <Form
            form={form}
            name="register"
            onFinish={onFinishAnnouncement}
            initialValues={{
                schoolId: profile ? profile.schoolId : null,
                institutionId: profile ? profile.institutionId : null,
            }}
            scrollToFirstError
        >
            <div>
                <h1>Add Announcement</h1>
            </div>
            <Row className='row'>
                <Col
                    xs={24}
                    md={12}
                    lg={8}
                >
                    <Form.Item
                        name="type"
                        label="Type"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select type of announcement"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            <Option value={'NOTICE'}>NOTICE</Option>
                            <Option value={'ADS'}>ADS</Option>
                        </Select>
                    </Form.Item>

                </Col>
                <Col
                    xs={24}
                    md={12}
                    lg={8}
                >
                    <Form.Item
                        name="institutionId"
                        label="Institution"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select school"
                            onChange={authContext.userType === "2" ? institutionOnChange : null}
                            allowClear={profile ? false : true}
                        >
                            {profile && <Option value={profile?.institutionId}>{profile?.institutionName}</Option>}
                            {institution.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.institutionId}>{obj.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    md={12}
                    lg={8}
                >
                    <Form.Item
                        name="schoolId"
                        label="School"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select school"
                            onChange={authContext.userType === "2" ? schoolOnChange : null}
                            allowClear={profile ? false : true}
                        >
                            {profile && <Option value={profile?.schoolId}>{profile?.name}</Option>}
                            {school.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.schoolId} institutionId={obj.institutionId}>{obj.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={24}
                    md={12}
                    lg={8}
                >
                    <Form.Item
                        name="date"
                        label="Select Date"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <RangePicker
                            initialvalues={[moment(dateFormat), moment(dateFormat)]}
                            format={dateFormat}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    md={12}
                    lg={12}
                >
                    <Text>Image: (Maximum of 3)</Text>
                    <Form.Item
                        name="image"
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <Upload
                            listType="picture-card"
                            {...props}
                            maxCount={3}
                            name="upload"
                        >
                            <div>
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </div>
                        </Upload>
                    </Form.Item>
                    <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt="example"
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24} lg={24}>
                    <Form.Item
                        name="content"
                        label="Content"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <TextArea
                            rows={4}
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                    </Form.Item>
                    {/* <div style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                        <Editor
                            toolbar={config}
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            placeholder="Write your comment here"
                        />
                    </div>
                    <br></br> */}
                </Col>
            </Row>
            <Row className='button'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    );
}

export default Announcement