import React, { useContext, useState } from 'react'
import { Form } from 'antd'
import FormInput from './Form'
import useRequest from '../../../../hooks/use-request'
import { AuthContext } from '../../../../store/use-context'
import API_CALL from "../../../../helpers/api";

const Institution = () => {
    const authContext = useContext(AuthContext);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addInstitution`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => {
        setIsModalVisible(false);
        setMessage(null);
    };

    const onFinish = (values) => {
        console.log(values);
        addRequest.sendRequest({
            institutionIdNumber: values.InstitutionIdNumber,
            name: values.name,
            address: values.address,
            email: values.email,
            contactNumber: values.contactNumber,
            headName: values.headName,
            headContactNumber: values.prefix + values.headContactNumber
        }, null)
    };

    return (
        <FormInput
            schoolName={'Institution '}
            handleOk={handleOk}
            onFinish={onFinish}
            isLoading={isLoading}
            isModalVisible={isModalVisible}
            message={message}
            form={form}
        />
    )
}

export default Institution