import { Form, Row, Card, Col, Radio, Input, Typography, Upload, Button, message as ANTMESSAGE } from 'antd'
import {
    PlusCircleOutlined,
    DeleteOutlined,
    InboxOutlined
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react'
import API_CALL from '../../../../../helpers/api';
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import CustomModal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';

const { Text } = Typography;
const { TextArea } = Input;

const JLQuestion = ({ moduleId, module, viewAdd, onSubmit }) => {
    const authContext = useContext(AuthContext)
    const [question, setQuestion] = useState(null);
    const [questionShowed, setQuestionShowed] = useState(null);
    const [answer, setAnswer] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleJLQuestion`,
        },
        (data) => {
            console.log(data)
            setMessage(data);
            setQuestion(null)
            setTotalItems(0)
            viewAdd === true && onSubmit()
            setIsModalVisible(true);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => setIsModalVisible(false)
    const handleCancel = () => setIsModalVisible(false)

    const questionOnChange = (e) => {
        setQuestion(e.target.value);
    }

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const props = {
        accept: "image/png",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    useEffect(() => {
        form.setFieldsValue({ 'question': question })
    }, [])

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        let answers = [];
        let formData = new FormData();

        formData.append("image", values.image?.[0] === undefined ? '' : values.image?.[0].originFileObj);

        formData.append("question", question);
        formData.append("totalItems", totalItems);
        values.choices.map((e, index) => {
            answers.push({ [1]: e })
        })
        formData.append("answers", JSON.stringify(answers));
        setIsLoading(true);
        addRequest.sendRequest(formData, { moduleId: moduleId })
        form.resetFields()
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Form
                name="dynamic_form_item"
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    name="image"
                    label="Upload Image: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple="false"
                >
                    <Upload.Dragger
                        {...props}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <br></br>
                <Form.Item
                    name="question"
                    label="Question"
                >
                    <TextArea
                        rows={4}
                        onChange={(e) => questionOnChange(e)}
                        value={question}
                    />
                    <Button
                        name="minus"
                        icon={<DeleteOutlined />}
                        disabled
                        style={{ display: 'none' }}
                        onClick={() => {
                            const lastIndex = question.lastIndexOf((totalItems) + '.__________');
                            const replacement = '';
                            const replaced =
                                question.substring(0, lastIndex) +
                                replacement +
                                question.substring(lastIndex + 15);
                            setQuestion(replaced);
                            setTotalItems(totalItems - 1);
                        }}
                    />
                </Form.Item>
                <Form.List
                    name="choices"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <Card
                            size="small"
                            title="Asnwers"
                            extra={<Button type="primary" onClick={() => {
                                if (fields.length < 1) {
                                    add();
                                    setQuestion(question + (totalItems + 1) + '.__________');
                                    setQuestionShowed(questionShowed + (totalItems + 1) + '.');
                                    setTotalItems(totalItems + 1);
                                } else {
                                    ANTMESSAGE.warning("You can only add one answer")
                                }
                            }}>Add</Button>}
                        >
                            {fields.map((field, index) => (
                                <Form.Item
                                    required={true}
                                    key={field.key}
                                    label={"Asnwer " + (+index + 1)}
                                >
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Item
                                                    {...field}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input text or delete this field.",
                                                        },
                                                    ]}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Input
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        onBlur={() => {
                                                            setQuestionShowed(questionShowed + form.getFieldValue('choices')[index] + ' ')
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <DeleteOutlined
                                                    className="dynamic-delete-button"
                                                    disabled={index + 1 === fields.length ? false : true}
                                                    onClick={index + 1 !== fields.length ? null : () => {
                                                        console.log((totalItems) + '.')
                                                        remove(field.name);
                                                        const lastIndex = question.lastIndexOf((totalItems) + '.__________');
                                                        const replacement = '';
                                                        const replaced =
                                                            question.substring(0, lastIndex) +
                                                            replacement +
                                                            question.substring(lastIndex + 15);
                                                        setQuestion(replaced);
                                                        const lastIndexShowed = questionShowed.lastIndexOf((totalItems) + '.Red');
                                                        const replacementShowed = '';
                                                        const replacedShowed =
                                                            questionShowed.substring(0, lastIndexShowed) +
                                                            replacementShowed +
                                                            questionShowed.substring(lastIndexShowed + 99999);
                                                        setQuestionShowed(replacedShowed);
                                                        setTotalItems(totalItems - 1);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                </Form.Item>
                            ))}
                        </Card>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Submit
                    </Button>
                </Form.Item>
                <CustomModal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={message?.messagE === 'Added successfully' ? 'Success' : 'Error'}
                >
                    <Text>{message?.messagE}</Text>
                </CustomModal>
                <CustomSpinnerModal isLoading={isLoading} />
            </Form>
        </>
    )
}

export default JLQuestion