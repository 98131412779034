import React, { useEffect, useState } from 'react'
import useRequest from '../../../hooks/use-request';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import { Button, Col, Layout, Row, Select } from 'antd'
import SubjectCard from '../../Layout/HoverableCard/HoverableCard';
import SubjectAP from '../../../assets/resources/Images/subject_ap.png';
import SubjectMATH from '../../../assets/resources/Images/subject_math.png';
import SubjectSCIENCE from '../../../assets/resources/Images/subject_science.png';
import SubjectENGLISH from '../../../assets/resources/Images/subject_english.png';
import SubjectPE from '../../../assets/resources/Images/subject_pe.png';
import '../../../assets/resources/style/activitySubject.css'
import { Link, useNavigate, useLocation } from 'react-router-dom';
const { Option } = Select;
const { Content } = Layout;

const ActivityTopic = ({ onClickTopicHandler, quarter, subjectName, onBackQuarterHandler }) => {
    const capitalize = (word) => {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }

    return (
        <Content>
            <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={onBackQuarterHandler}>{`<`}Back to Quarter</Button>
            </Row>
            <Row className='row'>
                {quarter.map((obj, index) => {
                    return (
                        <Col key={index} xs={16} md={10} lg={6}>
                            <SubjectCard
                                logo={
                                    (subjectName.includes('Math') && SubjectMATH) ||
                                    (subjectName.includes('English') && SubjectENGLISH) ||
                                    (subjectName.includes('Araling') && SubjectAP) ||
                                    (subjectName.includes('Physical') && SubjectPE) ||
                                    (subjectName.includes('Science') && SubjectSCIENCE)
                                }
                                title={obj.title}
                                description={obj.description}
                                onClick={() => { onClickTopicHandler({ subjectId: obj.subjectId, topicId: obj.topicId, quarter: obj.quarter }) }}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Content >
    )
}

export default ActivityTopic