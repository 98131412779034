import React, { useContext, useState } from 'react'
import QuestionForm from '../QuestionForm'
import moment from 'moment';
import { Form, Typography } from 'antd';
import useRequest from '../../../../../hooks/use-request';
import Modal from '../../../../Layout/Modal/Modal';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import QuizQuestion from './MultipleChoiceQuestion';
import API_CALL from "../../../../../helpers/api";
import { AuthContext } from '../../../../../store/use-context';
const { Text } = Typography;

const MultipleChoice = ({ topicId, subjectId, activityId, type, module, onFormBack }) => {
    const authContext = useContext(AuthContext)
    const [saveAnswer, setSaveAnswer] = useState(false);
    const [scoreValidation, setScoreValidation] = useState(false);
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [continueQuestion, setContinueQuestion] = useState(false);
    const [response, setResponse] = useState(null)
    const [form] = Form.useForm();

    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleMC`,
        },
        (data) => {
            console.log(data)
            setMessage(data);
            setResponse(data.moduleId);
            setIsModalVisible(true);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const format = 'mm:ss';

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        addRequest.sendRequest({
            subjectId: subjectId,
            topicId: topicId,
            type: type,
            activityId: activityId,
            directions: values.instruction,
            lecture: values?.lecture,
            retryLimit: values.retryLimit,
            scoreValidation: scoreValidation === true ? 1 : 0,
            isSaveAnswers: saveAnswer === true ? 1 : 0,
            isGroup: values?.isGroup ? values?.isGroup : 0
        }, null)
        setIsLoading(true);
    };

    const onSaveAnswerChange = (e) => {
        setSaveAnswer(e)
    }

    const onScoreValidationChange = (e) => {
        setScoreValidation(e)
    }

    const handleOk = () => {
        setIsModalVisible(false);
        if (message?.message === 'Added successfully') {
            setContinueQuestion(true);
        } else {
            form.resetFields();
            setContinueQuestion(false);
        }
    }

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    }

    return (
        <>
            {continueQuestion !== true && (< QuestionForm
                module={module}
                onModuleBackHandler={onFormBack}
                onSaveAnswerChange={onSaveAnswerChange}
                onScoreValidationChange={onScoreValidationChange}
                form={form}
                onFinish={onFinish}
                groupings={type === "PERFORMANCE TASK" ? true : false}
            ></QuestionForm>)
            }
            {
                continueQuestion === true && (
                    <QuizQuestion
                        moduleId={response}
                        module={module}
                    />
                )
            }
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
            >
                <Text>{message?.message} {message?.message === 'Added successfully' ? ', Do you wish to continue adding questions?' : ''}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading} />
        </>
    )
}

export default MultipleChoice