const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const promise = (waitTime, action, data) => {
    return wait(waitTime).then(() => {
        return new Promise((resolve, reject) => {
            if (action === 'resolve') {
                resolve(data);
            } else {
                reject(data);
            }
        });
    });
}

export default promise