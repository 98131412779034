import { createContext, useState } from "react";

export const AuthContext = createContext({
    token: null,
    currentUser: null,
    userType: null,
    userData: null,
    isAuthenticated: false,
    isLoading: true,
    authenticate: () => { },
    logout: () => { },
});

const AuthContextProvider = ({ children }) => {
    const initialToken = JSON.parse(localStorage.getItem("userData"));
    const [authToken, setAuthToken] = useState(initialToken?.token);
    const [currentUser, setCurrentUser] = useState(initialToken?.currentUser);
    const [userType, setUserType] = useState(initialToken?.userType);
    const [userData, setUserData] = useState(initialToken?.userData);
    const userIsLoggedIn = !!authToken;

    const authenticate = ({ token, currentUser, userType, userData }) => {
        setAuthToken(token);
        setCurrentUser(currentUser);
        setUserType(userType);
        setUserData(userData);
        localStorage.setItem("userData", JSON.stringify({ token, currentUser, userType, userData }));
    };

    const logout = async () => {
        setAuthToken(null);
        setCurrentUser(null);
        setUserType(null);
        setUserData(null);
        await localStorage.removeItem("userData");
    };

    const value = {
        token: authToken,
        currentUser: currentUser,
        userType: userType,
        userData: userData,
        isAuthenticated: userIsLoggedIn,
        authenticate: authenticate,
        logout: logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
