import { Drawer } from 'antd';
import FIBQuestion from "./Forms/FillInTheBlanks/FIBQuestion";
import WhackAMoleChoice from "./Forms/WhackAMole/WhackAMoleChoice";
import QuizQuestion from "./Forms/Quiz/QuizQuestion";
import OTBQuestion from "./Forms/OpenTheBox/OTBQuestion";
import MultipleChoice from "./Forms/MutipleChoice/MultipleChoiceQuestion";
import PowtoonEdit from "./Forms/Powtoon/PowtoonEdit";
import SpinAWheelQuestion from "./Forms/SpinAWheel/SpinAWheelQuestion";
import MTQuestion from "./Forms/MatchingType/MTQuestion";
import WSCQuestion from "./Forms/WordSentenceCompletion/WSCQuestion";
import React from 'react'
import MazeChaseQuestion from './Forms/MazeChase/MazeChaseQuestion';
import TrueFalseQuestionForm from './Forms/TrueFalse/TrueFalseQuestionForm';
import JumbledLetters from './Forms/JumbledLetters/JumbledLetters';
import JLQuestion from './Forms/JumbledLetters/JLQuestion';
import Crossword from './Forms/Crossword/Crossword';
import CWQuestion from './Forms/Crossword/CWQuestion';
import DDQuestion from './Forms/DragDrop/DDQuestion';

const ActivityAdd = ({
    parentMenuTitle,
    childrenVisible,
    addModuleQuestionClose,
    visible,
    activityType,
    moduleId,
    viewActivity,
    viewActivityModule,
}) => {
    console.log(activityType, "ACCCCC")

    return (
        <Drawer
            title={parentMenuTitle}
            placement="bottom"
            width={320}
            height={childrenVisible ? "80%" : "0%"}
            closable={false}
            onClose={addModuleQuestionClose}
            visible={visible}
        >
            {activityType === 'POWTOON' && (
                <PowtoonEdit moduleId={moduleId} module={'POWTOON'} />
            )}
            {activityType === 'Fill in the Blanks' && (
                <FIBQuestion moduleId={moduleId} module={'FILL IN THE BLANKS'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Whack A Mole' && (
                <WhackAMoleChoice moduleId={moduleId} module={'WHACK A MOLE'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'QuizShow' && (
                <QuizQuestion moduleId={moduleId} module={'QUIZ SHOW'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'True or False' && (
                <TrueFalseQuestionForm moduleId={moduleId} module={'True or False'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Open The Box' && (
                <OTBQuestion moduleId={moduleId} module={'OPEN THE BOX'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Multiple Choice' && (
                <MultipleChoice moduleId={moduleId} module={'Multiple Choice'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Spin A Wheel' && (
                <SpinAWheelQuestion moduleId={moduleId} module={'SPIN A WHEEL'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Matching Type' && (
                <MTQuestion moduleId={moduleId} module={'MATCHING TYPE'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Word Sentence Completion' && (
                <WSCQuestion moduleId={moduleId} module={'WORD SENTENCE COMPLETION'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Maze Chase' && (
                <MazeChaseQuestion moduleId={moduleId} module={'MAZE CHASE'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Jumbled Letters' && (
                <JLQuestion moduleId={moduleId} module={'JUMBLED LETTERS'} viewAdd={true} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Crosswords' && (
                <CWQuestion moduleId={moduleId} module={'CROSSWORDS'} viewAdd={true} viewActivity={viewActivity} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
            {activityType === 'Drag and Drop' && (
                <DDQuestion moduleId={moduleId} module={'DRAG AND DROP'} viewAdd={true} viewActivity={viewActivity} onSubmit={() => {
                    viewActivityModule.sendRequest(null, { moduleId })
                }} />
            )}
        </Drawer>
    )
}

export default ActivityAdd