import { Modal, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './modal.css'
const { Text } = Typography

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

const CustomSpinnerModal = ({ isLoading }) => {
    return (
        <>
            <Modal
                visible={isLoading}
                closable={false}
                footer={null}
                centered
            >
                <Spin indicator={antIcon} />
                <Text style={{ marginLeft: '24px' }}>Loading. Please wait...</Text>
            </Modal>
        </>
    );
};

export default CustomSpinnerModal;