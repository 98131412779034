import React, { useState } from 'react'
import {
    Row,
    Button,
    Col,
    Form,
    Input,
    Select,
    DatePicker,
    Typography,
} from 'antd'
import Modal from '../../../Layout/Modal/Modal';
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
const { Text } = Typography
const { Option } = Select;

const SchoolForm = ({
    schoolName,
    form,
    handleOk,
    onFinish,
    isLoading,
    isModalVisible,
    message,
    children
}) => {

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="63">+63</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                prefix: '63',
            }}
            scrollToFirstError
        >
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name={schoolName === 'School ' ? 'School' : 'Institution' + "IdNumber"}
                        label={schoolName + "Id Number"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="name"
                        label={schoolName}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: `Please input your ${schoolName} name`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                {children ? children : null}
            </Row>
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="headName"
                        label="Head Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="headContactNumber"
                        label="Head Mobile Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="contactNumber"
                        label={schoolName + 'Contact Number'}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12} lg={12}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                    <Form.Item
                        name="address"
                        label="Address"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='button'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Row>
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
            >
                <Text>{message?.message}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading} />
        </Form>
    )
}

export default SchoolForm