const Colors = {
    primary: '#1292B4',
    lightBlue: '#59a2f1',
    white: '#FFF',
    lighter: '#F3F3F3',
    light: '#DAE1E7',
    dark: '#444',
    darker: '#222',
    black: '#000',
    red: '#f00',
    blue: '#0274be',
    green: '#439e04',
    grey: '#717171',
}

export {
    Colors
};