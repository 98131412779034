import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Tag,
    Dropdown,
    Menu,
    Row,
    Col,
    Space,
    Segmented,
    Card,
    Input,
    InputNumber,
    Form,
    Typography,
    Popconfirm
} from "antd";
import {
    DownOutlined,
    UserOutlined,
    EditOutlined,
    FileExcelOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Colors } from '../../../assets/Constants/Colors';
import {
    ExportTableButton,
    SearchTableInput,
    Table
} from "ant-table-extensions";
import { Link } from "react-router-dom";
import '../../../assets/resources/style/school.css';
import useRequest from '../../../hooks/use-request';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import { calculateColumnsWidth } from '../../../helpers/table';
import API_CALL from '../../../helpers/api';
import { AuthContext } from '../../../store/use-context';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = (inputType === 'number' && <InputNumber min={1} max={12} />) ||
        (inputType === 'text' && <Input />)
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            type: dataIndex === 'email' && 'email',
                            message: dataIndex === 'email' && 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                            pattern: dataIndex === 'mobileNumber' && new RegExp(/^[0-9]+$/)
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const School = () => {
    const [school, setSchool] = useState(null);
    const [searchSchoolDataSource, setSearchSchoolDataSource] = useState(school);
    const [institution, setInstitution] = useState(null);
    const [searchInstitutionDataSource, setSearchInstitutionDataSource] = useState(institution);
    const [isLoading, setIsLoading] = useState(true);
    const [isInstitutionLoading, setIsInstitutionLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const [value, setValue] = useState('School');
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);
    const maxWidthPerCell = 600;

    const columns = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '100px',
            fixed: 'left',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={async () => {
                                await onEditSchool({ data: form.getFieldsValue(), schoolId: record.schoolId })
                                await setEditingKey('');
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Dropdown overlay={
                        <Menu
                            items={
                                [
                                    {
                                        label: 'Edit',
                                        key: '1',
                                        icon: <EditOutlined />,
                                        onClick: () => {
                                            edit(record)
                                        }
                                    },
                                    (
                                        record.status === 1 && ({
                                            label: 'Activate',
                                            key: '2',
                                            icon: <UserOutlined />,
                                        }) ||
                                        record.status === 0 && ({
                                            label: 'Deactivate',
                                            key: '3',
                                            icon: <UserOutlined />,
                                            onClick: () => {
                                                onDeactivateSchool(record.schoolId)
                                            }
                                        })
                                    )
                                ]}
                        />
                    }
                        trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            fixed: 'left',
            render: (tags) => (
                <span>
                    {
                        tags === 0 && (<Tag color={Colors.blue} key={tags}>
                            ACTIVE
                        </Tag>) ||
                        tags === 1 && (<Tag color={Colors.grey} key={tags}>
                            DEACTIVATED
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'School Number',
            key: 'schoolIdNumber',
            dataIndex: 'schoolIdNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: '100px',
            editable: true,
        },
        {
            title: 'Address',
            key: 'address',
            dataIndex: 'address',
            width: '100px',
            editable: true,
        },
        {
            title: 'Contact Number',
            key: 'contactNumber',
            dataIndex: 'contactNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Head Name',
            key: 'headName',
            dataIndex: 'headName',
            width: '100px',
            editable: true,
        },
        {
            title: 'Head Contact Number',
            key: 'headContactNumber',
            dataIndex: 'headContactNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            width: '100px',
            editable: true,
        },
    ];

    const columnsInstitution = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '100px',
            fixed: 'left',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={async () => {
                                await onEditSchool({ data: form.getFieldsValue(), schoolId: record.schoolId })
                                await setEditingKey('');
                            }}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Dropdown overlay={
                        <Menu
                            items={
                                [
                                    {
                                        label: 'Edit',
                                        key: '1',
                                        icon: <EditOutlined />,
                                        onClick: () => {
                                            edit(record)
                                        }
                                    },
                                    (
                                        record.status === 1 && ({
                                            label: 'Activate',
                                            key: '2',
                                            icon: <UserOutlined />,
                                        }) ||
                                        record.status === 0 && ({
                                            label: 'Deactivate',
                                            key: '3',
                                            icon: <UserOutlined />,
                                            onClick: () => {
                                                onDeactivateSchool(record.schoolId)
                                            }
                                        })
                                    )
                                ]}
                        />
                    }
                        trigger={['click']}
                    >
                        <Button type="primary">
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                )
            }

        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            fixed: 'left',
            render: (tags) => (
                <span>
                    {
                        tags === 0 && (<Tag color={Colors.blue} key={tags}>
                            ACTIVE
                        </Tag>) ||
                        tags === 1 && (<Tag color={Colors.grey} key={tags}>
                            DEACTIVATED
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'School Number',
            key: 'schoolIdNumber',
            dataIndex: 'schoolIdNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: '100px',
            editable: true,
        },
        {
            title: 'Address',
            key: 'address',
            dataIndex: 'address',
            width: '100px',
            editable: true,
        },
        {
            title: 'Contact Number',
            key: 'contactNumber',
            dataIndex: 'contactNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Head Name',
            key: 'headName',
            dataIndex: 'headName',
            width: '100px',
            editable: true,
        },
        {
            title: 'Head Contact Number',
            key: 'headContactNumber',
            dataIndex: 'headContactNumber',
            width: '100px',
            editable: true,
        },
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            width: '100px',
            editable: true,
        },
    ];

    const getInstitutions = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getInstitutions`
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                console.log(e)
                return e.status === 0;
            })
            setInstitution(sortActive);
            setSearchInstitutionDataSource(sortActive);
            setIsInstitutionLoading(false)
        },
        (err) => {
            setIsInstitutionLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSchools = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSchools`
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                console.log(e)
                return e.status === 0;
            })
            setSchool(sortActive);
            setSearchSchoolDataSource(sortActive);
            setIsLoading(false)
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const editSchool = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/editSchool`,
        },
        (data) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(data);
            getSchools.sendRequest();
        },
        (err) => {
            setIsModalVisible(true);
            setIsLoading(false);
            setMessage(err)
        }
    )

    const deactivateSchool = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/data/removeSchool`,
        },
        (data) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(data);
            getSchools.sendRequest();
        },
        (err) => {
            setIsModalVisible(true);
            setIsLoading(false);
            setMessage(err)
        }
    )

    const onEditSchool = ({ schoolId, data }) => {
        setIsLoading(true);
        editSchool.sendRequest({ ...data }, { schoolId })
    }

    const onDeactivateSchool = (schoolId) => {
        setIsLoading(true)
        deactivateSchool.sendRequest(null, { schoolId });
    }

    useEffect(() => {
        getSchools.sendRequest();
        authContext.userType === '0' && getInstitutions.sendRequest();
    }, [])

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({
            schoolIdNumber: '',
            name: '',
            address: '',
            contactNumber: '',
            headName: '',
            headContactNumber: '',
            email: '',
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    // if (isLoading) {
    //     return <CustomSpinnerModal isLoading={isLoading} />
    // }

    // if (authContext.userType === '0' && isInstitutionLoading) {
    //     return <CustomSpinnerModal isLoading={isInstitutionLoading} />
    // }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'gradeLevel' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const mergedColumnsInstitution = columnsInstitution.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'gradeLevel' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Card
            title="School"
            extra={
                <Link to={'/school/add'}>
                    <Button type="primary">Add</Button>
                </Link>
            }
        >
            <Row>
                <Col xs={24} md={12} lg={8}>
                    <Segmented
                        options={authContext.userType === '0' ? ['School', 'Institution'] : ['School']}
                        value={value}
                        onChange={async (value) => {
                            setValue(value)
                            await setEditingKey('');
                        }}
                        style={{
                            color: Colors.blue,
                        }}
                    />
                </Col>
                {value === 'School' && (
                    <Col key="School" xs={24} md={24} lg={24} className="table">
                        <Row justify="space-between">
                            <Col span={7}>
                                <ExportTableButton
                                    dataSource={school}
                                    columns={columns}
                                    btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                                    showColumnPicker
                                >
                                    Export to CSV
                                </ExportTableButton>
                            </Col>
                            <Col span={7} offset={8}>
                                <SearchTableInput
                                    columns={columns}
                                    dataSource={school}
                                    setDataSource={setSearchSchoolDataSource}
                                    inputProps={{
                                        placeholder: "Search this table...",
                                        prefix: <SearchOutlined />,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Form form={form} component={false}>
                            <Table
                                key="School"
                                dataSource={searchSchoolDataSource}
                                columns={mergedColumns}
                                rowKey="id"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                pagination={{
                                    // paginationSize: 10,
                                    onChange: cancel,
                                }}
                                scroll={{
                                    y: 350,
                                    x: '100vw',
                                }}
                                loading={isLoading}
                            />
                        </Form>
                    </Col>
                )}
                {value === 'Institution' && (
                    <Col key="Institution" xs={24} md={24} lg={24} className="table">
                        <Row justify="space-between">
                            <Col span={7}>
                                <ExportTableButton
                                    dataSource={institution}
                                    columns={columnsInstitution}
                                    btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                                    showColumnPicker
                                >
                                    Export to CSV
                                </ExportTableButton>
                            </Col>
                            <Col span={7} offset={8}>
                                <SearchTableInput
                                    columns={columnsInstitution}
                                    dataSource={institution}
                                    setDataSource={setSearchInstitutionDataSource}
                                    inputProps={{
                                        placeholder: "Search this table...",
                                        prefix: <SearchOutlined />,
                                    }}
                                />
                            </Col>
                        </Row>
                        <Form form={form} component={false}>
                            <Table
                                key="Institution"
                                dataSource={searchInstitutionDataSource}
                                columns={mergedColumnsInstitution}
                                rowKey="id"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                pagination={{
                                    // paginationSize: 10,
                                    onChange: cancel,
                                }}
                                scroll={{
                                    y: 350,
                                    x: '100vw',
                                }}
                                loading={isLoading}
                            />
                        </Form>
                    </Col>
                )}
            </Row>
        </Card>
    )
}

export default School