import { Row, Col, Select, Typography, Card, Button } from 'antd';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useRequest from '../../../hooks/use-request';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import Encoder from './Forms/Encoder';
import Institution from './Forms/Institution';
import School from './Forms/School';
import Student from './Forms/Student';
import Teacher from './Forms/Teacher';
import API_CALL from "../../../helpers/api";
const { Option } = Select;
const { Text } = Typography;

const Add = () => {
    const [school, setSchool] = useState(null);
    const [subject, setSubject] = useState(null);
    const [section, setSection] = useState(null);
    const [selectedForm, setSelectedForm] = useState(API_CALL === 'superadmin' ? 1 : 4);
    const [isLoading, setIsLoading] = useState(true);

    const onChange = (value) => {
        setSelectedForm(+value);
    };

    return (
        <Card title={<Link to={'/users'}><Button type="primary">{"< Back"}</Button></Link>}>
            <Row>
                <Text style={{ textAlign: 'center' }}>User Type: </Text>
                <Select
                    showSearch
                    placeholder="Select a user"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    style={{ width: 200 }}
                    value={selectedForm}
                >
                    {API_CALL === 'superadmin' && <Option value={1}>Institution</Option>}
                    {API_CALL === 'superadmin' && <Option value={2}>School</Option>}
                    {API_CALL === 'superadmin' && <Option value={3}>Encoder</Option>}
                    {(API_CALL === 'superadmin' || API_CALL === 'admin-insti' || API_CALL === 'admin-school') && <Option value={4}>Teacher</Option>}
                    {(API_CALL === 'superadmin' || API_CALL === 'admin-insti' || API_CALL === 'admin-school') && <Option value={5}>Student</Option>}
                </Select>
            </Row>
            <Col>
                {API_CALL === 'superadmin' && selectedForm === 1 && <Institution />}
                {API_CALL === 'superadmin' && selectedForm === 2 && <School />}
                {API_CALL === 'superadmin' && selectedForm === 3 && <Encoder />}
                {selectedForm === 4 && <Teacher />}
                {selectedForm === 5 && <Student />}
            </Col>
        </Card >
    )
}

export default Add