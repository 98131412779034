import React from 'react'
import Powtoon from './Forms/Powtoon/Powtoon';
import WhackAMole from './Forms/WhackAMole/WhackAMole';
import Quiz from './Forms/Quiz/QuizDirection';
import TrueFalseDirection from './Forms/TrueFalse/TrueFalseDirection';
import MatchingType from './Forms/MatchingType/MatchingType';
import WordSentenceCompletion from './Forms/WordSentenceCompletion/WordSentenceCompletion';
import FillInTheBlanks from './Forms/FillInTheBlanks/FillInTheBlanks';
import OpenTheBox from './Forms/OpenTheBox/OpenTheBox';
import SpinAWheel from './Forms/SpinAWheel/SpinAWheel';
import MultipleChoice from './Forms/MutipleChoice/MultipleChoiceDirection';
import MazeChase from './Forms/MazeChase/MazeChase';
import JumbledLetters from './Forms/JumbledLetters/JumbledLetters';
import Crossword from './Forms/Crossword/Crossword';
import PerformanceTask from './Forms/PerformanceTask/PerformanceTask';
import DragDrop from './Forms/DragDrop/DragDrop';

const ActivityForm = ({ module, onFormBack }) => {
    console.log(module.moduleType, "module.moduleType")
    return (
        module.moduleType === 'POWTOON' && (<Powtoon
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'WHACK A MOLE' && (<WhackAMole
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'QUIZ SHOW' && (<Quiz
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'TRUE OR FALSE' && (<TrueFalseDirection
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'MATCHING TYPE' && (<MatchingType
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'WORD SENTENCE COMPLETION' && (<WordSentenceCompletion
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'FILL IN THE BLANKS' && (<FillInTheBlanks
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'OPEN THE BOX' && (<OpenTheBox
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'SPIN A WHEEL' && (<SpinAWheel
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'MULTIPLE CHOICE' && (<MultipleChoice
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'MAZE CHASE' && (<MazeChase
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'JUMBLED LETTERS' && (<JumbledLetters
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'CROSSWORDS' && (<Crossword
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'PERFORMANCE TASK' && (<PerformanceTask
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />) ||
        module.moduleType === 'DRAG AND DROP' && (<DragDrop
            module={module.moduleType}
            activityId={module.activityId}
            type={module.module.activityType}
            subjectId={module.module.type.subjectId}
            quarter={module.module.type.quarter}
            topicId={module.module.type.topicId}
            onFormBack={onFormBack}
        />)
    )
}

export default ActivityForm