import React, { useState, useEffect, useContext } from "react";
// import Table from "../../hooks/use-datable";
import useRequest from "../../../hooks/use-request";
import moment from "moment";
import {
  Button,
  Tag,
  Dropdown,
  Menu,
  Space,
  Row,
  Col,
  Typography,
  Layout,
  Card,
  Divider,
  Drawer,
  Form,
  Input,
  Select,
  Popconfirm,
} from "antd";
import { Colors } from "../../../assets/Constants/Colors";
import "../../../assets/resources/style/user.css";
import {
  DownOutlined,
  UserOutlined,
  EditOutlined,
  FileExcelOutlined,
  SearchOutlined,
  UserSwitchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  ExportTableButton,
  SearchTableInput,
  Table,
} from "ant-table-extensions";
import Modal from "../../Layout/Modal/Modal";
import CustomSpinnerModal from "../../Layout/Modal/Spinner";
import { Link } from "react-router-dom";
import API_CALL from "../../../helpers/api";
import { AuthContext } from "../../../store/use-context";
import CustomFormModal from "../../Layout/Modal/CustomFormModal";
const { Option } = Select
const { Text } = Typography;

const User = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [searchDataSource, setSearchDataSource] = useState(users);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleChange, setIsModalVisibleChange] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [isModalClicked, setIsModalClicked] = useState({
    id: null,
    accountId: "",
  });
  const [message, setMessage] = useState(null);
  const authContext = useContext(AuthContext);

  const getTeacher = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/getTeachers`,
    },
    (data) => {
      let filteredUserType = data.map(
        (obj) => {
          return { ...obj, userType: "Teacher" }
        }
      );

      let arrangeStatus = filteredUserType.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: 0 }) ||
          (obj.status === 1 && { ...obj, status: 2 }) ||
          (obj.status === 2 && { ...obj, status: 1 })
      );

      let sortStatus = arrangeStatus.sort((a, b) => {
        return a.status - b.status;
      });

      let filteredStatus = sortStatus.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: "Active" }) ||
          (obj.status === 2 && { ...obj, status: "Deleted" }) ||
          (obj.status === 1 && { ...obj, status: "Deactivated" })
      );

      let filteredBirthdate = filteredStatus.map(
        (obj) =>
          obj.birthdate && {
            ...obj,
            birthdate: moment(obj.birthdate).format("YYYY-MM-DD"),
          }
      );

      let key = filteredBirthdate.map((obj, index) => {
        return { ...obj, key: index };
      });

      // setUsers(users => [...users, ...key])
      setUsers(key)
      // setSearchDataSource(search => [...search, ...key]);
      setSearchDataSource(key);
    },
    (err) => { }
  );

  const getStudent = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/getStudents`,
    },
    (data) => {
      let filteredUserType = data.map(
        (obj) => {
          return { ...obj, userType: "Teacher" }
        }
      );

      let arrangeStatus = filteredUserType.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: 0 }) ||
          (obj.status === 1 && { ...obj, status: 2 }) ||
          (obj.status === 2 && { ...obj, status: 1 })
      );

      let sortStatus = arrangeStatus.sort((a, b) => {
        return a.status - b.status;
      });

      let filteredStatus = sortStatus.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: "Active" }) ||
          (obj.status === 2 && { ...obj, status: "Deleted" }) ||
          (obj.status === 1 && { ...obj, status: "Deactivated" })
      );

      let filteredBirthdate = filteredStatus.map(
        (obj) =>
          obj.birthdate && {
            ...obj,
            birthdate: moment(obj.birthdate).format("YYYY-MM-DD"),
          }
      );

      let key = filteredBirthdate.map((obj, index) => {
        return { ...obj, key: index };
      });

      setUsers(users => [...users, ...key])
      setSearchDataSource(search => [...search, ...key]);
    },
    (err) => { }
  );

  const getUsers = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/getUsers`,
    },
    (data) => {
      let filteredUserType = data.map(
        (obj) =>
          (obj.userType === "0" && { ...obj, userType: "Super Admin" }) ||
          (obj.userType === "1" && {
            ...obj,
            userType: "Admin (Institution)",
          }) ||
          (obj.userType === "2" && { ...obj, userType: "Admin (School)" }) ||
          (obj.userType === "3" && { ...obj, userType: "Encoder" }) ||
          (obj.userType === "4" && { ...obj, userType: "Teacher" }) ||
          (obj.userType === "5" && { ...obj, userType: "Student" })
      );

      let arrangeStatus = filteredUserType.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: 0 }) ||
          (obj.status === 1 && { ...obj, status: 2 }) ||
          (obj.status === 2 && { ...obj, status: 1 })
      );

      let sortStatus = arrangeStatus.sort((a, b) => {
        return a.status - b.status;
      });

      let filteredStatus = sortStatus.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: "Active" }) ||
          (obj.status === 2 && { ...obj, status: "Deleted" }) ||
          (obj.status === 1 && { ...obj, status: "Deactivated" })
      );

      let filteredBirthdate = filteredStatus.map(
        (obj) =>
          obj.birthdate && {
            ...obj,
            birthdate: moment(obj.birthdate).format("YYYY-MM-DD"),
          }
      );

      let key = filteredBirthdate.map((obj, index) => {
        return { ...obj, key: index };
      });

      setUsers(key);
      setSearchDataSource(key);
    },
    (err) => { }
  );

  const getUser = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/${(authContext.userType === "0" && "getUser") ||
        (authContext.userType === "1" && "getTeacher") ||
        (authContext.userType === "2" && "getTeacher")}`,
    },
    (data) => {
      setUser(data);
      setVisibleDrawer(true);
    },
    (err) => { }
  );

  const activateRequest = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/activateUser`,
    },
    (data) => {
      getUsers.sendRequest();
      setMessage(data.message);
      setIsVisible(true);
      setIsModalLoading(false);
    },
    (err) => {
      setIsLoading(false);
      setIsVisible(true);
      setMessage(err.response.data);
    }
  );

  const deactivateRequest = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/${(authContext.userType === "0" && "deactivateUser") ||
        (authContext.userType === "1" && "removeTeacher") ||
        (authContext.userType === "2" && "removeTeacher")
        }`,
    },
    (data) => {
      authContext.userType === "0" ? getUsers.sendRequest() : getTeacher.sendRequest() && getStudent.sendRequest()
      setMessage(data.message);
      setIsVisible(true);
      setIsModalLoading(false);
    },
    (err) => {
      setIsLoading(false);
      setIsVisible(true);
      setMessage(err.response.data);
    }
  );

  const changePassword = useRequest(
    {
      method: "POST",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/changePassword`,
    },
    (data) => {
      setVisibleDrawer(false);
      setMessage(data.message);
      setIsLoading(false);
      setIsModalVisibleChange(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisibleChange(true);
      setMessage(err.response.data);
    }
  );

  const editProfile = useRequest(
    {
      method: "POST",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/editUser`,
    },
    (data) => {
      getUser.sendRequest(null, { accountId: user.accountId });
      setEditProfileModal(false);
      setEditUser(false);
      // setVisibleDrawer(false);
      setMessage(data.message);
      setIsLoading(false);
      setIsModalVisibleChange(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisibleChange(true);
      setMessage(err.response.data);
    }
  );

  const showModal = () => {
    setMessage(
      `Are you sure you want to ` + (isModalClicked.id === 2 && "activate") ||
      (isModalClicked.id === 3 && "deactivate") + ` this account ?`
    );
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsModalLoading(true);
    if (authContext.userType === "0") {
      isModalClicked.id === 2 &&
        activateRequest.sendRequest(null, {
          accountId: isModalClicked.accountId,
        });
      isModalClicked.id === 3 &&
        deactivateRequest.sendRequest(null, {
          accountId: isModalClicked.accountId,
        });
    } else {
      isModalClicked.id === 2 &&
        activateRequest.sendRequest(null, {
          teacherId: isModalClicked.accountId,
        });
      isModalClicked.id === 3 &&
        deactivateRequest.sendRequest(null, {
          teacherId: isModalClicked.accountId,
        });
    }
  };

  const handleModalOk = () => {
    setIsVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsVisible(false);
  };

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const onDrawerClose = () => {
    setVisibleDrawer(false);
    setEditUser(false);
  };

  useEffect(() => {
    {
      authContext.userType === "0" && getUsers.sendRequest();
    }
    {
      authContext.userType === "1" && getTeacher.sendRequest();
      authContext.userType === "1" && getStudent.sendRequest();
      authContext.userType === "2" && getTeacher.sendRequest();
      authContext.userType === "2" && getStudent.sendRequest();
    }
    setIsLoading(false);
  }, []);

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: '150px',
      render: (row) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: "View",
                  key: "0",
                  icon: <EyeOutlined />,
                  onClick: () => {
                    setUserType(row.userType);
                    console.log(row)
                    getUser.sendRequest(null, { accountId: row.accountId });
                  },
                },
                // {
                //   label: 'Edit',
                //   key: '1',
                //   icon: <EditOutlined />,
                // },
                (row.status === "Deactivated" && {
                  label: "Activate",
                  key: "2",
                  icon: <UserOutlined />,
                  onClick: () => {
                    showModal();
                    setIsModalClicked({ id: 2, accountId: row.accountId });
                  },
                }) ||
                (row.status === "Active" && {
                  label: "Deactivate",
                  key: "3",
                  icon: <UserOutlined />,
                  onClick: () => {
                    showModal();
                    setIsModalClicked({ id: 3, accountId: row.accountId });
                  },
                }),
                row.isDefaultPassword === 1 && {
                  label: "Change Password",
                  key: "4",
                  icon: <UserSwitchOutlined />,
                  onClick: () => {
                    changePassword(row.accountId);
                  },
                },
              ]}
            />
          }
          trigger={["click"]}
        >
          <Button type="primary">
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: '100px',
      render: (tags) => (
        <span>
          {(tags === "Active" && (
            <Tag color={Colors.blue} key={tags}>
              {tags.toUpperCase()}
            </Tag>
          )) ||
            (tags === "Deleted" && (
              <Tag color={Colors.grey} key={tags}>
                {tags.toUpperCase()}
              </Tag>
            )) ||
            (tags === "Deactivated" && (
              <Tag color={Colors.grey} key={tags}>
                {tags.toUpperCase()}
              </Tag>
            ))}
        </span>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      width: '150px',
    },
    {
      title: "Name",
      dataIndex: "firstName",
      width: '150px',
    },
    {
      title: "Middle Name",
      dataIndex: "middleName",
      width: '150px',
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: '150px',
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      width: '150px',
    },
    {
      title: "Birthdate",
      dataIndex: "birthdate",
      width: '150px',
    },
  ];

  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {content}
    </div>
  );

  const DescriptionItemChange = ({ title, children }) => (
    <div className="site-description-item-profile-wrapper">
      <p className="site-description-item-profile-p-label">{title}:</p>
      {children}
    </div>
  );

  const onFinish = (values) => {
    console.log(values)
  }

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    setIsLoading(true);
    changePassword.sendRequest(
      { updatedPassword: values.password },
      { accountId: accountId }
    );
    setChangePasswordModal(false);
  };

  const onEditProfile = (values) => {
    console.log("Received values of form: ", values);
    editProfile.sendRequest(
      {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        mobileNumber: values.prefix + values.mobileNumber
      },
      { accountId: accountId }
    );
    setChangePasswordModal(false);
  }

  const onChangeUsername = (e) => {
    console.log(e);
  }

  const prefixSelector = (
    <Form.Item
      name="prefix"
      noStyle
      initialValue={"63"}
    >
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="63">+63</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Card
      title="Users"
      extra={
        <Link to={"/users/add"}>
          <Button type="primary">Add</Button>
        </Link>
      }
    >
      <Row justify="space-between">
        <Col span={7}>
          <ExportTableButton
            dataSource={users}
            columns={columns}
            btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
            showColumnPicker
          >
            Export to CSV
          </ExportTableButton>
        </Col>
        <Col span={7} offset={8}>
          <SearchTableInput
            columns={columns}
            dataSource={users}
            setDataSource={setSearchDataSource}
            inputProps={{
              placeholder: "Search this table...",
              prefix: <SearchOutlined />,
            }}
          />
        </Col>
      </Row>
      <Table
        loading={getUsers.isLoading}
        dataSource={searchDataSource}
        columns={columns}
        rowKey="accountId"
        pagination={{
          paginationSize: 10,
        }}
        scroll={{
          y: 400,
        }}
      />
      {user && (
        <Form
          name="registration-form"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          initialValues={{
            firstName: user?.firstName,
            middleName: user?.middleName,
            lastName: user?.lastName,
            email: user?.email,
            mobileNumber: user?.mobileNumber?.substring(2, 12),
          }}
          requiredMark={true}
        >
          <Drawer
            width={640}
            title={"User Profile - " + userType}
            placement="right"
            closable={false}
            onClose={onDrawerClose}
            visible={visibleDrawer}
            extra={
              !editUser && (
                <Button type="primary"
                  onClick={() => {
                    setAccountId(user.accountId);
                    setEditUser(true);
                  }}
                >
                  Edit
                </Button>
              ) ||
              editUser && (
                <Space>
                  <Button
                    onClick={() => {
                      setEditUser(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Popconfirm
                    placement="bottomRight"
                    title={"Do you want to save this info?"}
                    onConfirm={() => {
                      const values = form.getFieldsValue();
                      console.log(values);
                      onEditProfile(values)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" htmlType="submit">Save</Button>
                  </Popconfirm>
                </Space>
              )
            }
          >
            <p className="site-description-item-profile-p">Personal</p>
            {!editUser && (
              <>
                <Row>
                  <Col span={8}>
                    <DescriptionItem
                      title="First Name"
                      content={user.firstName}
                    />
                  </Col>
                  <Col span={8}>
                    <DescriptionItem
                      title="Middle Name"
                      content={user.middleName}
                    />
                  </Col>
                  <Col span={8}>
                    <DescriptionItem
                      title="Last Name"
                      content={user.lastName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="Email" content={user.email} />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title="Mobile Number"
                      content={user.mobileNumber}
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="Birthdate" content={user.birthdate} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItemChange title="Username">
                      <Text>
                        {user.username}
                      </Text>
                    </DescriptionItemChange>
                  </Col>
                  {user.isDefaultPassword === 1 && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Default Password"
                        content={
                          <Button
                            type="primary"
                            onClick={() => {
                              setAccountId(user.accountId);
                              setChangePasswordModal(true);
                            }}
                          >
                            Change Password
                          </Button>
                        }
                      />
                    </Col>
                  )}
                </Row>
              </>)}
            {editUser && (
              <>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Middle Name"
                      name="middleName"
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "First name is required",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Mobile Number"
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Phone is required",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        style={{
                          width: '100%',
                        }}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="Birthdate" content={user.birthdate} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItemChange title="Username">
                      <Text>
                        {user.username}
                      </Text>
                    </DescriptionItemChange>
                  </Col>
                  {user.isDefaultPassword === 1 && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Default Password"
                        content={
                          <Button
                            type="primary"
                            onClick={() => {
                              setAccountId(user.accountId);
                              setChangePasswordModal(true);
                            }}
                          >
                            Change Password
                          </Button>
                        }
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Drawer>
        </Form>
      )
      }
      <CustomFormModal
        title="Change Password"
        visible={changePasswordModal}
        onCreate={onCreate}
        onCancel={() => {
          setChangePasswordModal(false);
        }}
      >
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </CustomFormModal>
      {
        user && <CustomFormModal
          title="Edit Profile"
          visible={editProfileModal}
          onCreate={onEditProfile}
          onCancel={() => {
            setEditProfileModal(false);
          }}
        >
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input first name!",
              },
            ]}
          >
            <Input
              initialValues={user?.firstName}
            />
          </Form.Item>
          <Form.Item
            name="middleName"
            label="Middle Name"
          >
            <Input
              initialValues={user?.middleName}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input last name!",
              },
            ]}
          >
            <Input
              initialValues={user?.lastName}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
          >
            <Input
              initialValues={user?.email}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Mobile Number"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Please input your phone number!',
              },
            ]}
            style={{ margin: 5 }}
          >
            <Input
              initialValues={user?.mobileNumber?.slice(2, 13)}
              addonBefore={
                <Form.Item name="prefix" noStyle>
                  <Select
                    style={{
                      width: 70,
                    }}
                    defaultValue="63"
                  >
                    <Option value="63">+63</Option>
                  </Select>
                </Form.Item>
              }
              style={{
                width: '100%',
              }}
              maxLength={10}
            />
          </Form.Item>
        </CustomFormModal>
      }

      <Modal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={
          (isModalClicked.id === 2 && "Activate") ||
          (isModalClicked.id === 3 && "Deactivate")
        }
      >
        <Text>Are you sure you want to {(isModalClicked.id === 2 && "activate") ||
          (isModalClicked.id === 3 && "deactivate")} this account ?</Text>
      </Modal>
      <Modal
        isModalVisible={isVisible}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
        title={
          (isModalClicked.id === 2 && "Activate") ||
          (isModalClicked.id === 3 && "Deactivate")
        }
      >
        <Text>{message}</Text>
      </Modal>
      <Modal
        isModalVisible={isModalVisibleChange}
        handleOk={() => setIsModalVisibleChange(false)}
        handleCancel={() => setIsModalVisibleChange(false)}
        title={
          (isModalClicked.id === 2 && "Activate") ||
          (isModalClicked.id === 3 && "Deactivate")
        }
      >
        <Text>{message}</Text>
      </Modal>
      <CustomSpinnerModal isLoading={isModalLoading}></CustomSpinnerModal>
      {getUser.isLoading || editProfile.isLoading && <CustomSpinnerModal isLoading={true}></CustomSpinnerModal>}
    </Card >
  );
};

export default User;