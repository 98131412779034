import React, { useContext, useEffect, useState, useRef } from "react";

import {
  Card,
  Col,
  Row,
  Typography,
  Progress,
  Upload,
  message,
  Button,
  Timeline,
  Radio,
  List,
} from "antd";
import {
  ToTopOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
} from "@ant-design/icons";
import ReactApexChart from "react-apexcharts";
import Paragraph from "antd/lib/typography/Paragraph";
import useRequest from "../../hooks/use-request";
import { AuthContext } from "../../store/use-context";
import CustomSpinnerModal from "../Layout/Modal/Spinner";
import HighchartsReact from 'highcharts-react-official';
import moment from "moment";
import Highcharts from "highcharts";

// Import our demo components
import Chart from "../Layout/Chart/Chart";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const dataSample = [
  {
    series: [7, 3],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Male", "Female"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  },
];

const dataSampleTeacher = [
  {
    series: [12, 6],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Male", "Female"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  },
];

const data = [
  {
    id: 1,
    title: "DGSI",
    description: "Don Santiago",
    amount: "Taytay, Rizal",
  },
  {
    id: 2,
    title: "School 2",
    description: "School Test",
    amount: "School 3, Rizal",
  },
  {
    id: 3,
    title: "School Bukol",
    description: "School Bukol",
    amount: "School Bukol",
  },
];

const profile = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
      fill="#fff"
    ></path>
    <path
      d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
      fill="#fff"
    ></path>
    <path
      d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
      fill="#fff"
    ></path>
    <path
      d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
      fill="#fff"
    ></path>
  </svg>,
];

const getAllStudent = (arr, key) => {
  let arr2 = [];

  arr.forEach((x) => {
    if (x.status === "Active") {
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["y"]++;
          }
        });
      } else {
        let a = {};
        a[key] = x[key];
        a["y"] = 1;
        arr2.push(a);
      }
    }
  });

  return arr2;
};

const getAllGenderStudent = (arr, key) => {
  let arr2 = [];

  arr.forEach((x) => {
    if (x.status === "Active" && x.gender === "0" && x.userType === "Student") {
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["y"]++;
          }
        });
      } else {
        let a = {};
        a[key] = x[key];
        a["y"] = 1;
        arr2.push(a);
      }
    }
  });

  return arr2;
};

const getAllStudentDeactivated = (arr, key) => {
  let arr2 = [];

  arr.forEach((x) => {
    if (x.status === "Deactivated") {
      if (
        arr2.some((val) => {
          return val[key] === x[key];
        })
      ) {
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["y"]++;
          }
        });
      } else {
        let a = {};
        a[key] = x[key];
        a["y"] = 1;
        arr2.push(a);
      }
    }
  });

  return arr2;
};

const dataRecharts = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

const chartOptions = {
  chart: {
    events: {
      load() {
        setTimeout(this.reflow.bind(this), 0);
      },
    },
  },
  title: {
    text: "Gender - Student"
  },
  credits: {
    enabled: false
  },
  series: [
    {
      data: [],
      keys: ["count", "name"],
      type: "pie"
    }
  ]
};


const Dashboard = () => {
  const { Title, Text } = Typography;
  const authContext = useContext(AuthContext);
  const [teacher, setTeacher] = useState(null);
  const [student, setStudent] = useState(null);
  const [countDashboard, setCountDashboard] = useState(null);
  //   const getTeacher = useRequest(
  //     {
  //       method: "GET",
  //       endPoint: `api/${
  //         (authContext.userType === "0" && "superadmin") ||
  //         (authContext.userType === "1" && "admin-insti") ||
  //         (authContext.userType === "2" && "admin-school") ||
  //         (authContext.userType === "3" && "encoder")
  //       }/users/getTeachers`,
  //     },
  //     (data) => {
  //       setTeacher(data);
  //     },
  //     (err) => {}
  //   );

  const getUser = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && "superadmin") ||
        (authContext.userType === "1" && "admin-insti") ||
        (authContext.userType === "2" && "admin-school") ||
        (authContext.userType === "3" && "encoder")
        }/users/getUsers`,
    },
    (data) => {
      let filteredUserType = data.map(
        (obj) =>
          (obj.userType === "0" && { ...obj, userType: "Super Admin" }) ||
          (obj.userType === "1" && {
            ...obj,
            userType: "Admin (Institution)",
          }) ||
          (obj.userType === "2" && { ...obj, userType: "Admin (School)" }) ||
          (obj.userType === "3" && { ...obj, userType: "Encoder" }) ||
          (obj.userType === "4" && { ...obj, userType: "Teacher" }) ||
          (obj.userType === "5" && { ...obj, userType: "Student" })
      );

      let arrangeStatus = filteredUserType.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: 0 }) ||
          (obj.status === 1 && { ...obj, status: 2 }) ||
          (obj.status === 2 && { ...obj, status: 1 })
      );

      let sortStatus = arrangeStatus.sort((a, b) => {
        return a.status - b.status;
      });

      let filteredStatus = sortStatus.map(
        (obj) =>
          (obj.status === 0 && { ...obj, status: "Active" }) ||
          (obj.status === 2 && { ...obj, status: "Deleted" }) ||
          (obj.status === 1 && { ...obj, status: "Deactivated" })
      );

      let filteredBirthdate = filteredStatus.map(
        (obj) =>
          obj.birthdate && {
            ...obj,
            birthdate: moment(obj.birthdate).format("YYYY-MM-DD"),
          }
      );

      let key = filteredBirthdate.map((obj, index) => {
        return { ...obj, key: index };
      });

      console.log(getAllStudent(key, "userType"));

      setStudent(key);
      console.log(getAllGenderStudent(key, "gender"))
      chartOptions.series[0].data.push([getAllGenderStudent(key, "gender")])
      setCountDashboard([
        {
          today: "# of Registered Active Students",
          title: getAllStudent(key, "userType")[5].y,
          icon: profile,
        },
        {
          today: "# of Registered Active Teachers",
          title: getAllStudent(key, "userType")[4].y,
          icon: profile,
        },
        {
          today: "# of Registered Active Encoder",
          title: getAllStudent(key, "userType")[2].y,
          icon: profile,
        },
      ]);
    },
    (err) => { }
  );



  useEffect(() => {
    // getTeacher.sendRequest();
    getUser.sendRequest();
  }, []);

  return (
    <>
      {!countDashboard && !student && <CustomSpinnerModal isLoading={true} />}
      {countDashboard && student && (
        <>
          <div className="layout-content">
            <Row gutter={[24, 0]}>
              <Col xs={24} md={16}>
                <Row gutter={[24, 0]}>
                  {countDashboard.map((c, index) => (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={8}
                      xl={8}
                      className="mb-24"
                    >
                      <Card bordered={false} className="criclebox">
                        <div className="number">
                          <Row align="middle" gutter={[24, 0]}>
                            <Col xs={18}>
                              <span>{c.today}</span>
                              <Title level={3}>{c.title} </Title>
                            </Col>
                            <Col xs={6}>
                              <div className="icon-box">{c.icon}</div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ))}
                  <Col xs={12} md={12} className="mb-24">
                    <Row gutter={[24, 0]}>
                      <Col span={24} md={24} xs={24}>
                        <Chart options={chartOptions} highcharts={Highcharts} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} className="mb-24">
                    <Card
                      className="header-solid h-full ant-card-p-0"
                      title={
                        <>
                          <Row
                            gutter={[24, 0]}
                            className="ant-row-flex ant-row-flex-middle"
                          >
                            <Col xs={24} md={12}>
                              <h6 className="font-semibold m-0">
                                Gender - Teacher
                              </h6>
                            </Col>
                          </Row>
                        </>
                      }
                    >
                      <Row gutter={[24, 0]}>
                        <Col span={24} md={24} xs={24}>
                          <div id="chart">
                            <ReactApexChart
                              options={dataSampleTeacher[0].options}
                              series={dataSampleTeacher[0].series}
                              type="pie"
                              width={380}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col span={24} md={8} className="mb-24">
                <Card
                  bordered={false}
                  className="header-solid h-full ant-invoice-card"
                  title={[<h6 className="font-semibold m-0">Schools</h6>]}
                //   extra={[
                //     <Button type="primary">
                //       <span>VIEW ALL</span>
                //     </Button>,
                //   ]}
                >
                  <List
                    itemLayout="horizontal"
                    className="invoice-list"
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item
                        key={item.id}
                      >
                        <List.Item.Meta
                          key={item.id}
                          title={item.title}
                          description={item.description}
                        />
                        <div className="amount">{item.amount}</div>
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
            {/* <Row gutter={[24, 0]}>
              <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
                <Card bordered={false} className="criclebox h-full">
                  <Row gutter>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={12}
                      xl={14}
                      className="mobile-24"
                    >
                      <d level={5}>Muse Dashboard for Ant Design</Title>
                          <Paragraph className="lastweek mb-36">
                            From colors, cards, typography to complex elements,
                            you will find the full documentation.
                          </Paragraph>
                        </div>
                        <div className="card-footer">
                          <a className="icon-move-right" href="#pablo">
                            Read More
                            {<RightOutlined />}
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      sm={24}
                      lg={12}
                      xl={10}
                      className="col-img"
                    >
                      <div className="ant-cret text-right">
                        <p>AAAA</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
                <Card bordered={false} className="criclebox card-info-2 h-full">
                  <div className="gradent h-full col-content">
                    <div className="card-content">
                      <Title level={5}>Work with the best</Title>
                      <p>
                        Wealth creation is an evolutionarily recent positive-sum
                        game. It is all about who take the opportunity first.
                      </p>
                    </div>
                    <div className="card-footer">
                      <a className="icon-move-right" href="#pablo">
                        Read More
                        <RightOutlined />
                      </a>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row> */}
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
