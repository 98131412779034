import { useContext, useState } from "react";
import { Button, Form, Input, Col, Row, Card, Typography, Space } from "antd";
import "../../assets/resources/style/login.css";
import useRequest from "../../hooks/use-request";
import { AuthContext } from "../../store/use-context";
import { useNavigate } from "react-router-dom";
import Modal from "../Layout/Modal/Modal";
import logo from "../../assets/resources/Images/schoolbook.png";
import CustomSpinnerModal from "../Layout/Modal/Spinner";

const { Text } = Typography;

const Login = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const loginRequest = useRequest(
    {
      method: "POST",
      endPoint: "api/auth/login",
    },
    (data) => {
      authContext.authenticate({
        token: data.token,
        currentUser: [data.data.firstName, data.data.lastName].join(" "),
        userType: data.data.userType,
        userData: data.data,
      });
      if (data.data.userType === "3") {
        navigate("/activity")
      } else {
        navigate("/users");
      }
    },
    (err) => {
      console.log(err);
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data);
    }
  );

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const authContext = useContext(AuthContext);

  const onFinish = (values) => {
    setIsLoading(true);
    loginRequest.sendRequest(
      { username: values.username, password: values.password },
      null
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <section id="home" className="home">
      <div className="home__container">
        <div className="site-card-wrapper">
          <div className="site-card">
            <Row justify="center" align="middle" className="card-row">
              <Col span={16} xs={20} md={24} lg={12} align="middle">
                <Card
                  style={{
                    width: "500px",
                    borderRadius: 10,
                    alignContent: "center",
                    borderColor: "#fff",
                    border: '1px solid #cecece'
                  }}
                  bordered
                  cover={
                    <img
                      className="cardLogo"
                      alt="bgLogo"
                      src={logo}
                      style={{
                        marginTop: 40,
                        width: "150px",
                        marginBottom: 40,
                      }}
                    />
                  }
                  className={'card-container'}
                >
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    requiredMark={false}
                  >
                    <Row>
                      <Col span={24} xs={24} md={24} lg={24} offset={4} className="card-inputs">
                        <Form.Item
                          label="Username"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={24} xs={24} md={24} lg={24} offset={4} className="card-inputs">
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                        {/* <a>Forgot Password?</a> */}
                      </Col>
                    </Row>
                    <Form.Item
                      wrapperCol={{
                        offset: 6,
                        span: 12,
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleOk}
        title={message?.message === "Added successfully" ? "Success" : "Error"}
      >
        <Text>{message?.message}</Text>
      </Modal>
      <CustomSpinnerModal isLoading={isLoading} />
    </section>
    // <div className="site-card-wrapper">
    //   <Row justify="center" align="middle">
    //     <Col span={16} justify="center" align="middle">
    //       <Card
    //         style={{
    //           width: "500px",
    //         }}
    //         cover={<img alt="bgSchool" src={logo} />}
    //       >
    //         <Form
    //           name="basic"
    //           labelCol={{
    //             span: 8,
    //           }}
    //           wrapperCol={{
    //             span: 16,
    //           }}
    //           initialValues={{
    //             remember: true,
    //           }}
    //           onFinish={onFinish}
    //           onFinishFailed={onFinishFailed}
    //           autoComplete="off"
    //         >
    //           <Form.Item
    //             label="Username"
    //             name="username"
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Please input your username!",
    //               },
    //             ]}
    //           >
    //             <Input />
    //           </Form.Item>

    //           <Form.Item
    //             label="Password"
    //             name="password"
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Please input your password!",
    //               },
    //             ]}
    //           >
    //             <Input.Password />
    //           </Form.Item>
    //           <Form.Item
    //             wrapperCol={{
    //               offset: 8,
    //               span: 16,
    //             }}
    //           >
    //             <Button type="primary" htmlType="submit">
    //               Submit
    //             </Button>
    //           </Form.Item>
    //         </Form>
    //       </Card>
    //     </Col>
    //   </Row>
    //   <Modal
    //     isModalVisible={isModalVisible}
    //     handleOk={handleOk}
    //     title={message?.message === "Added successfully" ? "Success" : "Error"}
    //   >
    //     <Text>{message?.message}</Text>
    //   </Modal>
    //   <CustomSpinnerModal isLoading={isLoading} />
    // </div>
  );
};

export default Login;
