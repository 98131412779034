import { Row, Col, Form, Button, Input, Checkbox, message, Upload, Typography, Space, message as ANTMESSAGE } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../../../../assets/resources/style/activityForm.css'
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import Modal from '../../../../Layout/Modal/Modal';
import API_CALL from "../../../../../helpers/api";
import { AuthContext } from '../../../../../store/use-context';
const { TextArea } = Input;
const { Dragger } = Upload;
const { Text } = Typography

const PowtoonEdit = ({ moduleId, module, onFormBack }) => {
    const authContext = useContext(AuthContext)
    const types = ["video/mp4"];
    const [form] = Form.useForm();
    const [uploadedVideo, setUploadedVideo] = useState();
    const [uploadedImage, setUploadedImage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const powtoon = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/editModulePowtoon`,
            headers: { "Content-Type": "multipart/form-data" }
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            console.log(err)
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const propsImage = {
        name: '.jpg',
        accept: [".jpg", ".pdf"],
        multiple: false,
        beforeUpload: (file) => {
            if (types.includes(file.type)) {
                setMessage({ message: 'File is MP4' })
                setIsModalVisible(true);
            }
            return false
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
                setUploadedImage(info.file);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const props = {
        name: '.mp4',
        accept: ".mp4",
        multiple: false,
        beforeUpload: (file) => {
            if (!types.includes(file.type)) {
                setMessage({ message: 'File is not MP4' })
                setIsModalVisible(true);
            }
            return false
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
                setUploadedVideo(info.file);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const normImage = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        if (types.includes(e.fileList[0].type)) {
            e.fileList.shift();
            ANTMESSAGE.warning("FILE IS NOT IMAGE")
        }
        return e?.fileList;
    };

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        if (!types.includes(e.fileList[0].type)) {
            e.fileList.shift();
        }
        return e?.fileList;
    };

    const onFinish = (values) => {
        let formData = new FormData();
        formData.append("vid", uploadedVideo);
        formData.append("image", uploadedImage);
        formData.append("description", values.instruction);
        powtoon.sendRequest(formData, { moduleId });
        setIsLoading(true);
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <br></br>
            <Form
                name="dynamic_form_item"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    choices: ["", ""]
                }
                }
            >
                <Row>
                    <Space>
                        <Col xs={24} md={24} lg={24}>
                            <p>Image/PDF: </p>
                            <Form.Item>
                                <Form.Item
                                    name="image"
                                    label="Upload Image/PDF: "
                                    valuePropName="fileListImage"
                                    getValueFromEvent={normImage}
                                    noStyle
                                    multiple="false"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Please attach a video."
                                //     },
                                // ]}
                                >
                                    <Dragger
                                        {...propsImage}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                            band files
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={24}>
                            <p>Video: </p>
                            <Form.Item>
                                <Form.Item
                                    name="upload"
                                    label="Upload Video: "
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    noStyle
                                    multiple="false"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Please attach a video."
                                //     },
                                // ]}
                                >
                                    <Dragger
                                        {...props}
                                        maxCount={1}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                            band files
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                    </Space>
                </Row>
                <Form.Item
                    name="instruction"
                    label="Instruction"
                    rules={[
                        {
                            required: true,
                            message: "Please input question",
                        },
                    ]}
                >
                    <TextArea placeholder="Instruction" rows={4} style={{
                        width: '100%',
                    }} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form >
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleOk}
                title={message?.message}
            >
                <Text>{message?.message}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading} />
        </>
    )
}

export default PowtoonEdit