import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Tag,
  Dropdown,
  Menu,
  Space,
  Row,
  Col,
  Card,
  Drawer,
  Image,
  Typography,
  Select,
  Input,
  Popconfirm,
  Form,
  Radio,
  Upload,
  TimePicker,
  message as Message,
  InputNumber
} from "antd";
import { Colors } from "../../../assets/Constants/Colors";
import '../../../assets/resources/style/user.css';
import {
  DownOutlined,
  FileExcelOutlined,
  SearchOutlined,
  FundViewOutlined,
  CloseSquareOutlined,
  CheckSquareTwoTone,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  DeleteOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import useRequest from "../../../hooks/use-request";
import { AuthContext } from "../../../store/use-context";
import PowtoonView from "./Forms/Powtoon/PowtoonView";
import ViewActivity from "./ViewActivity";
import CustomSpinnerModal from "../../Layout/Modal/Spinner";
import CustomModal from "../../Layout/Modal/Modal";
import FIBQuestion from "./Forms/FillInTheBlanks/FIBQuestion";
import moment from "moment";
import { Link } from "react-router-dom";
import ActivityAdd from "./ActivityAdd";
import ActivityEdit from "./ActivityEdit";
import convertMiliseconds from '../../../helpers/convertMiliseconds'
import convertMilisecondsTime from '../../../helpers/convertMillisecondsTime'
import PerformanceTaskView from "./Forms/PerformanceTask/PerformanceTaskView";
const { Text } = Typography
const { Option } = Select;
const { TextArea } = Input;

function Activity() {
  const authContext = useContext(AuthContext);
  const [activity, setActivity] = useState([]);
  const [subject, setSubject] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [topic, setTopic] = useState([]);
  const [question, setQuestion] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [searchDataSource, setSearchDataSource] = useState(activity);
  const [viewActivity, setViewActivity] = useState(null);
  const [searchViewDataSource, setSearchViewDataSource] = useState(viewActivity);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [message, setMessage] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [parentMenuTitle, setParentMenuTitle] = useState(null);
  const [editDrawer, setEditDrawer] = useState(false);
  const [childrenVisible, setChildrenVisible] = useState(false);
  const [activityType, setActivityType] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = useState(false)
  const [deleteModuleQuestionModalVisible, setDeleteModuleQuestionModalVisible] = useState(false)
  const [choiceId, setChoiceId] = useState(null)
  const [questionId, setQuestionId] = useState(null);
  const [editingKeyQuestion, setEditingKeyQuestion] = useState('');
  const [addingKeyChoices, setAddingKeyChoices] = useState('');
  const [editingKeyChoice, setEditingKeyChoice] = useState('');
  // const [choices, setChoices] = useState(false);
  const [editingKey, setEditingKey] = useState({ editable: null, keyId: null, name: null });
  const [editingKeyChoices, setEditingKeyChoices] = useState({ editable: null, choiceId: null, description: null });
  const [choices, setChoices] = useState(false);
  const [value, setValue] = useState(1);
  const [addChoiceDD, setAddChoiceDD] = useState({ adding: null, keyId: null });
  const [fileList, setFileList] = useState([
    {
      uid: null,
      name: null,
      status: null,
      url: null,
    },
  ]);
  const [form] = Form.useForm();
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
  const [selectedQuarterFilter, setSelectedQuarterFilter] = useState(null);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState(null);

  const getActivity = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/getModules`
    },
    (data) => {
      const changeName = data.sort((a, b) => {
        return a.status - b.status;
      }).map((e) => {
        return ((e.activityType === 'FITB' && { ...e, activityType: "Fill in the Blanks" }) ||
          (e.activityType === 'WAM' && { ...e, activityType: "Whack A Mole" }) ||
          (e.activityType === 'MC' && { ...e, activityType: "Multiple Choice" }) ||
          (e.activityType === 'WSC' && { ...e, activityType: "Word Sentence Completion" }) ||
          (e.activityType === 'TF' && { ...e, activityType: "True or False" }) ||
          (e.activityType === 'OTB' && { ...e, activityType: "Open The Box" }) ||
          (e.activityType === 'MT' && { ...e, activityType: "Matching Type" }) ||
          (e.activityType === 'POWTOON' && { ...e, activityType: "POWTOON" }) ||
          (e.activityType === 'SAW' && { ...e, activityType: "Spin A Wheel" }) ||
          (e.activityType === 'Maze' && { ...e, activityType: "Maze Chase" }) ||
          (e.activityType === 'QuizShow' && { ...e, activityType: "QuizShow" }) ||
          (e.activityType === 'JL' && { ...e, activityType: "Jumbled Letters" }) ||
          (e.activityType === 'PT' && { ...e, activityType: "Performance Task" }) ||
          (e.activityType === 'DD' && { ...e, activityType: "Drag and Drop" }) ||
          (e.activityType === 'CW' && { ...e, activityType: "Crosswords" })
        )
      }).filter((e) => {
        return e.status === 0;
      })
      setSearchDataSource(changeName);
      setActivity(changeName);
      setIsLoadingActivity(false);
    },
    (err) => {
      setIsLoadingActivity(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const getSubject = useRequest(
    {
      method: "GET",
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjectsPerGrade`
    },
    (data) => {
      const filteredData = data.filter((subject) => {
        return subject.status === 0
      })
      setSubject(filteredData)
      setIsLoadingActivity(false);
    },
    (err) => {
      setIsLoadingActivity(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const getTopic = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getTopic`
    },
    (data) => {
      let filteredTopic = data.filter((e) => {
        return e.status != 1
      })
      setTopic(filteredTopic);
      setIsLoadingActivity(false);
    }
  )

  const getModulesPerTopic = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/getModulesPerTopic`
    },
    (data) => {
      const changeName = data.sort((a, b) => {
        return a.status - b.status;
      }).map((e) => {
        return ((e.activityType === 'FITB' && { ...e, activityType: "Fill in the Blanks" }) ||
          (e.activityType === 'WAM' && { ...e, activityType: "Whack A Mole" }) ||
          (e.activityType === 'MC' && { ...e, activityType: "Multiple Choice" }) ||
          (e.activityType === 'WSC' && { ...e, activityType: "Word Sentence Completion" }) ||
          (e.activityType === 'TF' && { ...e, activityType: "True or False" }) ||
          (e.activityType === 'OTB' && { ...e, activityType: "Open The Box" }) ||
          (e.activityType === 'MT' && { ...e, activityType: "Matching Type" }) ||
          (e.activityType === 'POWTOON' && { ...e, activityType: "POWTOON" }) ||
          (e.activityType === 'SAW' && { ...e, activityType: "Spin A Wheel" }) ||
          (e.activityType === 'Maze' && { ...e, activityType: "Maze Chase" }) ||
          (e.activityType === 'JL' && { ...e, activityType: "Jumbled Letters" }) ||
          (e.activityType === 'QuizShow' && { ...e, activityType: "QuizShow" }) ||
          (e.activityType === 'PT' && { ...e, activityType: "Performance Task" }) ||
          (e.activityType === 'DD' && { ...e, activityType: "Drag and Drop" }) ||
          (e.activityType === 'CW' && { ...e, activityType: "Crosswords" })
        )
      }).filter((e) => {
        return e.status === 0;
      })
      setSearchDataSource(changeName);
      setActivity(changeName);
      setIsLoadingActivity(false);
    },
    (err) => {
      setIsLoadingActivity(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const viewActivityModule = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/getModule`
    },
    (data) => {
      setSearchViewDataSource(data);
      setViewActivity(data);
      setIsLoading(false);
      setVisible(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const editRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'POWTOON' && 'editModulePowtoon') ||
        (activityType === 'Fill in the Blanks' && 'editModuleFITB') ||
        (activityType === 'Crosswords' && 'editModuleCW') ||
        (activityType === 'Jumbled Letters' && 'editModuleJL') ||
        (activityType === 'Word Sentence Completion' && 'editModuleWSC') ||
        (activityType === 'QuizShow' && 'editModuleQuizShow') ||
        (activityType === 'Whack A Mole' && 'editModuleWAM') ||
        (activityType === 'Spin A Wheel' && 'editModuleSAW') ||
        (activityType === 'Matching Type' && 'editModuleMT') ||
        (activityType === 'Multiple Choice' && 'editModuleMC') ||
        (activityType === 'True or False' && 'editModuleTF') ||
        (activityType === 'Maze Chase' && 'editModuleMaze') ||
        (activityType === 'Performance Task' && 'editModulePT') ||
        (activityType === 'Drag and Drop' && 'editModuleDD') ||
        (activityType === 'Open The Box' && 'editModuleOTB')
        }`,
    },
    (data) => {
      setEditMessage(data);
      setEditModalVisible(true);
      setIsEditLoading(false);
    },
    (err) => {
      setIsEditLoading(false);
      setEditModalVisible(true);
      setEditMessage(err.response.data)
    }
  )

  const addChoicesRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'Word Sentence Completion' && 'addModuleWSCChoices') ||
        (activityType === 'QuizShow' && 'addModuleQuizShowChoices') ||
        (activityType === 'Whack A Mole' && 'addModuleWAMChoice') ||
        (activityType === 'Spin A Wheel' && 'addModuleSAWChoices') ||
        (activityType === 'Matching Type' && 'addModuleMTChoices') ||
        (activityType === 'Multiple Choice' && 'addModuleMCChoices') ||
        (activityType === 'Open The Box' && 'addModuleOTBChoices') ||
        (activityType === 'Drag and Drop' && 'addModuleDDChoices') ||
        (activityType === 'Maze Chase' && 'addModuleMazeChoices')
        }`,
    },
    (data) => {
      viewActivityModule.sendRequest(null, { moduleId })
      setEditingKeyChoice('');
      setChoices(null);
      setEditingKeyChoices({ editable: null, choiceId: null, description: null });
      setAddChoiceDD({ adding: null, keyId: null });
      form.setFieldsValue({ description: '' })
      setEditMessage(data);
      setEditModalVisible(true);
      setIsEditLoading(false);
    },
    (err) => {
      setIsEditLoading(false);
      setEditModalVisible(true);
      setEditMessage(err.response.data)
    }
  )

  const editChoicesRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'POWTOON' && 'editModulePowtoon') ||
        (activityType === 'Word Sentence Completion' && 'editModuleWSCChoices') ||
        (activityType === 'QuizShow' && 'editModuleQuizShowChoices') ||
        (activityType === 'Whack A Mole' && 'editModuleWAMChoice') ||
        (activityType === 'Spin A Wheel' && 'editModuleSAWChoices') ||
        (activityType === 'Matching Type' && 'editModuleMTChoices') ||
        (activityType === 'Multiple Choice' && 'editModuleMCChoices') ||
        (activityType === 'Open The Box' && 'editModuleOTBChoices') ||
        (activityType === 'Maze Chase' && 'editModuleMazeChoices')
        }`,
    },
    (data) => {
      viewActivityModule.sendRequest(null, { moduleId })
      setEditingKeyChoice('');
      setChoices(null);
      setEditingKeyChoices({ editable: null, choiceId: null, description: null });
      form.setFieldsValue({ description: '' })
      setEditMessage(data);
      setEditModalVisible(true);
      setIsEditLoading(false);
    },
    (err) => {
      setIsEditLoading(false);
      setEditModalVisible(true);
      setEditMessage(err.response.data)
    }
  )

  const editQuestionRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'Word Sentence Completion' && 'editModuleWSCQuestion') ||
        (activityType === 'QuizShow' && 'editModuleQuizShowQuestion') ||
        (activityType === 'Spin A Wheel' && 'editModuleSAWQuestion') ||
        (activityType === 'Matching Type' && 'editModuleMTQuestion') ||
        (activityType === 'Multiple Choice' && 'editModuleMCQuestion') ||
        (activityType === 'Maze Chase' && 'editModuleMazeQuestion') ||
        (activityType === 'True or False' && 'editModuleTFQuestion') ||
        (activityType === 'Fill in the Blanks' && 'editModuleFITBQuestion') ||
        (activityType === 'Crosswords' && 'editModuleCWQuestion') ||
        (activityType === 'Jumbled Letters' && 'editModuleJLQuestion') ||
        (activityType === 'Drag and Drop' && 'editModuleDDQuestion') ||
        (activityType === 'Open The Box' && 'editModuleOTBQuestion')
        }`,
    },
    (data) => {
      viewActivityModule.sendRequest(null, { moduleId })
      setEditingKeyQuestion('')
      setEditMessage(data);
      setEditModalVisible(true);
      setIsEditLoading(false);
      setChoices(null);
    },
    (err) => {
      setIsEditLoading(false);
      setEditModalVisible(true);
      setEditMessage(err.response.data)
    }
  )

  const editKeyRequest = useRequest(
    {
      method: 'POST',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'Drag and Drop' && 'editModuleDDKey')
        }`,
    },
    (data) => {
      viewActivityModule.sendRequest(null, { moduleId })
      setEditingKey('')
      setEditMessage(data);
      setEditModalVisible(true);
      setIsEditLoading(false);
    },
    (err) => {
      setIsEditLoading(false);
      setEditModalVisible(true);
      setEditMessage(err.response.data)
    }
  )

  const deleteModule = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/removeModule`
    },
    (data) => {
      setMessage(data)
      setIsLoading(false);
      getActivity.sendRequest();
      setIsModalVisible(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const deleteModuleQuestion = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'QuizShow' && 'removeModuleQuizShowQuestion') ||
        (activityType === 'Fill in the Blanks' && 'removeModuleFITBQuestion') ||
        (activityType === 'Jumbled Letters' && 'removeModuleJLQuestion') ||
        (activityType === 'Crosswords' && 'removeModuleCWQuestion') ||
        (activityType === 'Word Sentence Completion' && 'removeModuleWSCQuestion') ||
        (activityType === 'Whack A Mole' && 'removeModuleWAMQuestion') ||
        (activityType === 'Spin A Wheel' && 'removeModuleSAWQuestion') ||
        (activityType === 'Matching Type' && 'removeModuleMTQuestion') ||
        (activityType === 'Multiple Choice' && 'removeModuleMCQuestion') ||
        (activityType === 'Maze Chase' && 'removeModuleMazeQuestion') ||
        (activityType === 'True or False' && 'removeModuleTFQuestion') ||
        (activityType === 'Drag and Drop' && 'removeModuleDDQuestion') ||
        (activityType === 'Open The Box' && 'removeModuleOTBQuestion')}`
    },
    (data) => {
      setMessage(data)
      setIsLoading(false);
      viewActivityModule.sendRequest(null, { moduleId })
      setIsModalVisible(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const deleteModuleQuestionImage = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'QuizShow' && 'removeModuleQuizShowQuestionImage') ||
        (activityType === 'Multiple Choice' && 'removeModuleMCQuestionImage') ||
        (activityType === 'Word Sentence Completion' && 'removeModuleWSCQuestionImage')
        // ||(activityType === 'Fill in the Blanks' && 'removeModuleFITBQuestion') ||
        // (activityType === 'Jumbled Letters' && 'removeModuleJLQuestion') ||
        // (activityType === 'Crosswords' && 'removeModuleCWQuestion') ||
        // (activityType === 'Whack A Mole' && 'removeModuleWAMQuestion') ||
        // (activityType === 'Spin A Wheel' && 'removeModuleSAWQuestion') ||
        // (activityType === 'Matching Type' && 'removeModuleMTQuestion') ||
        // (activityType === 'Maze Chase' && 'removeModuleMazeQuestion') ||
        // (activityType === 'True or False' && 'removeModuleTFQuestion') ||
        // (activityType === 'Drag and Drop' && 'removeModuleDDQuestion') ||
        // (activityType === 'Open The Box' && 'removeModuleOTBQuestion')
        }`
    },
    (data) => {
      setMessage(data)
      setIsLoading(false);
      viewActivityModule.sendRequest(null, { moduleId })
      setIsModalVisible(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const deleteModuleChoice = useRequest(
    {
      method: 'GET',
      endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
        (authContext.userType === "1" && 'admin-insti') ||
        (authContext.userType === "2" && 'admin-school') ||
        (authContext.userType === "3" && 'encoder')
        }/${(authContext.userType === "0" && 'modules') ||
        (authContext.userType === "3" && 'data')
        }/${(activityType === 'QuizShow' && 'removeModuleQuizShowChoices') ||
        (activityType === 'Whack A Mole' && 'removeModuleWAMChoices') ||
        (activityType === 'Matching Type' && 'removeModuleMTChoices') ||
        (activityType === 'Maze Chase' && 'removeModuleMazeChoices') ||
        (activityType === 'Open The Box' && 'removeModuleOTBChoices') ||
        (activityType === 'Multiple Choice' && 'removeModuleMCChoices') ||
        (activityType === 'Spin A Wheel' && 'removeModuleSAWChoices') ||
        (activityType === 'Drag and Drop' && 'removeModuleDDChoices') ||
        (activityType === 'Word Sentence Completion' && 'removeModuleWSCChoices')
        }`
    },
    (data) => {
      setMessage(data)
      setIsLoading(false);
      viewActivityModule.sendRequest(null, { moduleId })
      setIsModalVisible(true);
    },
    (err) => {
      setIsLoading(false);
      setIsModalVisible(true);
      setMessage(err?.response?.data)
    }
  )

  const onClose = () => {
    setVisible(false);
  }

  const onViewTopic = async (activityType, type, moduleId) => {
    setIsLoading(true);
    await viewActivityModule.sendRequest(null, { moduleId })
    setParentMenuTitle(type);
    setActivityType(activityType)
    setModuleId(moduleId)
  }

  const addModuleQuestion = () => {
    setChildrenVisible(true);
  }

  const addModuleQuestionClose = () => {
    setChildrenVisible(false);
  }

  const onEdit = ({ values, scoreValidation, saveAnswer }) => {
    console.log(values)
    editRequest.sendRequest({
      type: values?.values?.type ? values?.values?.type : searchViewDataSource.type,
      directions: values.values.instruction,
      lecture: values?.values?.lecture,
      retryLimit: values.values.retryLimit,
      scoreValidation: scoreValidation === true ? 1 : 0,
      isSaveAnswers: saveAnswer === true ? 1 : 0,
      isUploadRequired: searchViewDataSource.isUploadRequired === 1 ? 1 : 0,
      mazeSize: searchViewDataSource.activityType === "Maze" ? values.values.mazeSize === 5 ? null : values.values.mazeSize : null,
      isGroup: values.values.isGroup
    }, { moduleId })
    setIsEditLoading(true);
  }

  const onEditChoices = (data, choiceId) => {
    editChoicesRequest.sendRequest({
      ...data
    }, { choiceId })
    setIsEditLoading(true);
  }

  const onDeleteModule = (moduleId) => {
    setIsLoading(true);
    deleteModule.sendRequest(null, { moduleId })
  }

  const onDeleteModuleQuestion = (questionId) => {
    setIsLoading(true);
    deleteModuleQuestion.sendRequest(null, { questionId })
  }

  const onDeleteModuleQuestionImage = (questionId) => {
    setIsLoading(true);
    deleteModuleQuestionImage.sendRequest(null, { questionId })
  }

  const handleOkQuestion = () => {
    onDeleteModuleQuestion(questionId);
  }

  const handleCancelQuestion = () => {
    setDeleteModuleQuestionModalVisible(false);
    setChoiceId(null);
  }

  const onDeleteModuleChoices = (choiceId) => {
    setDeleteModuleModalVisible(false);
    setIsLoading(true);
    deleteModuleChoice.sendRequest(null, { choiceId })
  }

  const handleOkChoice = () => {
    onDeleteModuleChoices(choiceId);
  }

  const handleCancelChoice = () => {
    setDeleteModuleModalVisible(false);
    setChoiceId(null);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const columns = [
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: '100px',
      render: (row) => (
        <Dropdown overlay={
          <Menu
            items={
              [
                {
                  label: 'View',
                  key: '0',
                  icon: <FundViewOutlined />,
                  onClick: () => {
                    onViewTopic(row.activityType, row.type, row.moduleId);
                  }
                },
                {
                  label: 'Remove',
                  key: '2',
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    onDeleteModule(row.moduleId);
                  }
                },

              ]}
          />
        }
          trigger={['click']}
        >
          <Button type="primary">
            <Space>
              Action
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (tags, index) => (
        <span>
          {
            tags === 0 && (<Tag color={Colors.blue} key={index.id}>
              {'ACTIVE'}
            </Tag>) ||
            tags === 1 && (<Tag color={Colors.grey} key={index.id}>
              {'DELETED'}
            </Tag>)
          }
        </span>
      ),
    },
    {
      title: 'Subject Name',
      key: 'subjectName',
      dataIndex: 'subjectName',
      width: '200px',
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    {
      title: 'Topic Name',
      key: 'topicName',
      dataIndex: 'topicName',
      width: '200px',
      sorter: (a, b) => a.topicName.localeCompare(b.topicName),
    },
    {
      title: 'Module Type',
      key: 'type',
      dataIndex: 'type',
      width: '200px',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Activity Type',
      key: 'activityType',
      dataIndex: 'activityType',
      width: '200px',
      sorter: (a, b) => a.activityType.localeCompare(b.activityType),
    },
    {
      title: 'Direction',
      key: 'directions',
      dataIndex: 'directions',
      width: '200px',
      sorter: (a, b) => a.directions.localeCompare(b.directions),
      ellipsis: true,
    },
    {
      title: 'Retry',
      key: 'retryLimit',
      dataIndex: 'retryLimit',
      width: '200px',
      sorter: (a, b) => a.retryLimit - b.retryLimit,
    },
    {
      title: 'Score Validation',
      key: 'scoreValidation',
      dataIndex: 'scoreValidation',
      width: '200px',
      sorter: (a, b) => a.scoreValidation - b.scoreValidation,
      render(row) {
        return row === 0 ? 'No' : 'Yes'
      }
    },
    {
      title: 'Save Answers',
      key: 'isSaveAnswers',
      dataIndex: 'isSaveAnswers',
      width: '200px',
      sorter: (a, b) => a.isSaveAnswers - b.isSaveAnswers,
      render(row) {
        return row === 0 ? 'No' : 'Yes'
      }
    },
    {
      title: 'Upload Required',
      key: 'isUploadRequired',
      dataIndex: 'isUploadRequired',
      width: '200px',
      sorter: (a, b) => a.isUploadRequired - b.isUploadRequired,
      render(row) {
        return row === 0 ? 'No' : 'Yes'
      }
    },
  ];

  const onAddQuestion = ({ activityType, moduleId }) => {
    {
      activityType === 'FITB' && (
        <FIBQuestion
          moduleId={moduleId}
          module={'FILL IN THE BLANKS'}
        />
      )
    }
  }

  const uniqueByKey = (data, key) => {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  const onModuleEditDirection = () => {
    setEditDrawer(true);
  }

  const onModuleEditDirectionClose = () => {
    setEditDrawer(false);
  }

  const handleEditOk = () => {
    setEditModalVisible(false);
    setEditDrawer(false);
  }

  const cancelQuestion = () => {
    setEditingKeyQuestion('');
    setAddingKeyChoices('');
    form.setFieldsValue({ description: '' })
    setChoices(null);
  };

  const saveQuestionFITB = async (questionId) => {
    const values = form.getFieldsValue();
    let answers = []
    let formData = new FormData();
    formData.append("question", question ? question : '');
    formData.append("image", values.image?.fileList?.[0] === undefined ? '' : values.image?.fileList[0].originFileObj);
    values.answers?.map((e, index) => {
      answers.push({ [1]: e })
    })
    formData.append("answers", JSON.stringify(answers));
    formData.append("totalItems", totalItems);
    editQuestionRequest.sendRequest(formData, { questionId });
    setQuestion(null)
    setTotalItems(0);
    form.resetFields()
  }

  const saveQuestionJL = async (questionId) => {
    const values = form.getFieldsValue();
    let answers = []
    let formData = new FormData();
    formData.append("question", question ? question : '');
    formData.append("image", values.image?.fileList?.[0] === undefined ? '' : values.image?.fileList[0].originFileObj);
    values.answers?.map((e, index) => {
      answers.push({ [1]: e })
    })
    formData.append("answers", JSON.stringify(answers));
    formData.append("totalItems", totalItems);
    editQuestionRequest.sendRequest(formData, { questionId });
    setQuestion(null)
    setTotalItems(0);
    form.resetFields()
  }

  const saveQuestionCW = async (questionId) => {
    const values = form.getFieldsValue();

    editQuestionRequest.sendRequest({
      question: values.question,
      answer: values?.answer,
      alignment: values.alignment ? values.alignment : "ACROSS",
      row: values.row ? values.row : 0,
      col: values.col ? values.col : 0,
      number: values.number ? values.number : 1
    }, { questionId });
    setQuestion(null)
    setTotalItems(0);
    form.resetFields()
  }

  const saveQuestion = async (questionId) => {
    const values = form.getFieldsValue();
    // if (!values?.tName && !values?.fName && !values?.t && !values.f) {
    //   return Message.error("Please input true or false indicator.");
    // }

    let formData = new FormData();
    formData.append("question", values?.question ? values.question : '');
    formData.append("image", values?.image?.file ? values.image.file : '');
    formData.append("timeLimit", values?.time ? moment.duration(moment(values.time._d).format('HH:mm:ss')).asMilliseconds() : '');
    formData.append("isTrue", choices === true ? 1 : 0);
    formData.append("t", values?.tName && values.tName || values?.t?.fileList && values?.t?.fileList[0]?.originFileObj || '');
    formData.append("f", values?.fName && values.fName || values?.f?.fileList && values?.f?.fileList[0]?.originFileObj || '');
    editQuestionRequest.sendRequest(formData, { questionId });
  }

  const removeQuestion = async (questionId) => {
    onDeleteModuleQuestion(questionId)
  }

  const removeQuestionImage = async (questionId) => {
    onDeleteModuleQuestionImage(questionId)
  }

  const cancelChoices = () => {
    setEditingKeyChoices({ editable: null, choiceId: null, description: null });
    form.setFieldsValue({ description: '' })
  };

  const addChoices = async () => {
    const values = form.getFieldsValue();
    console.log(values)
    let formData = new FormData();
    formData.append("answer", values?.answer ? values.answer : '');
    formData.append("description", values?.description ? values.description : '');
    formData.append("image", values?.upload ? values.upload.file : '');
    formData.append("isCorrect", choices === true ? 1 : 0);
    if (addingKeyChoices) {
      addChoicesRequest.sendRequest(formData, { questionId: addingKeyChoices });
    } else {
      addChoicesRequest.sendRequest(formData, { keyId: addChoiceDD?.keyId });
    }
    setAddingKeyChoices('')
    setAddChoiceDD({ adding: null, keyId: null });
  }

  const saveChoices = async (choiceId) => {
    const values = form.getFieldsValue();
    let formData = new FormData();
    formData.append("description", values?.description ? values.description : '');
    formData.append("image", values?.upload ? values.upload.file : '');
    formData.append("isCorrect", choices === true ? 1 : 0);
    editChoicesRequest.sendRequest(formData, { choiceId });
  }

  const removeChoices = async (choiceId) => {
    onDeleteModuleChoices(choiceId)
  }

  const saveKey = async (keyId) => {
    const values = form.getFieldsValue();

    editKeyRequest.sendRequest({
      key: values.name,
    }, { keyId });
    form.resetFields()
  }

  const cancelKey = () => {
    setEditingKey({ editable: null, keyId: null, name: null });
    form.setFieldsValue({ description: '' })
  };

  const removeKey = async (choiceId) => {
    onDeleteModuleChoices(choiceId)
  }

  const addChoice = async (keyId) => {
    const values = form.getFieldsValue();

    editKeyRequest.sendRequest({
      key: values.name,
    }, { keyId });
    form.resetFields()
  }

  const checkBoxImage = (e, index) => {
    setChoices(e.target.checked);
  }

  const onChangeRadio = (e, index) => {
    setValue(e.target.value);
  }

  const props = {
    accept: ".png, .jpg, .jpeg, .gif, .tiff",
    multiple: false,
    beforeUpload: (file) => {
      console.log(file)
      const isLt2M = file.size / 300 / 300 < 2;
      if (!isLt2M) {
        // Message.error("Image must smaller!")
        // return false;
      }
      return false;
    },
    onChange(info) {
      console.log(info);
      const isLt2M = info.file.size / 300 / 300 < 2;
      console.log(isLt2M)
      if (!isLt2M) {
        Message.error("Image must be smaller!")
        form.resetFields()
        return;
      }

      if (isLt2M) {
        console.log(isLt2M)
        if (info.file.status !== "removed") {
          let reader = new FileReader();
          console.log(info.file)
          reader.readAsDataURL(info.file);
        } else if (info.file.status === 'done') {
          Message.success(`${info.file.name} file uploaded successfully`);
        }
      }
    },
  };

  useEffect(() => {
    getActivity.sendRequest();

  }, []);

  const questionColumn = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'questionId',
      editable: true,
      width: "90%",
      render: (item, record, index) => {
        return (index + 1) + '. ' + item ? item : ''
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(_, record);
        const adding = isAdding(_, record);
        return editable ? (
          <Space>
            <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestion(record.questionId)}>
              <a>Save</a>
            </Popconfirm>
            <Typography.Link
              onClick={cancelQuestion}
            >
              Cancel
            </Typography.Link>
          </Space>
        ) : (
          <Space>
            {/* <Typography.Link
              className=''
              onClick={() => {
                add(record)
              }}
            >
              + choice
            </Typography.Link> */}
            <Typography.Link
              className='update-profile__edit-button'
              onClick={() => {
                edit(record)
              }}
            >
              Edit
            </Typography.Link>
            <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(record.questionId)}>
              <a style={{ color: "#ff4d4f" }}>Remove</a>
            </Popconfirm>
          </Space>
        )
      }
    },
  ]

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = (inputType === 'text' && <Input />)
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record) => record.questionId === editingKeyQuestion;
  const isAdding = (record) => record.questionId === addingKeyChoices;

  const add = (record) => {
    setAddingKeyChoices(record.questionId);
  };

  const edit = (record) => {
    console.log(record)
    if (record?.t) {
      setValue(record.t.includes('uploads/') ? 2 : 1)
    }
    if (record?.isTrue) {
      setChoices(record.isTrue === 1 ? true : false)
    }
    form.setFieldsValue({
      question: '',
      ...record,
    });
    setEditingKeyQuestion(record.questionId);
  };

  const isEditingWAM = (record) => record.choiceId === editingKeyChoice;

  const editWAM = (record) => {
    form.setFieldsValue({
      choices: '',
      ...record,
    });
    setEditingKeyChoice(record.choiceId);
  };

  const cancelWAM = () => {
    setEditingKeyChoice('');
    setChoices(null);
  };

  const cancel = () => {
    setEditingKeyQuestion('');
  };

  const mergedColumns = questionColumn.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'gradeLevel' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        adding: isAdding(record),
      }),
    };
  });

  const config = {
    rules: [
      {
        type: 'object',
        required: true,
        message: 'Please select time!',
      },
    ],
  };

  const gradeLevelOnChange = (value) => {
    if (value === "") {
      setSubject([]);
      setQuarter([]);
      setTopic([]);
      setSelectedSubjectFilter(null);
      setSelectedQuarterFilter(null);
      setSelectedTopicFilter(null);
      form.setFieldsValue({ subjectFilter: "", quarterFilter: "", topicFilter: "" });
    } else {
      setIsLoadingActivity(true)
      setSelectedSubjectFilter(null);
      setSelectedQuarterFilter(null);
      setSelectedTopicFilter(null);
      getSubject.sendRequest(null, { gradeLevel: value })
      form.setFieldsValue({ subjectFilter: "", quarterFilter: "", topicFilter: "" });
    }
  }

  const subjectOnChange = (value) => {
    if (value === "") {
      setSelectedTopicFilter(null);
      setSelectedSubjectFilter(null);
      setSelectedQuarterFilter(null);
      setQuarter([]);
      form.setFieldsValue({ quarterFilter: "", topicFilter: "" });
    } else {
      setSelectedTopicFilter(null);
      setSelectedSubjectFilter(value);
      setSelectedQuarterFilter(null);
      form.setFieldsValue({ quarterFilter: "", topicFilter: "" });
      setQuarter([
        {
          quarter: 1,
          name: 'Quarter 1'
        },
        {
          quarter: 2,
          name: 'Quarter 2'
        },
        {
          quarter: 3,
          name: 'Quarter 3'
        },
        {
          quarter: 4,
          name: 'Quarter 4'
        },
      ])
    }
  }

  const quarterOnChange = (value) => {
    setSelectedQuarterFilter(value);
    setSelectedTopicFilter(null);
    setIsLoadingActivity(true);
    form.setFieldsValue({ topicFilter: "" });
    getTopic.sendRequest({ subjectId: selectedSubjectFilter, quarter: value }, null)
  }

  const topicOnChange = (value) => {
    setSelectedTopicFilter(value);
  }

  const filterSearchBtn = () => {
    setIsLoadingActivity(true);
    if (selectedTopicFilter) {
      getModulesPerTopic.sendRequest(null, { topicId: selectedTopicFilter })
    } else {
      getActivity.sendRequest();
    }
  }

  const clearSearchBtn = () => {
    setSubject([]);
    setQuarter([]);
    setTopic([]);
    setSelectedSubjectFilter(null);
    setSelectedQuarterFilter(null);
    setSelectedTopicFilter(null);
    form.setFieldsValue({ gradeLevelFilter: "", subjectFilter: "", quarterFilter: "", topicFilter: "" });
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const questionOnChange = (e) => {
    setQuestion(e.target.value);
  }

  return (
    <>
      <CustomSpinnerModal isLoading={isLoading} />
      {!activity && <CustomSpinnerModal isLoading={true} />}
      {activity && (
        <Card
          title="Activity"
          extra={
            <Row>
              <Col xs={24} md={12} lg={12} xl={12}>
                <Link to={'/activity/add'}>
                  <Button type="primary" onClick={() => console.log('test')}>Add</Button>
                </Link>
              </Col>
            </Row>
          }
        >
          <Form form={form}>
            <Row style={{
              justifyContent: "space-between"
            }}>
              <Col
                xs={24} md={5} lg={5} xl={5}
                style={{ width: '20%', marginRight: '5px' }}
              >
                <Form.Item
                  name="gradeLevelFilter"
                >
                  <Select
                    placeholder="Select a grade"
                    style={{ width: '100%' }}
                    onChange={gradeLevelOnChange}
                  >
                    <Option value="">Select</Option>
                    <Option value="1">Grade 1</Option>
                    <Option value="2">Grade 2</Option>
                    <Option value="3">Grade 3</Option>
                    <Option value="4">Grade 4</Option>
                    <Option value="5">Grade 5</Option>
                    <Option value="6">Grade 6</Option>
                    <Option value="7">Grade 7</Option>
                    <Option value="8">Grade 8</Option>
                    <Option value="9">Grade 9</Option>
                    <Option value="10">Grade 10</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24} md={5} lg={5} xl={5}
                style={{ width: '20%', marginRight: '5px' }}
              >
                <Form.Item
                  name="subjectFilter"
                >
                  <Select
                    placeholder="Select a subject"
                    style={{ width: '100%' }}
                    loading={isLoadingActivity}
                    onChange={subjectOnChange}
                  >
                    <Option value="" disabled>Select a subject</Option>
                    {subject.map((sub, i) => (
                      <Option key={i} value={sub.subjectId}>{sub.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24} md={5} lg={5} xl={5}
                style={{ width: '20%', marginRight: '5px' }}
              >
                <Form.Item
                  name="quarterFilter"
                >
                  <Select
                    placeholder="Select a quarter"
                    style={{ width: '100%' }}
                    onChange={quarterOnChange}
                  >
                    <Option value="" disabled>Select a quarter</Option>
                    {quarter.map((q, i) => (
                      <Option key={i} value={q.quarter}>{q.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24} md={4} lg={4} xl={4}
                style={{ width: '20%', marginRight: '10px' }}
              >
                <Form.Item
                  name="topicFilter"
                >
                  <Select
                    placeholder="Select a topic"
                    style={{ width: '100%' }}
                    onChange={topicOnChange}
                  >
                    <Option value="" disabled>Select a topic</Option>
                    {topic.map((q, i) => (
                      <Option key={i} value={q.topicId}>{q.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={2} lg={2} xl={2}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={clearSearchBtn}
                >
                  Clear
                </Button>
              </Col>
              <Col xs={12} md={2} lg={2} xl={2}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={filterSearchBtn}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          <Row justify="space-between">
            <Col span={7} xs={24} md={7} lg={7} xl={7}>
              <ExportTableButton
                dataSource={activity}
                columns={columns}
                btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                showColumnPicker
              >
                Export to CSV
              </ExportTableButton>
            </Col>
            <Col span={7} offset={8} xs={16} md={7} lg={7} xl={7}>
              <SearchTableInput
                columns={columns}
                dataSource={activity}
                setDataSource={setSearchDataSource}
                inputProps={{
                  placeholder: "Search this table...",
                  prefix: <SearchOutlined />,
                }}
              />
            </Col>
          </Row>
          <Form form={form} component={false}>
            <Table
              dataSource={searchDataSource}
              columns={[
                {
                  title: 'Action',
                  dataIndex: '',
                  key: 'x',
                  width: '100px',
                  fixed: 'left',
                  render: (row) => (
                    <Dropdown overlay={
                      <Menu
                        items={
                          [
                            {
                              label: 'View',
                              key: '0',
                              icon: <FundViewOutlined />,
                              onClick: () => {
                                onViewTopic(row.activityType, row.type, row.moduleId);
                              }
                            },
                            {
                              label: 'Remove',
                              key: '2',
                              icon: <DeleteOutlined />,
                              onClick: () => {
                                onDeleteModule(row.moduleId);
                              }
                            },

                          ]}
                      />
                    }
                      trigger={['click']}
                    >
                      <Button type="primary">
                        <Space>
                          Action
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  )
                },
                {
                  title: 'Status',
                  key: 'status',
                  dataIndex: 'status',
                  width: '100px',
                  fixed: 'left',
                  render: (tags, index) => (
                    <span>
                      {
                        tags === 0 && (<Tag color={Colors.blue} key={index.id}>
                          {'ACTIVE'}
                        </Tag>) ||
                        tags === 1 && (<Tag color={Colors.grey} key={index.id}>
                          {'DELETED'}
                        </Tag>)
                      }
                    </span>
                  ),
                },
                {
                  title: 'Subject Name',
                  key: 'subjectName',
                  dataIndex: 'subjectName',
                  width: '200px',
                  sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
                  filters: uniqueByKey(activity, act => act.subjectName).map((e) => ({
                    text: e.subjectName,
                    value: e.subjectName
                  })),
                  filterSearch: true,
                  onFilter: (value, record) => record.subjectName.indexOf(value) === 0,
                },
                {
                  title: 'Grade Level',
                  key: 'gradeLevel',
                  dataIndex: 'gradeLevel',
                  width: '200px',
                  sorter: (a, b) => b.gradeLevel - a.gradeLevel,
                },
                {
                  title: 'Topic Name',
                  key: 'topicName',
                  dataIndex: 'topicName',
                  width: '200px',
                  sorter: (a, b) => a.topicName.localeCompare(b.topicName),
                },
                {
                  title: 'Student Performance',
                  key: 'isGroup',
                  dataIndex: 'isGroup',
                  width: '200px',
                  sorter: (a, b) => a.isGroup - b.isGroup,
                  render(row) {
                    return row === 0 ? 'Individual' : 'By Group'
                  }
                },
                {
                  title: 'Activity Type',
                  key: 'activityType',
                  dataIndex: 'activityType',
                  width: '200px',
                  sorter: (a, b) => a.activityType.localeCompare(b.activityType),

                },
                {
                  title: 'Module Type',
                  key: 'type',
                  dataIndex: 'type',
                  width: '200px',
                  sorter: (a, b) => a.type.localeCompare(b.type),
                },
                {
                  title: 'Direction',
                  key: 'directions',
                  dataIndex: 'directions',
                  width: '200px',
                  sorter: (a, b) => a.directions.localeCompare(b.directions),
                  ellipsis: true,
                },
                {
                  title: 'Retry',
                  key: 'retryLimit',
                  dataIndex: 'retryLimit',
                  width: '200px',
                  sorter: (a, b) => a.retryLimit.localeCompare(b.retryLimit),
                },
                {
                  title: 'Score Validation',
                  key: 'scoreValidation',
                  dataIndex: 'scoreValidation',
                  width: '200px',
                  sorter: (a, b) => a.scoreValidation.localeCompare(b.scoreValidation),
                  render(row) {
                    return row === 0 ? 'No' : 'Yes'
                  }
                },
                {
                  title: 'Save Answers',
                  key: 'isSaveAnswers',
                  dataIndex: 'isSaveAnswers',
                  width: '200px',
                  sorter: (a, b) => a.isSaveAnswers.localeCompare(b.isSaveAnswers),
                  render(row) {
                    return row === 0 ? 'No' : 'Yes'
                  }
                },
                {
                  title: 'Upload Required',
                  key: 'isUploadRequired',
                  dataIndex: 'isUploadRequired',
                  width: '200px',
                  sorter: (a, b) => a.isUploadRequired.localeCompare(b.isUploadRequired),
                  render(row) {
                    return row === 0 ? 'No' : 'Yes'
                  }
                },
              ]}
              rowKey="id"
              pagination={{
                paginationSize: 10,
              }}
              scroll={{
                y: 350,
                x: '100vw',
              }}
              loading={isLoadingActivity}
            />
            <Drawer
              title={activityType + ' - ' + parentMenuTitle}
              placement="bottom"
              closable={false}
              onClose={onClose}
              visible={visible}
              key={parentMenuTitle}
              className="demo"
              height={childrenVisible || editDrawer ? "70%" : "92%"}
              extra={
                <Space>
                  {(activityType === 'POWTOON' || activityType === "Performance Task") &&
                    <Button type="primary"
                      onClick={() => {
                        setEditDrawer(true);
                      }}
                    >
                      Edit
                    </Button>
                  }
                  {(activityType !== 'POWTOON' && activityType !== "Performance Task") &&
                    <Button type="primary"
                      onClick={() => {
                        addModuleQuestion(activityType, moduleId)
                      }}
                    >
                      Add
                    </Button>
                  }
                </Space>
              }
            >
              {activityType === 'POWTOON' && <PowtoonView searchViewDataSource={searchViewDataSource} pause={visible} />}
              {activityType === 'Whack A Mole' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    dataSource={searchViewDataSource.activity.choices.filter((choice, index) => {
                      return choice.status != 1
                    })}
                    columns=
                    {[
                      {
                        title: 'Choice',
                        dataIndex: 'choiceId',
                        key: 'choiceId',
                        editable: true,
                        render: (item, record, index) => {
                          return 'Choice ' + (index + 1) + '.'
                        },
                      },
                      {
                        title: 'Action',
                        dataIndex: '',
                        key: 'x',
                        render: (_, record) => {
                          const editable = isEditingWAM(_, record);
                          return editable ? (
                            <Space>
                              <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(record.choiceId)}>
                                <a>Save</a>
                              </Popconfirm>
                              <Typography.Link
                                onClick={cancelWAM}
                              >
                                Cancel
                              </Typography.Link>
                            </Space>
                          ) : (
                            <Space>
                              <Typography.Link
                                className='update-profile__edit-button'
                                onClick={() => {
                                  editWAM(record)
                                  setChoices(record.isCorrect === 0 ? false : true)
                                }}
                              >
                                Edit
                              </Typography.Link>
                              <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(record.choiceId)}>
                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                              </Popconfirm>
                            </Space>
                          )
                        }
                      },
                    ]}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record, index) => {
                        const editable = isEditingWAM(record);
                        return <>
                          {
                            editable ? (
                              <>
                                <Col xs={1} md={1} lg={1}>
                                  <li>
                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                    <label htmlFor={`cb` + index}>
                                      <div className="icon">
                                        {choices === true ?
                                          <CheckSquareTwoTone
                                            style={{ fontSize: '20px' }}
                                          /> :
                                          <CloseSquareOutlined
                                            style={{ fontSize: '20px' }}
                                          />}
                                      </div>
                                    </label>
                                  </li>
                                </Col>
                                <Col>
                                  <Form.Item
                                    name="upload"
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please attach an image.',
                                      },
                                    ]}
                                  >
                                    <Upload
                                      listType="picture-card"
                                      {...props}
                                      maxCount={1}
                                      name="upload"
                                      defaultFileList={record.image ? [{
                                        uid: '-1',
                                        name: 'abc.png',
                                        status: 'done',
                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                      }] : null}
                                      style={{ width: '32px', height: '32px' }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div
                                          style={{
                                            marginTop: 8,
                                          }}
                                        >
                                          Upload
                                        </div>
                                      </div>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Text>Answer: </Text>
                                <Row>
                                  <Col xs={1} md={1} lg={1}>
                                    {record.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                  </Col>
                                  <Image
                                    width={100}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                  // src={record.image.includes("https") ? record.image : 'http://' + window.location.hostname + ':9003/' + record.image}
                                  />
                                </Row>
                              </>
                            )
                          }
                        </>
                      }
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="choiceId"
                    pagination={{
                      paginationSize: 10,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Matching Type' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity.questions}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          <Text>Answer: </Text>
                          {searchViewDataSource.activity.choices.map((choice, index) => {
                            return (
                              <Row key={index}>
                                <Col xs={1} md={1} lg={1}>
                                  {choice.questionId === record.questionId ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                </Col>
                                {choice.description !== '' ? (
                                  <Col xs={22} md={22} lg={22}>{choice.description}</Col>
                                ) : (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                  />
                                )}
                              </Row>
                            )
                          })}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'QuizShow' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <Row>
                              <Col xs={21} md={21} lg={21}>
                                <h5>Question Image: </h5>
                                {record.image ? (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                  // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                  />
                                ) : (
                                  <Text>No Data Available</Text>
                                )}
                              </Col>
                              {record.image ? (<Col style={{ marginLeft: '19px' }}>
                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestionImage(record?.questionId)}>
                                  <a style={{ color: "#ff4d4f" }}>Remove</a>
                                </Popconfirm>
                              </Col>) : null}
                            </Row>
                          )}
                          <br />
                          <br />
                          {editable ? (
                            <>
                              <Form.Item name="time" label="Time" {...config}>
                                <TimePicker
                                  style={{
                                    width: '90%',
                                  }}
                                  defaultValue={moment(convertMiliseconds(record.timeLimit), 'HH:mm:ss')}
                                  format={'mm:ss'}
                                />
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Time Limit : </h5>
                              {convertMilisecondsTime(record.timeLimit)}
                            </>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    <>
                                      <Col xs={8} md={8} lg={8}>
                                        <Form.Item
                                          validateTrigger={['onChange', 'onBlur']}
                                        >
                                          <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                            <Radio value={1}>Text</Radio>
                                            <Radio value={2}>Image</Radio>
                                          </Radio.Group>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={11} md={11} lg={11}>
                                        {value === 2 ? (
                                          <Form.Item
                                            name="upload"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Description is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKeyChoices.description}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        // src={'http://' + window.location.hostname + ':9003/' + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}
                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={8} md={8} lg={8}>
                                <Form.Item
                                  validateTrigger={['onChange', 'onBlur']}
                                >
                                  <Radio.Group onChange={(e) => onChangeRadio(e, 0)} value={value}>
                                    <Radio value={1}>Text</Radio>
                                    <Radio value={2}>Image</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                {value === 2 ? (
                                  <Form.Item
                                    name="upload"
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please attach an image.',
                                      },
                                    ]}
                                  >
                                    <Upload
                                      listType="picture-card"
                                      {...props}
                                      maxCount={1}
                                      name="upload"
                                      style={{ width: '32px', height: '32px' }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div
                                          style={{
                                            marginTop: 8,
                                          }}
                                        >
                                          Upload
                                        </div>
                                      </div>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name="description"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Description is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      defaultValue={editingKeyChoices.description}
                                      style={{
                                        width: "100%",
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'True or False' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          {editable ? (
                            <>
                              <Text>Current Answer: </Text>
                              <Row>
                                <Col xs={1} md={1} lg={1}>
                                  <li>
                                    <input type="checkbox" id={`cb` + 1} onChange={(e) => checkBoxImage(e, 1)} />
                                    <label htmlFor={`cb` + 1}>
                                      <div className="icon">
                                        {choices === true ?
                                          <CheckSquareTwoTone
                                            style={{ fontSize: '20px' }}
                                          /> :
                                          <CloseSquareOutlined
                                            style={{ fontSize: '20px' }}
                                          />}
                                      </div>
                                    </label>
                                  </li>
                                </Col>
                                <Col xs={22} md={22} lg={22}>
                                  <Text>
                                    {choices === true ? 'True' : 'False'}
                                  </Text>
                                </Col>
                                <Col xs={22} md={22} lg={22}>
                                  <Form.Item
                                    validateTrigger={['onChange', 'onBlur']}
                                    key={`radio`}
                                  >
                                    <Text>Set True or False: </Text>
                                    <Radio.Group
                                      defaultValue={record.t.includes('uploads/') ? 2 : 1}
                                      onChange={(e) => onChangeRadio(e)}
                                      value={value}
                                    >
                                      <Radio value={1}>Text</Radio>
                                      <Radio value={2}>Image</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                <Row>
                                  <Col>
                                    <Text>True: </Text>
                                  </Col>
                                  <Col style={{ width: value === 1 && '50%' }}>
                                    {value === 2 ? (<Form.Item
                                      name="t"
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <Upload
                                        listType="picture-card"
                                        {...props}
                                        maxCount={1}
                                        name="upload"
                                        style={{ width: '32px', height: '32px' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please attach an image.',
                                          },
                                        ]}
                                        defaultFileList={record.t.includes('uploads/') ? [{
                                          uid: '-1',
                                          name: 'abc.png',
                                          status: 'done',
                                          url: process.env.REACT_APP_SCHOOL_BOOK + record.t,
                                          // url: 'http://' + window.location.hostname + ':9003/' + record.t,
                                        }] : null}
                                      >
                                        <div>
                                          <PlusOutlined />
                                          <div
                                            style={{
                                              marginTop: 8,
                                            }}
                                          >
                                            Upload
                                          </div>
                                        </div>
                                      </Upload>
                                    </Form.Item>) : (
                                      <Form.Item
                                        rules={[
                                          {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input text",
                                          },
                                        ]}
                                        style={{
                                          width: '100%',
                                        }}
                                        name="tName"
                                      >
                                        <Input
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Text>False: </Text>
                                  </Col>
                                  <Col style={{ width: value === 1 && '50%' }}>
                                    {value === 2 ? (<Form.Item
                                      name="f"
                                      validateTrigger={['onChange', 'onBlur']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <Upload
                                        listType="picture-card"
                                        {...props}
                                        maxCount={1}
                                        name="upload"
                                        style={{ width: '32px', height: '32px' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please attach an image.',
                                          },
                                        ]}
                                        defaultFileList={record.f.includes('uploads/') ? [{
                                          uid: '-1',
                                          name: 'abc.png',
                                          status: 'done',
                                          url: process.env.REACT_APP_SCHOOL_BOOK + record.f,
                                          // url: 'http://' + window.location.hostname + ':9003/' + record.f,
                                        }] : null}
                                      >
                                        <div>
                                          <PlusOutlined />
                                          <div
                                            style={{
                                              marginTop: 8,
                                            }}
                                          >
                                            Upload
                                          </div>
                                        </div>
                                      </Upload>
                                    </Form.Item>) : (
                                      <Form.Item
                                        rules={[
                                          {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input text",
                                          },
                                        ]}
                                        style={{
                                          width: '100%',
                                        }}
                                        name="fName"
                                      >
                                        <Input
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Col>
                                </Row>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Text>Answer: </Text>
                              <Row>
                                <Col xs={1} md={1} lg={1}>
                                  {record.isTrue === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                </Col>
                                <Col xs={22} md={22} lg={22}>
                                  <Text>
                                    {record.isTrue === 1 ? 'True' : 'False'}
                                  </Text>
                                </Col>
                              </Row>
                              <br></br>
                              <>
                                <h5>True Image/Text: </h5>
                                {record.t.includes("uploads/") ? (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.t}
                                  // src={'http://' + window.location.hostname + ':9003/' + record.t}
                                  />
                                ) : (
                                  <Text>{record.t}</Text>
                                )}
                              </>
                              <>
                                <h5>False Image/Text: </h5>
                                {record.f.includes("uploads/") ? (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.f}
                                  // src={'http://' + window.location.hostname + ':9003/' + record.f}
                                  />
                                ) : (
                                  <Text>{record.f}</Text>
                                )}
                              </>
                            </>
                          )}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Word Sentence Completion' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <Row>
                              <Col xs={21} md={21} lg={21}>
                                <h5>Question Image: </h5>
                                {record.image ? (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                  // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                  />
                                ) : (
                                  <Text>No Data Available</Text>
                                )}
                              </Col>
                              {record.image ? (<Col style={{ marginLeft: '19px' }}>
                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestionImage(record?.questionId)}>
                                  <a style={{ color: "#ff4d4f" }}>Remove</a>
                                </Popconfirm>
                              </Col>) : null}
                            </Row>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    <>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={19} md={19} lg={19}>
                                        <Form.Item
                                          name="description"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Description is required",
                                            },
                                          ]}
                                        >
                                          <Input
                                            defaultValue={editingKeyChoices.description}
                                            style={{
                                              width: "100%",
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}

                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={19} md={19} lg={19}>
                                <Form.Item
                                  name="description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Description is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={editingKeyChoices.description}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Fill in the Blanks' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    key="questionId"
                    dataSource={searchViewDataSource.activity}
                    columns={[
                      {
                        title: 'Question',
                        dataIndex: 'question',
                        key: 'questionId',
                        render: (item, record, index) => {
                          return (index + 1) + '. ' + item
                        }
                      },
                      {
                        title: 'Action',
                        dataIndex: '',
                        key: 'x',
                        render: (_, record) => {
                          const editable = isEditing(_, record);
                          return editable ? (
                            <Space>
                              <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestionFITB(record.questionId)}>
                                <a>Save</a>
                              </Popconfirm>
                              <Typography.Link
                                onClick={cancelQuestion}
                              >
                                Cancel
                              </Typography.Link>
                            </Space>
                          ) : (
                            <Space>
                              <Typography.Link
                                className='update-profile__edit-button'
                                onClick={() => {
                                  edit(record)
                                }}
                              >
                                Edit
                              </Typography.Link>
                              <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(record.questionId)}>
                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                              </Popconfirm>
                            </Space>
                          )
                        }
                      },
                    ]}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        return <>
                          {editable ? (
                            <>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  fileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                              <Form.Item
                                name="question"
                                label="Question"
                              >
                                <TextArea
                                  rows={4}
                                  onChange={(e) => questionOnChange(e)}
                                  value={question}
                                />
                                <Button
                                  name="minus"
                                  icon={<DeleteOutlined />}
                                  disabled
                                  style={{ display: 'none' }}
                                  onClick={() => {
                                    const lastIndex = question.lastIndexOf((totalItems) + '.__________');
                                    const replacement = '';
                                    const replaced =
                                      question.substring(0, lastIndex) +
                                      replacement +
                                      question.substring(lastIndex + 15);
                                    setQuestion(replaced);
                                    setTotalItems(totalItems - 1);
                                  }}
                                />
                              </Form.Item>
                              <Form.List
                                name="answers"
                              >
                                {(fields, { add, remove }, { errors }) => (
                                  <Card
                                    size="small"
                                    title="Asnwers"
                                    extra={<Button type="primary" onClick={() => {
                                      add();
                                      setQuestion(question + (totalItems + 1) + '.__________');
                                      setTotalItems(totalItems + 1);
                                    }}>Add</Button>}
                                  >
                                    {fields.map((field, index) => (
                                      <Form.Item
                                        required={true}
                                        key={field.key}
                                        label={"Asnwer " + (+index + 1)}
                                      >
                                        <>
                                          <Row>
                                            <Col>
                                              <Form.Item
                                                {...field}
                                                rules={[
                                                  {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input text or delete this field.",
                                                  },
                                                ]}
                                                style={{
                                                  width: '100%',
                                                }}
                                              >
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col>
                                              <DeleteOutlined
                                                className="dynamic-delete-button"
                                                disabled={index + 1 === fields.length ? false : true}
                                                onClick={index + 1 !== fields.length ? null : () => {
                                                  remove(field.name);
                                                  const lastIndex = question?.lastIndexOf((totalItems) + '.__________');
                                                  const replacement = '';
                                                  const replaced =
                                                    question.substring(0, lastIndex) +
                                                    replacement +
                                                    question.substring(lastIndex + 15);
                                                  setQuestion(replaced);
                                                  setTotalItems(totalItems - 1);
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </>
                                      </Form.Item>
                                    ))}
                                  </Card>
                                )}
                              </Form.List>
                            </>
                          ) : (
                            <>
                              {record.image ? (
                                <Image
                                  width={100}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>Image: No Data Available</Text>
                              )}

                              <br />
                              <Text>Total Items: {record.totalItems}</Text>
                              <br />
                              <h5>Answers: </h5>
                              {record.choices?.map((e, index) => {
                                return (
                                  <>
                                    <Text>{(index + 1) + '. ' + e["1"]}</Text>
                                    <br />
                                  </>
                                )
                              })}
                            </>
                          )}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    pagination={{
                      paginationSize: 10,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Jumbled Letters' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    key="questionId"
                    dataSource={searchViewDataSource.activity}
                    columns={[
                      {
                        title: 'Question',
                        dataIndex: 'question',
                        key: 'questionId',
                        render: (item, record, index) => {
                          return (index + 1) + '. ' + item
                        }
                      },
                      {
                        title: 'Action',
                        dataIndex: '',
                        key: 'x',
                        render: (_, record) => {
                          const editable = isEditing(_, record);
                          return editable ? (
                            <Space>
                              <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestionJL(record.questionId)}>
                                <a>Save</a>
                              </Popconfirm>
                              <Typography.Link
                                onClick={cancelQuestion}
                              >
                                Cancel
                              </Typography.Link>
                            </Space>
                          ) : (
                            <Space>
                              <Typography.Link
                                className='update-profile__edit-button'
                                onClick={() => {
                                  edit(record)
                                }}
                              >
                                Edit
                              </Typography.Link>
                              <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(record.questionId)}>
                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                              </Popconfirm>
                            </Space>
                          )
                        }
                      },
                    ]}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        return <>
                          {editable ? (
                            <>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  fileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                              <Form.Item
                                name="question"
                                label="Question"
                              >
                                <TextArea
                                  rows={4}
                                  onChange={(e) => questionOnChange(e)}
                                  value={question}
                                />
                                <Button
                                  name="minus"
                                  icon={<DeleteOutlined />}
                                  disabled
                                  style={{ display: 'none' }}
                                  onClick={() => {
                                    const lastIndex = question.lastIndexOf((totalItems) + '.__________');
                                    const replacement = '';
                                    const replaced =
                                      question.substring(0, lastIndex) +
                                      replacement +
                                      question.substring(lastIndex + 15);
                                    setQuestion(replaced);
                                    setTotalItems(totalItems - 1);
                                  }}
                                />
                              </Form.Item>
                              <Form.List
                                name="answers"
                              >
                                {(fields, { add, remove }, { errors }) => (
                                  <Card
                                    size="small"
                                    title="Asnwers"
                                    extra={<Button type="primary" onClick={() => {
                                      add();
                                      setQuestion(question + (totalItems + 1) + '.__________');
                                      setTotalItems(totalItems + 1);
                                    }}>Add</Button>}
                                  >
                                    {fields.map((field, index) => (
                                      <Form.Item
                                        required={true}
                                        key={field.key}
                                        label={"Asnwer " + (+index + 1)}
                                      >
                                        <>
                                          <Row>
                                            <Col>
                                              <Form.Item
                                                {...field}
                                                rules={[
                                                  {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Please input text or delete this field.",
                                                  },
                                                ]}
                                                style={{
                                                  width: '100%',
                                                }}
                                              >
                                                <Input
                                                  style={{
                                                    width: '100%',
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                            <Col>
                                              <DeleteOutlined
                                                className="dynamic-delete-button"
                                                disabled={index + 1 === fields.length ? false : true}
                                                onClick={index + 1 !== fields.length ? null : () => {
                                                  remove(field.name);
                                                  const lastIndex = question?.lastIndexOf((totalItems) + '.__________');
                                                  const replacement = '';
                                                  const replaced =
                                                    question.substring(0, lastIndex) +
                                                    replacement +
                                                    question.substring(lastIndex + 15);
                                                  setQuestion(replaced);
                                                  setTotalItems(totalItems - 1);
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </>
                                      </Form.Item>
                                    ))}
                                  </Card>
                                )}
                              </Form.List>
                            </>
                          ) : (
                            <>
                              {record.image ? (
                                <Image
                                  width={100}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>Image: No Data Available</Text>
                              )}

                              <br />
                              <Text>Total Items: {record.totalItems}</Text>
                              <br />
                              <h5>Answers: </h5>
                              {record.choices?.map((e, index) => {
                                return (
                                  <>
                                    <Text>{(index + 1) + '. ' + e["1"]}</Text>
                                    <br />
                                  </>
                                )
                              })}
                            </>
                          )}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    pagination={{
                      paginationSize: 10,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Open The Box' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record)
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    // <>
                                    //   <Col xs={1} md={1} lg={1}>
                                    //     <li>
                                    //       <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                    //       <label htmlFor={`cb` + index}>
                                    //         <div className="icon">
                                    //           {choices === true ?
                                    //             <CheckSquareTwoTone
                                    //               style={{ fontSize: '20px' }}
                                    //             /> :
                                    //             <CloseSquareOutlined
                                    //               style={{ fontSize: '20px' }}
                                    //             />}
                                    //         </div>
                                    //       </label>
                                    //     </li>
                                    //   </Col>
                                    //   <Col xs={19} md={19} lg={19}>
                                    //     <Form.Item
                                    //       name="description"
                                    //       rules={[
                                    //         {
                                    //           required: true,
                                    //           message: "Description is required",
                                    //         },
                                    //       ]}
                                    //     >
                                    //       <Input
                                    //         defaultValue={editingKeyChoices.description}
                                    //         style={{
                                    //           width: "100%",
                                    //         }}
                                    //       />
                                    //     </Form.Item>
                                    //   </Col>
                                    // </>
                                    <>
                                      <Col xs={8} md={8} lg={8}>
                                        <Form.Item
                                          validateTrigger={['onChange', 'onBlur']}
                                        >
                                          <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                            <Radio value={1}>Text</Radio>
                                            <Radio value={2}>Image</Radio>
                                          </Radio.Group>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={11} md={11} lg={11}>
                                        {value === 2 ? (
                                          <Form.Item
                                            name="upload"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Description is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKeyChoices.description}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}

                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={19} md={19} lg={19}>
                                <Form.Item
                                  name="description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Description is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={editingKeyChoices.description}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Maze Chase' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    <>
                                      <Col xs={8} md={8} lg={8}>
                                        <Form.Item
                                          validateTrigger={['onChange', 'onBlur']}
                                        >
                                          <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                            <Radio value={1}>Text</Radio>
                                            <Radio value={2}>Image</Radio>
                                          </Radio.Group>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={11} md={11} lg={11}>
                                        {value === 2 ? (
                                          <Form.Item
                                            name="upload"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Description is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKeyChoices.description}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}
                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={19} md={19} lg={19}>
                                <Form.Item
                                  name="description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Description is required",
                                    },
                                  ]}
                                >
                                  <Input
                                    defaultValue={editingKeyChoices.description}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Spin A Wheel' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    <>
                                      <Col xs={8} md={8} lg={8}>
                                        <Form.Item
                                          validateTrigger={['onChange', 'onBlur']}
                                        >
                                          <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                            <Radio value={1}>Text</Radio>
                                            <Radio value={2}>Image</Radio>
                                          </Radio.Group>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={11} md={11} lg={11}>
                                        {value === 2 ? (
                                          <Form.Item
                                            name="upload"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Description is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKeyChoices.description}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}
                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={8} md={8} lg={8}>
                                <Form.Item
                                  validateTrigger={['onChange', 'onBlur']}
                                >
                                  <Radio.Group onChange={(e) => onChangeRadio(e, 0)} value={value}>
                                    <Radio value={1}>Text</Radio>
                                    <Radio value={2}>Image</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                {value === 2 ? (
                                  <Form.Item
                                    name="upload"
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please attach an image.',
                                      },
                                    ]}
                                  >
                                    <Upload
                                      listType="picture-card"
                                      {...props}
                                      maxCount={1}
                                      name="upload"
                                      style={{ width: '32px', height: '32px' }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div
                                          style={{
                                            marginTop: 8,
                                          }}
                                        >
                                          Upload
                                        </div>
                                      </div>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name="description"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Description is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      defaultValue={editingKeyChoices.description}
                                      style={{
                                        width: "100%",
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Multiple Choice' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <Row>
                              <Col xs={21} md={21} lg={21}>
                                <h5>Question Image: </h5>
                                {record.image ? (
                                  <Image
                                    width={200}
                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                  // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                  />
                                ) : (
                                  <Text>No Data Available</Text>
                                )}
                              </Col>
                              {record.image ? (<Col style={{ marginLeft: '19px' }}>
                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestionImage(record?.questionId)}>
                                  <a style={{ color: "#ff4d4f" }}>Remove</a>
                                </Popconfirm>
                              </Col>) : null}
                            </Row>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.choices.map((choice, index) => {
                            return (
                              <Form
                                name="edit-choice"
                                autoComplete="off"
                                form={form}
                                requiredMark={true}
                                key={index}
                              >
                                <Row>
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    null
                                  ) : (
                                    <Col xs={1} md={1} lg={1}>
                                      {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                    </Col>
                                  )}
                                  {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                    <>
                                      <Col xs={8} md={8} lg={8}>
                                        <Form.Item
                                          validateTrigger={['onChange', 'onBlur']}
                                        >
                                          <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                            <Radio value={1}>Text</Radio>
                                            <Radio value={2}>Image</Radio>
                                          </Radio.Group>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={1} md={1} lg={1}>
                                        <li>
                                          <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                          <label htmlFor={`cb` + index}>
                                            <div className="icon">
                                              {choices === true ?
                                                <CheckSquareTwoTone
                                                  style={{ fontSize: '20px' }}
                                                /> :
                                                <CloseSquareOutlined
                                                  style={{ fontSize: '20px' }}
                                                />}
                                            </div>
                                          </label>
                                        </li>
                                      </Col>
                                      <Col xs={11} md={11} lg={11}>
                                        {value === 2 ? (
                                          <Form.Item
                                            name="upload"
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name="description"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Description is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKeyChoices.description}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    choice.description !== null ? (
                                      <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                    ) : (
                                      <Col xs={19} md={19} lg={19}>
                                        <Image
                                          width={200}
                                          src={process.env.REACT_APP_SCHOOL_BOOK + choice.image}
                                        />
                                      </Col>
                                    )
                                  )}
                                  <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                      <Space>
                                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                          <a>Save</a>
                                        </Popconfirm>
                                        <Typography.Link
                                          onClick={cancelChoices}
                                        >
                                          Cancel
                                        </Typography.Link>
                                      </Space>
                                    ) : (
                                      <Space>
                                        <Typography.Link
                                          className='update-profile__edit-button'
                                          onClick={() => {
                                            setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                            setChoices(choice.isCorrect === 1 ? true : false);
                                            setValue(choice.description ? 1 : 2)
                                            form.setFieldsValue({ description: choice.description })
                                          }}
                                        >
                                          Edit
                                        </Typography.Link>
                                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                          <a style={{ color: "#ff4d4f" }}>Remove</a>
                                        </Popconfirm>
                                      </Space>
                                    )}
                                  </Col>
                                </Row>
                              </Form>
                            )
                          })}
                          {addingKeyChoices === record.questionId ? (
                            <Row>
                              <Col xs={8} md={8} lg={8}>
                                <Form.Item
                                  validateTrigger={['onChange', 'onBlur']}
                                >
                                  <Radio.Group onChange={(e) => onChangeRadio(e, 0)} value={value}>
                                    <Radio value={1}>Text</Radio>
                                    <Radio value={2}>Image</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                              <Col xs={1} md={1} lg={1}>
                                <li>
                                  <input type="checkbox" id={`cb` + 0} onChange={(e) => checkBoxImage(e, 0)} />
                                  <label htmlFor={`cb` + 0}>
                                    <div className="icon">
                                      {choices === true ?
                                        <CheckSquareTwoTone
                                          style={{ fontSize: '20px' }}
                                        /> :
                                        <CloseSquareOutlined
                                          style={{ fontSize: '20px' }}
                                        />}
                                    </div>
                                  </label>
                                </li>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                {value === 2 ? (
                                  <Form.Item
                                    name="upload"
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please attach an image.',
                                      },
                                    ]}
                                  >
                                    <Upload
                                      listType="picture-card"
                                      {...props}
                                      maxCount={1}
                                      name="upload"
                                      style={{ width: '32px', height: '32px' }}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please attach an image.',
                                        },
                                      ]}
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div
                                          style={{
                                            marginTop: 8,
                                          }}
                                        >
                                          Upload
                                        </div>
                                      </div>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name="description"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Description is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      defaultValue={editingKeyChoices.description}
                                      style={{
                                        width: "100%",
                                      }}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                {addingKeyChoices === record.questionId ? (
                                  <Space>
                                    <Popconfirm
                                      title="Are you sure you want to save this?"
                                      onConfirm={() => addChoices()}
                                    >
                                      <a>Save</a>
                                    </Popconfirm>
                                    <Typography.Link
                                      onClick={cancelQuestion}
                                    >
                                      Cancel
                                    </Typography.Link>
                                  </Space>
                                ) : (
                                  null
                                )}
                              </Col>
                            </Row>
                          ) : (
                            null
                          )}
                          {!adding ? <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => add(record)}
                          >Add</Button> : null}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Crosswords' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    key="questionId"
                    dataSource={searchViewDataSource.activity}
                    columns={[
                      {
                        title: 'Question',
                        dataIndex: 'question',
                        key: 'questionId',
                        render: (item, record, index) => {
                          return (index + 1) + '. ' + item
                        }
                      },
                      {
                        title: 'Action',
                        dataIndex: '',
                        key: 'x',
                        render: (_, record) => {
                          const editable = isEditing(_, record);
                          return editable ? (
                            <Space>
                              <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestionCW(record.questionId)}>
                                <a>Save</a>
                              </Popconfirm>
                              <Typography.Link
                                onClick={cancelQuestion}
                              >
                                Cancel
                              </Typography.Link>
                            </Space>
                          ) : (
                            <Space>
                              <Typography.Link
                                className='update-profile__edit-button'
                                onClick={() => {
                                  edit(record)
                                }}
                              >
                                Edit
                              </Typography.Link>
                              <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(record.questionId)}>
                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                              </Popconfirm>
                            </Space>
                          )
                        }
                      },
                    ]}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        return <>
                          {editable ? (
                            <>
                              <Form.Item
                                name="question"
                                label="Question"
                              >
                                <TextArea
                                  rows={4}
                                  onChange={(e) => questionOnChange(e)}
                                  value={question}
                                />
                              </Form.Item>
                              <Row>
                                <Space>
                                  <Col>
                                    <p>Answer</p>
                                    <Form.Item
                                      name="answer"
                                      title="Answer"
                                      rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message: "Please input answer.",
                                        },
                                      ]}
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <Input
                                        style={{
                                          width: '100%',
                                        }}
                                        onBlur={() => {
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <p>Alignment</p>
                                    <Form.Item
                                      name="alignment"
                                      title="Alignment"
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <Select
                                        style={{
                                          width: 120,
                                        }}
                                        options={[
                                          {
                                            value: 'ACROSS',
                                            label: 'Across',
                                          },
                                          {
                                            value: 'DOWN',
                                            label: 'Down',
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Space>
                              </Row>
                              <br></br>
                              <Row>
                                <Space>
                                  <Col>
                                    <p>Row</p>
                                    <Form.Item
                                      name="row"
                                      title="Row"
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <InputNumber
                                        min={0}
                                        max={999}
                                        defaultValue={record.rowVal}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <p>Col</p>
                                    <Form.Item
                                      name="col"
                                      title="Col"
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <InputNumber
                                        defaultValue={record.colVal}
                                        min={0}
                                        max={999}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>
                                    <p>Number</p>
                                    <Form.Item
                                      name="number"
                                      title="Number"
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <InputNumber
                                        defaultValue={record.numberVal}
                                        min={0}
                                        max={999}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Space>
                              </Row>
                            </>
                          ) : (
                            <>
                              <h5>Answers: </h5>
                              {record.answer}
                              <br></br>
                              <h5>Alignment: </h5>
                              {record.alignment}
                              <br></br>
                              <h5>Row: </h5>
                              {record.rowVal ? record.rowVal : 0}
                              <br></br>
                              <h5>Col: </h5>
                              {record.colVal ? record.colVal : 0}
                              <br></br>
                              <h5>Number: </h5>
                              {record.numberVal ? record.numberVal : 0}
                            </>
                          )}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    pagination={{
                      paginationSize: 10,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Drag and Drop' && (
                <ViewActivity
                  searchViewDataSource={searchViewDataSource}
                  onModuleEditDirection={onModuleEditDirection}
                >
                  <Table
                    id="questionId"
                    dataSource={searchViewDataSource.activity}
                    deleteQuestion={
                      (value) => {
                        setDeleteModuleQuestionModalVisible(true)
                        setQuestionId(value)
                      }
                    }
                    columns={mergedColumns}
                    defaultExpandAllRows={true}
                    expandable={{
                      expandedRowRender: (record) => {
                        const editable = isEditing(record);
                        const adding = isAdding(record);
                        return <>
                          {editable ? (
                            <>
                              <h5>Question Image: </h5>
                              <Form.Item
                                name="image"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please attach an image.',
                                  },
                                ]}
                              >
                                <Upload
                                  listType="picture-card"
                                  {...props}
                                  maxCount={1}
                                  name="upload"
                                  defaultFileList={record.image ? [{
                                    uid: '-1',
                                    name: 'abc.png',
                                    status: 'done',
                                    url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                    // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                  }] : null}
                                  style={{ width: '32px', height: '32px' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please attach an image.',
                                    },
                                  ]}
                                >
                                  <div>
                                    <PlusOutlined />
                                    <div
                                      style={{
                                        marginTop: 8,
                                      }}
                                    >
                                      Upload
                                    </div>
                                  </div>
                                </Upload>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <h5>Question Image: </h5>
                              {record.image ? (
                                <Image
                                  width={200}
                                  src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                />
                              ) : (
                                <Text>No Data Available</Text>
                              )}
                            </>
                          )}
                          <br />
                          <br />
                          <h5>Answer: </h5>
                          {record.keys.map((key, index) => {
                            return (
                              <>
                                <Card>
                                  <Form
                                    name="edit-key"
                                    autoComplete="off"
                                    form={form}
                                    requiredMark={true}
                                    key={index}
                                  >
                                    <Row>
                                      {editingKey.editable && editingKey.keyId === key.id ? (
                                        <Col xs={19} md={19} lg={19}>
                                          <Form.Item
                                            name="name"
                                            rules={[
                                              {
                                                required: true,
                                                message: "Name is required",
                                              },
                                            ]}
                                          >
                                            <Input
                                              defaultValue={editingKey.name}
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      ) : (
                                        key.name !== null ? (
                                          <Col xs={19} md={19} lg={19}>{+index + 1}. Key - {key.name}</Col>
                                        ) : (
                                          <Col xs={19} md={19} lg={19}>
                                            <Image
                                              width={200}
                                              src={process.env.REACT_APP_SCHOOL_BOOK + key.image}
                                            />
                                          </Col>
                                        )
                                      )}
                                      <Col xs={4} md={4} lg={4} style={{ paddingLeft: "48px" }}>
                                        {editingKey.editable && editingKey.keyId === key.id ? (
                                          <Space>
                                            <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveKey(key.id)}>
                                              <a>Save</a>
                                            </Popconfirm>
                                            <Typography.Link
                                              onClick={() => {
                                                setEditingKey({ editable: false, keyId: null, name: null });
                                              }}
                                            >
                                              Cancel
                                            </Typography.Link>
                                          </Space>
                                        ) : (
                                          !addChoiceDD.adding ? (
                                            <Space>
                                              <Typography.Link
                                                className='update-profile__edit-button'
                                                onClick={() => {
                                                  setEditingKey({ editable: true, keyId: key.id, name: key.name });
                                                  form.setFieldsValue({ name: key.name })
                                                }}
                                              >
                                                Edit
                                              </Typography.Link>
                                              {record?.keys?.length > 0 ? (
                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeKey(key.id)}>
                                                  <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                </Popconfirm>
                                              ) : null}
                                            </Space>
                                          ) : null
                                        )}
                                      </Col>
                                    </Row>
                                    <p>Correct Choices: </p>
                                    <Row>
                                      {key?.items?.length === 0 ? (
                                        <Col xs={24} md={24} lg={24}>
                                          <p>No Data Available.</p>
                                        </Col>
                                      ) : (
                                        key?.items?.map((e, i) => {
                                          if (e.description) {
                                            return <Col key={i} xs={24} md={24} lg={24} style={{ wordBreak: "normal" }}>
                                              <Row>
                                                <Col xs={19} md={19} lg={19}>
                                                  <p>{i + 1}. {e.description}</p>
                                                </Col>
                                                <Col xs={4} md={4} lg={4} style={{ paddingLeft: "80px" }}>
                                                  <Space>
                                                    {key?.items?.length > 1 ? (
                                                      <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(e.choiceId)}>
                                                        <a className='update-profile__edit-button' style={{ color: "#ff4d4f" }}>Remove</a>
                                                      </Popconfirm>
                                                    ) : null}
                                                  </Space>
                                                </Col>
                                              </Row>
                                            </Col>
                                          } else {
                                            return <Col xs={24} md={24} lg={24} key={i}>
                                              <Row>
                                                <Col xs={19} md={19} lg={19}>
                                                  <Image
                                                    width={100}
                                                    src={process.env.REACT_APP_SCHOOL_BOOK + e.image}
                                                    style={{ marginBottom: 10 }}
                                                  />
                                                </Col>
                                                <Col xs={4} md={4} lg={4} style={{ paddingLeft: "80px" }}>
                                                  <Space>
                                                    {key?.items?.length > 1 ? (
                                                      <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(e.choiceId)}>
                                                        <a className='update-profile__edit-button' style={{ color: "#ff4d4f" }}>Remove</a>
                                                      </Popconfirm>
                                                    ) : null}
                                                  </Space>
                                                </Col>
                                              </Row>
                                            </Col>
                                          }
                                        })
                                      )}
                                    </Row>
                                    {!addChoiceDD.adding && record?.keys?.length < 4 ? (
                                      <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={() => setAddChoiceDD({ adding: true, keyId: key.id })}
                                      >Add</Button>
                                    ) : null}
                                    {addChoiceDD.adding && addChoiceDD.keyId === key.id ? (
                                      <Row>
                                        <Col>
                                          <Row>
                                            <Col>
                                              <Form.Item
                                                validateTrigger={['onChange', 'onBlur']}
                                                key={`radio`}
                                              >
                                                <Text>Type of choice: </Text>
                                                <Radio.Group onChange={(e) => onChangeRadio(e)} value={value}>
                                                  <Radio value={1}>Text</Radio>
                                                  <Radio value={2}>Image</Radio>
                                                </Radio.Group>
                                              </Form.Item>
                                            </Col>
                                            <Col style={{ justifyContent: 'center', alignContent: 'center' }}>
                                              <Typography.Link
                                                onClick={() => {
                                                  setAddChoiceDD({ adding: false, keyId: null });
                                                }}
                                              >
                                                Cancel
                                              </Typography.Link>
                                            </Col>
                                          </Row>
                                        </Col>
                                        {value === 2 ? (
                                          <Form.Item
                                            name='upload'
                                            label={'Choice '}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Please attach an image.',
                                              },
                                            ]}
                                            style={{
                                              width: '80%',
                                            }}
                                          >
                                            <Upload
                                              listType="picture-card"
                                              {...props}
                                              maxCount={1}
                                              name="upload"
                                              style={{ width: '32px', height: '32px' }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please attach an image.',
                                                },
                                              ]}
                                            >
                                              <div>
                                                <PlusOutlined />
                                                <div
                                                  style={{
                                                    marginTop: 8,
                                                  }}
                                                >
                                                  Upload
                                                </div>
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        ) : (
                                          <Form.Item
                                            name='answer'
                                            label={'Choice '}
                                            rules={[
                                              {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input text",
                                              },
                                            ]}
                                            style={{
                                              width: '100%',
                                            }}
                                          >
                                            <Input
                                              style={{
                                                width: '100%',
                                              }}
                                            />
                                          </Form.Item>
                                        )}
                                        <Button
                                          type="primary"
                                          icon={<PlusOutlined />}
                                          style={{ float: 'right' }}
                                          onClick={() => addChoices()}
                                        >Submit</Button>
                                      </Row>
                                    ) : null}
                                  </Form>
                                </Card>
                                <br></br>
                              </>
                            )
                          })}
                        </>
                      },
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                      expanded ? (
                        <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                      ) : (
                        <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                      )
                    }
                    rowKey="questionId"
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    pagination={{
                      // paginationSize: 10,
                      onChange: cancel,
                    }}
                    style={{ minWidth: '600px', maxWidth: '600px' }}
                  />
                </ViewActivity>
              )}
              {activityType === 'Performance Task' && <PerformanceTaskView searchViewDataSource={searchViewDataSource} />}
              <ActivityAdd
                parentMenuTitle={parentMenuTitle}
                childrenVisible={childrenVisible}
                addModuleQuestionClose={addModuleQuestionClose}
                visible={childrenVisible}
                activityType={activityType}
                moduleId={moduleId}
                viewActivity={viewActivity}
                viewActivityModule={viewActivityModule}
              />
              <ActivityEdit
                parentMenuTitle={parentMenuTitle}
                childrenVisible={editDrawer}
                onModuleEditDirectionClose={onModuleEditDirectionClose}
                visible={editDrawer}
                activityType={activityType}
                moduleId={moduleId}
                viewActivityModule={viewActivityModule}
                searchViewDataSource={searchViewDataSource}
                onEdit={onEdit}
                editRequest={editRequest}
                editMessage={editMessage}
                isEditLoading={isEditLoading}
                editModalVisible={editModalVisible}
                handleEditOk={handleEditOk}
              />
            </Drawer>
          </Form>
          <CustomModal
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
          >
            <Text>{message?.message}</Text>
          </CustomModal>
          <CustomModal
            isModalVisible={deleteModuleModalVisible}
            handleOk={handleOkChoice}
            handleCancel={handleCancelChoice}
            title="Remove"
          >
            <Text>Do you want to remove this ?</Text>
          </CustomModal>
          <CustomModal
            isModalVisible={deleteModuleQuestionModalVisible}
            handleOk={handleOkQuestion}
            handleCancel={handleCancelQuestion}
            title="Remove"
          >
            <Text>Do you want to remove this ?</Text>
          </CustomModal>
        </Card >
      )
      }
    </>
  );
}

export default Activity;