import { Card, Row, Col } from 'antd';
import '../../../assets/resources/style/activityType.css'

const HoverableCardType = ({ logo, title, description, onClick }) => (
    <div
        onClick={onClick}
    >
        <Row
            style={{
                marginBottom: 10,
            }}
            className='row-card'
        >
            <div
                style={{
                    width: 150,
                    height: 150,
                }}
                className='card-image-container'
            >
                <img
                    src={logo}
                    className="card-image"
                ></img>
            </div>
            <Card
                size="small"
                title={title}
                headStyle={{ fontFamily: 'Maven Pro' }}
                style={{
                    width: 220,
                    height: 150,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                    borderWidth: 2
                }}
                bodyStyle={{ width: '100%' }}
            >
                <Row>
                    <Col
                        xs={24}
                        xl={24}
                        style={{ fontSize: 14, fontFamily: 'Maven Pro' }}
                    >
                        {description}
                    </Col>
                </Row>
            </Card>
        </Row>
    </div>
);

export default HoverableCardType;