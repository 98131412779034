import { Form, Row, Col, Input, Select, Typography, Button, Cascader, Card } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import useRequest from '../../../hooks/use-request'
import Modal from '../../Layout/Modal/Modal'
import CustomSpinnerModal from '../../Layout/Modal/Spinner'
import API_CALL from "../../../helpers/api";
import { AuthContext } from '../../../store/use-context'
const { Option } = Select
const { Text } = Typography
const { SHOW_CHILD } = Cascader;

let TARGET_OPTION;

const Add = () => {
    const [subject, setSubject] = useState([]);
    const [profile, setProfile] = useState(null);
    const [subjectId, setSubjectId] = useState([]);
    const [school, setSchool] = useState([]);
    const [schoolId, setSchoolId] = useState([]);
    const [teacher, setTeacher] = useState([]);
    const [subjectTeacher, setSubjectTeacher] = useState([]);
    const [options, setOptions] = useState(null);
    const [message, setMessage] = useState(null);
    const [isTeacherLoading, setIsTeacherLoading] = useState(true);
    const [isSchoolLoading, setIsSchoolLoading] = useState(true);
    const [isSubjectLoading, setIsSubjectLoading] = useState(true);
    const [isSubjectTeacherLoading, setIsSubjectTeacherLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);
    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addSection`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSchools = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSchools`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                console.log(e)
                return e.status === 0;
            })
            setSchool(sortActive);
            setIsSchoolLoading(false);
        },
        (err) => {
            setIsSchoolLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getUsers = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/getUsers`,
        },
        (data) => {
            let teachers = data.filter((e) => {
                return e.userType === "4" && e.status === 0;
            })
            setTeacher(teachers)
            setMessage(data);
            setIsTeacherLoading(false);
        },
        (err) => {
            setIsTeacherLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getTeachers = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/getTeachers`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setTeacher(sortActive)
            setIsTeacherLoading(false);
        },
        (err) => {
            setIsTeacherLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSubjects = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjects`,
        },
        (data) => {
            const filteredData = data.filter((subject) => {
                return subject.status === 0
            })
            setSubject(filteredData);
            setOptions(filteredData.map((e) => {
                return {
                    label: e.name,
                    value: e.subjectId,
                    isLeaf: false,
                }
            }));
            setMessage(data);
            setIsSubjectLoading(false);
        },
        (err) => {
            setIsSubjectLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getProfile = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "2" && 'admin-school')}/profile/getProfile`,
        },
        (data) => {
            setProfile(data);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const getSubjectTeachers = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjectTeachers`,
        },
        (data) => {
            console.log(data);
            setSubjectTeacher(data)
            setMessage(data);
            setIsSubjectTeacherLoading(false);
        },
        (err) => {
            setIsSubjectTeacherLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => {
        setIsModalVisible(false);
    }

    useEffect(() => {
        if (school.length === 0) {
            { authContext.userType === '0' && getSchools.sendRequest(); }
            { authContext.userType === '1' && getSchools.sendRequest(); }
        }
        if (teacher.length === 0) {
            { authContext.userType === '0' && getUsers.sendRequest(); }
            { authContext.userType !== '0' && getTeachers.sendRequest(); }
        }
        { authContext.userType === '2' && getProfile.sendRequest(); }
        getSubjects.sendRequest();
        if (subjectTeacher && TARGET_OPTION !== undefined) {
            setTimeout(() => {
                TARGET_OPTION.loading = false;
                TARGET_OPTION.children = subjectTeacher.map((e) => {
                    return {
                        label: [e?.firstName, e?.middleName, e?.lastName].join(" "),
                        value: e?.teacherId
                    }
                })
                setOptions([...options]);
            }, 5000);
        }
    }, [subjectTeacher])

    if (isTeacherLoading) {
        return <CustomSpinnerModal isLoading={isTeacherLoading} />
    }

    if (isSubjectLoading) {
        return <CustomSpinnerModal isLoading={isSubjectLoading} />
    }

    if (isSubjectTeacherLoading) {
        return <CustomSpinnerModal isLoading={isSubjectTeacherLoading} />
    }

    const onChange = (value) => {
        setSubjectId(value)
    };

    const onSchoolChange = (value) => {
        setSchoolId(value)
    }

    const onSubjectChange = (value) => {
    }

    const onFinish = (values) => {
        console.log(values);
        let subjects = [];
        for (let index = 0; index < values.subjects.length; index++) {
            subjects.push({
                subjectId: values.subjects[index][0],
                teacherId: values.subjects[index][1]
            })
        }
        addRequest.sendRequest({
            schoolId: values.schoolId,
            gradeLevel: values.gradeLevel,
            name: values.name,
            adviserId: values.adviserId,
            subjects: JSON.stringify(subjects)
        }, null);
    }

    const optionLists = (
        subject.map((e) => {
            return {
                label: e.name,
                value: e.subjectId,
            }
        })
    )

    const loadData = async (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        TARGET_OPTION = targetOption
        targetOption.loading = true;
        await getSubjectTeachers.sendRequest(null, { subjectId: selectedOptions[0].value, schoolId })
    };

    return (
        <Card
            title={
                <Link to="/section">
                    <Button type='primary'>
                        {"< Back to Section"}
                    </Button>
                </Link>
            }
        >
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    prefix: '63',
                    schoolId: profile?.schoolId
                }}
                scrollToFirstError
            >
                <Row className='row'>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="name"
                            label="Section Name"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your section name`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="gradeLevel"
                            label="Grade Level"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please select grade level`,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select grade"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                <Option value={1}>Grade 1</Option>
                                <Option value={2}>Grade 2</Option>
                                <Option value={3}>Grade 3</Option>
                                <Option value={4}>Grade 4</Option>
                                <Option value={5}>Grade 5</Option>
                                <Option value={6}>Grade 6</Option>
                                <Option value={7}>Grade 7</Option>
                                <Option value={8}>Grade 8</Option>
                                <Option value={9}>Grade 9</Option>
                                <Option value={10}>Grade 10</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='row'>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name={"schoolId"}
                            label={"School"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Select
                                showSearch
                                placeholder="Select school"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                onChange={onSchoolChange}
                            >
                                {profile ? <Option value={profile?.schoolId}>{profile?.name}</Option> : null}
                                {school.map((obj, index) => {
                                    return (
                                        <Option key={index} value={obj.schoolId}>{obj.name}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name={"adviserId"}
                            label={"Adviser"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Select
                                showSearch
                                placeholder="Select adviser"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {teacher.map((obj, index) => {
                                    return (
                                        <Option key={index} value={obj.accountId}>{obj.lastName + ', ' + obj.firstName}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            name="subjects"
                            label="Subjects"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Cascader
                                style={{
                                    width: '100%',
                                }}
                                options={options}
                                onChange={onChange}
                                multiple
                                showCheckedStrategy={SHOW_CHILD}
                                loadData={loadData}
                                changeOnSelect
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='button'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
                <Modal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </Modal>
                {/* <CustomSpinnerModal isLoading={isLoading} /> */}
            </Form>
        </Card>
    )
}

export default Add