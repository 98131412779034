import { Row, Col, Form, Button, Input, Checkbox, TimePicker, InputNumber, Upload, Select } from 'antd';
import { PlusCircleOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import moment from 'moment';
import promise from '../../../../helpers/promise';
import '../../../../assets/resources/style/activityForm.css'
const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select
const types = ["video/mp4"];

const QuestionForm = ({
    module,
    onModuleBackHandler,
    onSaveAnswerChange,
    onScoreValidationChange,
    instruction,
    time,
    retryLimit,
    scoreValidation,
    isSaveAnswers,
    lecture,
    viewEdit,
    form,
    onFinish,
    onEdit,
    onSubmit,
    isMaze = false,
    groupings = false,
    isGroup,
    children,
}) => {
    const format = 'mm:ss';
    const [message, setMessage] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [uploadedImage, setUploadedImage] = useState();

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    const editQuestion = (values) => {
        return promise(1000, 'resolve', onEdit(values))
    }

    const getActivity = () => {
        return promise(1000, 'resolve', onSubmit());
    }

    const normImage = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        if (types.includes(e.fileList[0].type)) {
            e.fileList.shift();
        }
        return e?.fileList;
    };

    const propsImage = {
        name: '.jpg',
        accept: ".jpg",
        multiple: false,
        beforeUpload: (file) => {
            if (types.includes(file.type)) {
                setMessage({ message: 'File is MP4' })
                setIsModalVisible(true);
            }
            return false
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
                setUploadedImage(info.file);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            {!viewEdit && <Row>
                <Col>
                    <Button type='primary' onClick={onModuleBackHandler}>{`<`}Back to Module</Button>
                </Col>
            </Row>
            }
            <br></br>
            <Form
                name="dynamic_form_item"
                onFinish={viewEdit === true ? async (values) => {
                    await editQuestion({ values })
                    await getActivity()
                } : (values) => {
                    form.setFieldsValue({ image: uploadedImage })
                    onFinish(values)
                }}
                initialValues={{
                    instruction,
                    retryLimit,
                    lecture: lecture?.lecture
                }}
                form={form}
            >
                <Form.Item
                    label="Instruction"
                    name="instruction"
                    rules={[
                        {
                            required: true,
                            message: 'Instruction is required'
                        },
                    ]}
                >
                    <Input
                        placeholder="Instruction"
                        style={{
                            width: '90%',
                        }}
                    />
                </Form.Item>
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item name="time" label="Time" {...config}
                            initialValue={moment(time ? time : '00:00', 'mm:ss')}
                        >
                            <TimePicker
                                style={{
                                    width: '90%',
                                }}
                                format={format}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            label="Retry Limit"
                            name="retryLimit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Retry limit is required'
                                },
                            ]}
                        >
                            <InputNumber min={1} max={10} style={{
                                width: '90%',
                            }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                        <Row>
                            <Col xs={24} md={24} lg={12}>
                                <Form.Item
                                    label="Score Validation"
                                    name="scoreValidation"
                                    tooltip="(If checked) Teacher will validate the score"
                                >
                                    <Checkbox
                                        name='scoreValidation'
                                        style={{ float: 'left' }}
                                        checked={scoreValidation}
                                        onChange={(e) => {
                                            onScoreValidationChange(e.target.checked)
                                        }}
                                    >
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                                <Form.Item
                                    label="Save Answer"
                                    name="isSaveAnswer"
                                >
                                    <Checkbox
                                        name='isSaveAnswers'
                                        style={{ float: 'left' }}
                                        checked={isSaveAnswers}
                                        onChange={(e) => {
                                            onSaveAnswerChange(e.target.checked)
                                        }}
                                    >
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    {isMaze ? <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            label="Maze Size"
                            name="mazeSize"
                            rules={[
                                {
                                    required: true,
                                    message: 'Maze size is required'
                                },
                            ]}
                            initialValue={5}
                        >
                            <InputNumber
                                min={5}
                                max={15}
                                style={{
                                    width: '90%',
                                }}
                            />
                        </Form.Item>
                    </Col> : null}
                    {groupings ? <Col xs={24} md={12} lg={8}>
                        <Form.Item
                            label="Student Performance"
                            name="isGroup"
                            initialValue={isGroup ? isGroup : 0}
                        >
                            <Select
                                placeholder="Select a student performance"
                            >
                                <Option value={0}>INDIVIDUAL</Option>
                                <Option value={1}>GROUP</Option>
                            </Select>
                        </Form.Item>
                    </Col> : null}
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            label="Lecture"
                            name="lecture"
                        >
                            <TextArea
                                rows={5}
                                placeholder="Lecture..."
                                style={{
                                    width: '90%',
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={24} md={24} lg={24}>
                        <p>Image: </p>
                        <Form.Item name="image">
                            <Form.Item
                                name="image"
                                label="Upload Image: "
                                valuePropName="fileListImage"
                                getValueFromEvent={normImage}
                                noStyle
                                multiple="false"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Please attach a video."
                            //     },
                            // ]}
                            >
                                <Dragger
                                    {...propsImage}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                        band files
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row> */}
                {children}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form >
        </>
    )
}

export default QuestionForm