import { Row, Col, Button } from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../../store/use-context";
import HoverableCardType from '../../Layout/HoverableCard/HoverableCardType'
import useRequest from "../../../hooks/use-request";
import GEAR_UP from '../../../assets/resources/Images/gear_up.png';
import CRANK_UP from '../../../assets/resources/Images/crank_up.png';
import SPARK_UP from '../../../assets/resources/Images/spark_up.png';
import TUNE_UP from '../../../assets/resources/Images/tune_up.png';
import CATCH_UP from '../../../assets/resources/Images/catch_up.png';
import OPEN_UP from '../../../assets/resources/Images/open_up.png';
import STEP_UP from '../../../assets/resources/Images/step_up.png';
import PUT_UP from '../../../assets/resources/Images/put_up.png';
import WIND_UP from '../../../assets/resources/Images/wind_up.png';

const ActivityModule = ({ onClickModule, activityType, onModuleBack }) => {
    const authContext = useContext(AuthContext);
    const [moduleList, setModuleList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState(null);

    const getModule = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'data') ||
                (authContext.userType === "3" && 'data')
                }/getActivity`,
        },
        (data) => {
            setIsLoading(false);
            setIsVisible(false);
            setMessage(null);
            setModuleList(data);
        },
        (err) => {
            setIsLoading(false);
            setIsVisible(true);
            setMessage(err.response.data)
        }
    )

    useEffect(() => {
        getModule.sendRequest();
    }, [])
    // const moduleList = [
    //     {
    //         id: '0',
    //         title: 'Whack-A-Mole',
    //         message: 'The letters of the english alphabet.',
    //         // image: GEAR_UP
    //     },
    //     {
    //         id: '1',
    //         title: 'Matching Type',
    //         message: 'Pick and crack the egg that has a big and small letter of the alphabet.',
    //         // image: CRANK_UP
    //     },
    //     {
    //         id: '2',
    //         title: 'Quiz Show',
    //         message: 'Pop each colorful balloon and find out if the letters inside are written correctly in uppercase and lowercase.',
    //         // image: SPARK_UP
    //     },
    //     {
    //         id: '3',
    //         title: 'True or False',
    //         message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
    //         // image: TUNE_UP
    //     },
    //     {
    //         id: '4',
    //         title: 'Word Sentence Completion',
    //         message: 'Tell the beginning letter of each picture.',
    //         // image: CATCH_UP
    //     },
    //     {
    //         id: '5',
    //         title: 'Fill in the blanks',
    //         message: 'Tap the smiley face if the statement that you will hear is true and tap the sad face if it is incorrect.',
    //         // image: STEP_UP
    //     },
    //     {
    //         id: '6',
    //         title: 'Open-the-Box',
    //         message: 'Tell the beginning letter of each picture.',
    //         // image: PUT_UP
    //     },
    //     {
    //         id: '7',
    //         title: 'Powtoon',
    //         message: 'Do your best to catch the letters of the alphabet.',
    //         // image: WIND_UP
    //     },
    //     {
    //         id: '8',
    //         title: 'Spin-A-Wheel',
    //         message: 'Do your best to catch the letters of the alphabet.',
    //         // image: WIND_UP
    //     },
    //     {
    //         id: '9',
    //         title: 'Multiple-Choice',
    //         message: 'Do your best to catch the letters of the alphabet.',
    //         // image: WIND_UP
    //     },
    //     {
    //         id: '10',
    //         title: 'Maze Chase',
    //         message: 'Do your best to catch the letters of the alphabet.',
    //         // image: WIND_UP
    //     }
    // ];

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={onModuleBack}>{`<`}Back to Activity</Button>
            </Row>
            <Row>
                {moduleList.filter((module, index) => {
                    if (activityType === 'GEAR UP') {
                        return module.name === 'Powtoon'
                    }
                    else if (activityType === "PERFORMANCE TASK") {
                        return module.name !== 'Powtoon'
                    }
                    else if (activityType !== "PERFORMANCE TASK" || activityType !== 'GEAR UP') {
                        return module.name !== "Performance Task" && module.name !== 'Powtoon'
                    } else {
                        return module.name !== 'Powtoon'
                    }
                }).map((module, index) => {
                    return (
                        <Col
                            key={index}
                            xs={24}
                            md={16}
                            lg={12}
                            xl={8}
                        >
                            <HoverableCardType
                                logo={module.image}
                                title={module.name}
                                description={module.description}
                                onClick={() => {
                                    onClickModule(module.name.toUpperCase(), module.activityId)
                                }}
                            />
                        </Col>
                    )
                })}
            </Row>
        </>
    )
}

export default ActivityModule