import { Form, Row, Col, Input, Select, Typography, Button, Card, Upload, message as ErrorMessage } from 'antd'
import React, { useState, useEffect, useContext } from 'react'
import {
    PlusOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom'
import useRequest from '../../../hooks/use-request'
import Modal from '../../Layout/Modal/Modal'
import CustomSpinnerModal from '../../Layout/Modal/Spinner'
import { AuthContext } from '../../../store/use-context'
const { Option } = Select
const { Text } = Typography
const { TextArea } = Input

const props = {
    multiple: false,
    beforeUpload: (file) => {
        const isLt2M = file.size / 300 / 300 < 2;
        if (!isLt2M) {
            console.log('test')
        }
        return false;
    },
    onChange(info) {
        if (info.file.status !== "removed") {
            let reader = new FileReader();
            reader.readAsDataURL(info.file);
        }
    },
};

const AddLibrary = () => {
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [subject, setSubject] = useState([]);
    const [isLoadingActivity, setIsLoadingActivity] = useState(true);
    const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/addFileLibrary`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const getSubject = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSubjectsPerGrade`
        },
        (data) => {
            const filteredData = data.filter((subject) => {
                return subject.status === 0
            })
            setSubject(filteredData)
            setIsLoadingActivity(false);
        },
        (err) => {
            setIsLoadingActivity(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const gradeLevelOnChange = (value) => {
        if (value === "") {
            setSubject([]);
            setSelectedSubjectFilter(null);
            form.setFieldsValue({ subjectId: "" });
        } else {
            setIsLoadingActivity(true)
            setSelectedSubjectFilter(null);
            getSubject.sendRequest(null, { gradeLevel: value })
            form.setFieldsValue({ subjectId: "" });
        }
    }

    const onFinish = (values) => {
        let formData = new FormData();
        formData.append("title", values.title ? values.title : '');
        formData.append("subjectId", form.getFieldValue("subjectId") ? form.getFieldValue("subjectId") : "");
        formData.append("credits", values.credits ? values.credits : '');
        formData.append("reference", values.reference ? values.reference : '');
        formData.append("description", values.description ? values.description : '');
        formData.append("file", values.file?.fileList?.[0] === undefined ? '' : values.file?.fileList[0].originFileObj);
        if (form.getFieldValue("gradeLevelFilter") !== "") {
            if (form.getFieldValue("subjectId") !== "") {
                setIsLoading(true)
                addRequest.sendRequest(formData, null);
            } else {
                setIsLoading(false)
                ErrorMessage.error("Please select a subject")
            }
        } else {
            setIsLoading(true)
            addRequest.sendRequest(formData, null);
        }
        // setIsLoading(true)
    }

    console.log(isLoading)

    return (
        <Card
            title={
                <Link to='/library'>
                    <Button type='primary'>{"< Back to Library"}</Button>
                </Link>
            }
        >
            <CustomSpinnerModal isLoading={isLoading} />
            <Form
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    prefix: '63',
                }}
                scrollToFirstError
            >
                <Row className='row'>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="title"
                            label="Title"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your title`,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="gradeLevelFilter"
                            label="Grade Level"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            initialValue={""}
                        >
                            <Select
                                placeholder="Select a grade"
                                style={{ width: '100%' }}
                                onChange={gradeLevelOnChange}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Grade 1</Option>
                                <Option value="2">Grade 2</Option>
                                <Option value="3">Grade 3</Option>
                                <Option value="4">Grade 4</Option>
                                <Option value="5">Grade 5</Option>
                                <Option value="6">Grade 6</Option>
                                <Option value="7">Grade 7</Option>
                                <Option value="8">Grade 8</Option>
                                <Option value="9">Grade 9</Option>
                                <Option value="10">Grade 10</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="subjectId"
                            label="Subject"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            initialValue={""}
                        >
                            <Select
                                placeholder="Select a subject"
                                style={{ width: '100%' }}
                                loading={isLoadingActivity}
                            >
                                <Option value="" disabled>Select a subject</Option>
                                {subject?.map((sub, i) => (
                                    <Option key={i} value={sub.subjectId}>{sub.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="description"
                            label="Description"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please input your description`,
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="credits"
                            label="Credits"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="reference"
                            label="Reference"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                        <Form.Item
                            name="file"
                            label="File"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: 5 }}
                        >
                            <Upload
                                listType="picture-card"
                                {...props}
                                maxCount={1}
                                name="upload"
                                style={{ width: '32px', height: '32px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please attach an file.',
                                    },
                                ]}
                            >
                                <div>
                                    <PlusOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                        Upload
                                    </div>
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='button'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
                <Modal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleOk}
                    title={message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message === 'Added successfully' || message?.message === 'Added successfully.' ? 'Added Successfully.' : 'Please contact support.'}</Text>
                </Modal>
                <CustomSpinnerModal isLoading={isLoading} />
            </Form>
        </Card>
    )
}

export default AddLibrary