import React, { useEffect, useState } from 'react'
import useRequest from '../../../hooks/use-request';
import CustomSpinnerModal from '../../Layout/Modal/Spinner';
import { Col, Layout, Row, Select, Space, Typography } from 'antd'
import SubjectCard from '../../Layout/HoverableCard/HoverableCard';
import SubjectAP from '../../../assets/resources/Images/subject_ap.png';
import SubjectMATH from '../../../assets/resources/Images/subject_math.png';
import SubjectSCIENCE from '../../../assets/resources/Images/subject_science.png';
import SubjectENGLISH from '../../../assets/resources/Images/subject_english.png';
import SubjectPE from '../../../assets/resources/Images/subject_pe.png';
import '../../../assets/resources/style/activitySubject.css'
import { Link, useNavigate } from 'react-router-dom';
const { Option } = Select;
const { Content } = Layout;
const { Text } = Typography;

const ActivitySubject = ({ onChangeHandler, onClickHandler, gradeLevel, subject }) => {

    const capitalize = (word) => {
        const lower = word.toLowerCase();
        return word.charAt(0).toUpperCase() + lower.slice(1);
    }

    return (
        <Content>
            <Row className='row'>
                {/* <Col className="select-dropdown"> */}
                <Space>
                    <Text>Select Grade Level: </Text>
                    <Select
                        showSearch
                        placeholder="Select a Grade Level"
                        optionFilterProp="children"
                        value={gradeLevel}
                        onChange={onChangeHandler}
                        style={{
                            width: '500px',
                            float: 'right'
                        }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        <Option value="1">Grade 1</Option>
                        <Option value="2">Grade 2</Option>
                        <Option value="3">Grade 3</Option>
                        <Option value="4">Grade 4</Option>
                        <Option value="5">Grade 5</Option>
                        <Option value="6">Grade 6</Option>
                        <Option value="7">Grade 7</Option>
                        <Option value="8">Grade 8</Option>
                        <Option value="9">Grade 9</Option>
                        <Option value="10">Grade 10</Option>
                    </Select>
                </Space>
                {/* </Col> */}
            </Row>
            <br></br>
            <Row className='row'>
                {subject.map((obj, index) => {
                    return (
                        <Col key={index} xs={16} md={10} lg={6}>
                            <SubjectCard
                                logo={
                                    (obj.name.includes('Math') && SubjectMATH) ||
                                    (obj.name.includes('English') && SubjectENGLISH) ||
                                    (obj.name.includes('Araling') && SubjectAP) ||
                                    (obj.name.includes('Physical') && SubjectPE) ||
                                    (obj.name.includes('Science') && SubjectSCIENCE)
                                }
                                title={capitalize(obj.name)}
                                description={obj.description}
                                onClick={() => { onClickHandler(obj.subjectId, obj.name) }}
                            />
                        </Col>
                    )
                })}
            </Row>
        </Content >
    )
}

export default ActivitySubject