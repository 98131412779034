import { Tabs, Card } from 'antd';
import React, { useEffect } from 'react';
import ArchiveActivity from './ArchiveActivity/ArchiveActivity';
import ArchiveSchool from './ArchiveSchool/ArchiveSchool';
import ArchiveSection from './ArchiveSection/ArchiveSection';
import ArchiveSubject from './ArchiveSubject/ArchiveSubject';
import ArchiveUser from './ArchiveUsers/ArchiveUser';
const { TabPane } = Tabs;

const onChange = (key) => {
    console.log(key);
};

const Archive = () => {
    return (
        <Card
            title="Archived"
        >
            <Tabs defaultActiveKey="1" onChange={onChange}>
                <TabPane tab="Users" key="1">
                    <ArchiveUser />
                </TabPane>
                <TabPane tab="Activity" key="2">
                    <ArchiveActivity />
                </TabPane>
                <TabPane tab="School" key="3">
                    <ArchiveSchool />
                </TabPane>
                <TabPane tab="Section" key="4">
                    <ArchiveSection />
                </TabPane>
                <TabPane tab="Subject" key="5">
                    <ArchiveSubject />
                </TabPane>
            </Tabs>
        </Card>
    )
}

export default Archive