import React, { useEffect, useState, useContext } from "react";
import {
    Button,
    Tag,
    Dropdown,
    Menu,
    Space,
    Row,
    Col,
    Layout,
    Card,
    Drawer,
    Collapse,
    Image,
    Typography,
    Select,
    Input,
    Popconfirm,
    Form,
    Radio,
    Upload,
    TimePicker,
} from "antd";
import { Colors } from "../../../../assets/Constants/Colors";
import '../../../../assets/resources/style/user.css';
import {
    DownOutlined,
    UserOutlined,
    EditOutlined,
    FileExcelOutlined,
    SearchOutlined,
    FundViewOutlined,
    CloseSquareOutlined,
    CheckSquareTwoTone,
    PlusCircleTwoTone,
    MinusCircleTwoTone,
    DeleteOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { ExportTableButton, SearchTableInput, Table } from "ant-table-extensions";
import useRequest from "../../../../hooks/use-request";
import { AuthContext } from "../../../../store/use-context";
import PowtoonView from "../../Activity/Forms/Powtoon/PowtoonView";
import ViewActivity from "../../Activity/ViewActivity";
import CustomSpinnerModal from "../../../Layout/Modal/Spinner";
import CustomModal from "../../../Layout/Modal/Modal";
import FIBQuestion from "../../Activity/Forms/FillInTheBlanks/FIBQuestion";
import moment from "moment";
import ActivityAdd from "../../Activity/ActivityAdd";
import ActivityEdit from "../../Activity/ActivityEdit";
import convertMiliseconds from '../../../../helpers/convertMiliseconds'
import convertMilisecondsTime from '../../../../helpers/convertMillisecondsTime'
import { Link } from "react-router-dom";
const { Text } = Typography
const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;
function ArchiveActivity() {
    const authContext = useContext(AuthContext);
    const [activity, setActivity] = useState(null);
    const [searchDataSource, setSearchDataSource] = useState(activity);
    const [viewActivity, setViewActivity] = useState(null);
    const [searchViewDataSource, setSearchViewDataSource] = useState(viewActivity);
    const [isLoading, setIsLoading] = useState(null);
    const [isLoadingActivity, setIsLoadingActivity] = useState(true);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const [editMessage, setEditMessage] = useState(null);
    const [visible, setVisible] = useState(false);
    const [parentMenuTitle, setParentMenuTitle] = useState(null);
    const [editDrawer, setEditDrawer] = useState(false);
    const [childrenVisible, setChildrenVisible] = useState(false);
    const [activityType, setActivityType] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    const [allSubject, setAllSubject] = useState(null);
    const [allTopic, setAllTopic] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState("");
    const [deleteModuleModalVisible, setDeleteModuleModalVisible] = useState(false)
    const [deleteModuleQuestionModalVisible, setDeleteModuleQuestionModalVisible] = useState(false)
    const [choiceId, setChoiceId] = useState(null)
    const [questionId, setQuestionId] = useState(null);
    const [editingKeyQuestion, setEditingKeyQuestion] = useState('');
    // const [choices, setChoices] = useState(false);
    const [editingKeyChoices, setEditingKeyChoices] = useState({ editable: null, choiceId: null, description: null });
    const [choices, setChoices] = useState(false);
    const [value, setValue] = useState(1);
    const [fileList, setFileList] = useState([
        {
            uid: null,
            name: null,
            status: null,
            url: null,
        },
    ]);
    const [form] = Form.useForm();

    const getActivity = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/getModules`
        },
        (data) => {
            const changeName = data.sort((a, b) => {
                return a.status - b.status;
            }).map((e) => {
                return ((e.activityType === 'FITB' && { ...e, activityType: "Fill in the Blanks" }) ||
                    (e.activityType === 'WAM' && { ...e, activityType: "Whack A Mole" }) ||
                    (e.activityType === 'MC' && { ...e, activityType: "Multiple Choice" }) ||
                    (e.activityType === 'WSC' && { ...e, activityType: "Word Sentence Completion" }) ||
                    (e.activityType === 'TF' && { ...e, activityType: "True or False" }) ||
                    (e.activityType === 'OTB' && { ...e, activityType: "Open The Box" }) ||
                    (e.activityType === 'MT' && { ...e, activityType: "Matching Type" }) ||
                    (e.activityType === 'POWTOON' && { ...e, activityType: "POWTOON" }) ||
                    (e.activityType === 'SAW' && { ...e, activityType: "Spin A Wheel" }) ||
                    (e.activityType === 'Maze' && { ...e, activityType: "Maze Chase" }) ||
                    (e.activityType === 'QuizShow' && { ...e, activityType: "QuizShow" }))
            }).filter((e) => {
                return e.status === 1;
            })
            console.log(changeName);
            setSearchDataSource(changeName);
            setActivity(changeName);
            setIsLoadingActivity(false);
        },
        (err) => {
            setIsLoadingActivity(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const viewActivityModule = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/getModule`
        },
        (data) => {
            setSearchViewDataSource(data);
            setViewActivity(data);
            setIsLoading(false);
            setVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const editRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }{(activityType === 'POWTOON' && 'editModulePowtoon') ||
                (activityType === 'Fill in the Blanks' && 'editModuleFITB') ||
                (activityType === 'Word Sentence Completion' && 'editModuleWSC') ||
                (activityType === 'QuizShow' && 'editModuleQuizShow') ||
                (activityType === 'Whack A Mole' && 'editModuleWAM') ||
                (activityType === 'Spin A Wheel' && 'editModuleSAW') ||
                (activityType === 'Matching Type' && 'editModuleMT') ||
                (activityType === 'Multiple Choice' && 'editModuleMC') ||
                (activityType === 'True or False' && 'editModuleTF') ||
                (activityType === 'Open The Box' && 'editModuleOTB')
                }`,
        },
        (data) => {
            setEditMessage(data);
            setEditModalVisible(true);
            setIsEditLoading(false);
        },
        (err) => {
            setIsEditLoading(false);
            setEditModalVisible(true);
            setEditMessage(err.response.data)
        }
    )

    const editChoicesRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }{(activityType === 'POWTOON' && 'editModulePowtoon') ||
                (activityType === 'Word Sentence Completion' && 'editModuleWSCChoices') ||
                (activityType === 'QuizShow' && 'editModuleQuizShowChoices') ||
                (activityType === 'Whack A Mole' && 'editModuleWAMChoice') ||
                (activityType === 'Spin A Wheel' && 'editModuleSAWChoices') ||
                (activityType === 'Matching Type' && 'editModuleMTChoices') ||
                (activityType === 'Multiple Choice' && 'editModuleMCChoices') ||
                (activityType === 'Open The Box' && 'editModuleOTBChoices')
                }`,
        },
        (data) => {
            viewActivityModule.sendRequest(null, { moduleId })
            setEditingKeyChoices({ editable: null, choiceId: null, description: null });
            form.setFieldsValue({ description: '' })
            setEditMessage(data);
            setEditModalVisible(true);
            setIsEditLoading(false);
        },
        (err) => {
            setIsEditLoading(false);
            setEditModalVisible(true);
            setEditMessage(err.response.data)
        }
    )

    const editQuestionRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }{(activityType === 'Word Sentence Completion' && 'editModuleWSCQuestion') ||
                (activityType === 'QuizShow' && 'editModuleQuizShowQuestion') ||
                (activityType === 'Spin A Wheel' && 'editModuleSAWQuestion') ||
                (activityType === 'Matching Type' && 'editModuleMTQuestion') ||
                (activityType === 'Multiple Choice' && 'editModuleMCQuestion') ||
                (activityType === 'Maze Chase' && 'editModuleMazeQuestion') ||
                (activityType === 'True or False' && 'editModuleTFQuestion') ||
                (activityType === 'Fill in the Blanks' && 'editModuleFITBQuestion') ||
                (activityType === 'Open The Box' && 'editModuleOTBQuestion')
                }`,
        },
        (data) => {
            viewActivityModule.sendRequest(null, { moduleId })
            setEditingKeyQuestion('')
            setEditMessage(data);
            setEditModalVisible(true);
            setIsEditLoading(false);
            setChoices(null);
        },
        (err) => {
            setIsEditLoading(false);
            setEditModalVisible(true);
            setEditMessage(err.response.data)
        }
    )

    const deleteModule = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/removeModule`
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            getActivity.sendRequest();
            setIsModalVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const deleteModuleQuestion = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/${(activityType === 'QuizShow' && 'removeModuleQuizShowQuestion')}`
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            viewActivityModule.sendRequest(null, { moduleId })
            setIsModalVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const deleteModuleChoice = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/${(activityType === 'QuizShow' && 'removeModuleQuizShowChoices') ||
                (activityType === 'WAM' && 'removeModuleWAMChoices')
                }`
        },
        (data) => {
            setMessage(data)
            setIsLoading(false);
            viewActivityModule.sendRequest(null, { moduleId })
            setIsModalVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const getAllSubject = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/data/getSubjects`
        },
        (data) => {
            const filterData = data.filter((e) => {
                return e.status !== 1
            })
            setAllSubject(filterData);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const getAllTopic = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/data/getTopics`
        },
        (data) => {
            const filterData = data.filter((e) => {
                return e.status !== 1
            })
            setAllTopic(filterData);
            setIsLoading(false);
            setIsModalVisible(true);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )

    const onClose = () => {
        setVisible(false);
    }

    const onViewTopic = async (activityType, type, moduleId) => {
        console.log(activityType)
        setIsLoading(true);
        await viewActivityModule.sendRequest(null, { moduleId })
        setParentMenuTitle(type);
        setActivityType(activityType)
        setModuleId(moduleId)
    }

    const addModuleQuestion = () => {
        setChildrenVisible(true);
    }

    const addModuleQuestionClose = () => {
        setChildrenVisible(false);
    }

    const onEdit = ({ values, scoreValidation, saveAnswer }) => {
        console.log('Received values of form:', values.values);
        editRequest.sendRequest({
            type: searchViewDataSource.type,
            directions: values.values.instruction,
            retryLimit: values.values.retryLimit,
            scoreValidation: scoreValidation === true ? 1 : 0,
            isSaveAnswers: saveAnswer === true ? 1 : 0,
            isUploadRequired: searchViewDataSource.isUploadRequired === 1 ? 1 : 0
        }, { moduleId })
        setIsEditLoading(true);
    }

    const onEditChoices = (data, choiceId) => {
        editChoicesRequest.sendRequest({
            ...data
        }, { choiceId })
        setIsEditLoading(true);
    }

    const onDeleteModule = (moduleId) => {
        setIsLoading(true);
        deleteModule.sendRequest(null, { moduleId })
    }

    const onDeleteModuleQuestion = (questionId) => {
        setIsLoading(true);
        deleteModuleQuestion.sendRequest(null, { questionId })
    }

    const handleOkQuestion = () => {
        onDeleteModuleQuestion(questionId);
    }

    const handleCancelQuestion = () => {
        setDeleteModuleQuestionModalVisible(false);
        setChoiceId(null);
    }

    const onDeleteModuleChoices = (choiceId) => {
        setDeleteModuleModalVisible(false);
        setIsLoading(true);
        deleteModuleChoice.sendRequest(null, { choiceId })
    }

    const handleOkChoice = () => {
        onDeleteModuleChoices(choiceId);
    }

    const handleCancelChoice = () => {
        setDeleteModuleModalVisible(false);
        setChoiceId(null);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const columns = [
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: '100px',
            render: (row) => (
                <Dropdown overlay={
                    <Menu
                        items={
                            [
                                {
                                    label: 'View',
                                    key: '0',
                                    icon: <FundViewOutlined />,
                                    onClick: () => {
                                        onViewTopic(row.activityType, row.type, row.moduleId);
                                    }
                                },
                            ]}
                    />
                }
                    trigger={['click']}
                >
                    <Button type="primary">
                        <Space>
                            Action
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: '100px',
            sorter: (a, b) => a.status - b.status,
            render: (tags, index) => (
                <span>
                    {
                        tags === 0 && (<Tag color={Colors.blue} key={index.id}>
                            {'ACTIVE'}
                        </Tag>) ||
                        tags === 1 && (<Tag color={Colors.grey} key={index.id}>
                            {'DELETED'}
                        </Tag>)
                    }
                </span>
            ),
        },
        {
            title: 'Subject Name',
            key: 'subjectName',
            dataIndex: 'subjectName',
            width: '200px',
            sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
        },
        {
            title: 'Topic Name',
            key: 'topicName',
            dataIndex: 'topicName',
            width: '200px',
            sorter: (a, b) => a.topicName.localeCompare(b.topicName),
        },
        {
            title: 'Module Type',
            key: 'type',
            dataIndex: 'type',
            width: '200px',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: 'Activity Type',
            key: 'activityType',
            dataIndex: 'activityType',
            width: '200px',
            sorter: (a, b) => a.activityType.localeCompare(b.activityType),
        },
        {
            title: 'Direction',
            key: 'directions',
            dataIndex: 'directions',
            width: '200px',
            sorter: (a, b) => a.directions.localeCompare(b.directions),
            ellipsis: true,
        },
        {
            title: 'Retry',
            key: 'retryLimit',
            dataIndex: 'retryLimit',
            width: '200px',
            sorter: (a, b) => a.retryLimit - b.retryLimit,
        },
        {
            title: 'Score Validation',
            key: 'scoreValidation',
            dataIndex: 'scoreValidation',
            width: '200px',
            sorter: (a, b) => a.scoreValidation - b.scoreValidation,
            render(row) {
                return row === 0 ? 'No' : 'Yes'
            }
        },
        {
            title: 'Save Answers',
            key: 'isSaveAnswers',
            dataIndex: 'isSaveAnswers',
            width: '200px',
            sorter: (a, b) => a.isSaveAnswers - b.isSaveAnswers,
            render(row) {
                return row === 0 ? 'No' : 'Yes'
            }
        },
        {
            title: 'Upload Required',
            key: 'isUploadRequired',
            dataIndex: 'isUploadRequired',
            width: '200px',
            sorter: (a, b) => a.isUploadRequired - b.isUploadRequired,
            render(row) {
                return row === 0 ? 'No' : 'Yes'
            }
        },
    ];

    const onAddQuestion = ({ activityType, moduleId }) => {
        console.log(activityType, moduleId)
        {
            activityType === 'FITB' && (
                <FIBQuestion
                    moduleId={moduleId}
                    module={'FILL IN THE BLANKS'}
                />
            )
        }
    }

    const uniqueByKey = (data, key) => {
        return [
            ...new Map(
                data.map(x => [key(x), x])
            ).values()
        ]
    }

    const onModuleEditDirection = () => {
        setEditDrawer(true);
    }

    const onModuleEditDirectionClose = () => {
        setEditDrawer(false);
    }

    const handleEditOk = () => {
        setEditModalVisible(false);
        setEditDrawer(false);
    }

    const cancelQuestion = () => {
        setEditingKeyQuestion('');
        form.setFieldsValue({ description: '' })
        setChoices(null);
    };

    const saveQuestion = async (questionId) => {
        const values = form.getFieldsValue();
        console.log(values);
        let formData = new FormData();
        formData.append("question", values?.question ? values.question : '');
        formData.append("image", values?.image?.file ? values.image.file : '');
        formData.append("timeLimit", values?.time ? moment.duration(moment(values.time._d).format('HH:mm:ss')).asMilliseconds() : '');
        formData.append("isTrue", choices === true ? 1 : 0);
        editQuestionRequest.sendRequest(formData, { questionId });
    }

    const removeQuestion = async (questionId) => {
        onDeleteModuleQuestion(questionId)
    }

    const cancelChoices = () => {
        setEditingKeyChoices({ editable: null, choiceId: null, description: null });
        form.setFieldsValue({ description: '' })
    };

    const saveChoices = async (choiceId) => {
        const values = form.getFieldsValue();
        console.log(values.upload);
        let formData = new FormData();
        formData.append("description", values?.description ? values.description : '');
        formData.append("image", values?.upload ? values.upload.file : '');
        formData.append("isCorrect", choices === true ? 1 : 0);
        editChoicesRequest.sendRequest(formData, { choiceId });
    }

    const removeChoices = async (choiceId) => {
        onDeleteModuleChoices(choiceId)
    }

    const checkBoxImage = (e, index) => {
        setChoices(e.target.checked);
    }

    const onChangeRadio = (e, index) => {
        setValue(e.target.value);
    }

    const props = {
        accept: ".png, .jpg, .jpeg, .gif, .tiff",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    useEffect(() => {
        getActivity.sendRequest();

        if (!allSubject) {
            getAllSubject.sendRequest();
        }
    }, []);

    const questionColumn = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'questionId',
            editable: true,
            width: "90%",
            render: (item, record, index) => {
                return item
            }
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            width: "10%",
            render: (_, record) => {
                const editable = isEditing(_, record);
                return editable ? (
                    <Space>
                        <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestion(record.questionId)}>
                            <a>Save</a>
                        </Popconfirm>
                        <Typography.Link
                            onClick={cancelQuestion}
                        >
                            Cancel
                        </Typography.Link>
                    </Space>
                ) : (
                    <Space>
                        <Typography.Link
                            className='update-profile__edit-button'
                            onClick={() => {
                                edit(record)
                            }}
                        >
                            Edit
                        </Typography.Link>
                        <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(record.questionId)}>
                            <a style={{ color: "#ff4d4f" }}>Remove</a>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ]

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = (inputType === 'text' && <Input />)
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.questionId === editingKeyQuestion;

    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({
            question: '',
            ...record,
        });
        setFileList({
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
            // url: 'http://' + window.location.hostname + ':9003/' + record.image,
        });
        setEditingKeyQuestion(record.questionId);
    };

    const cancel = () => {
        setEditingKeyQuestion('');
    };

    const mergedColumns = questionColumn.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'gradeLevel' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select time!',
            },
        ],
    };

    return (
        <>
            {(!allSubject && !activity) && <CustomSpinnerModal isLoading={true} />}
            {activity && (
                <>
                    <Row justify="space-between">
                        <Col span={7} xs={24} md={7} lg={7} xl={7}>
                            <ExportTableButton
                                dataSource={activity}
                                columns={columns}
                                btnProps={{ type: "primary", icon: <FileExcelOutlined /> }}
                                showColumnPicker
                            >
                                Export to CSV
                            </ExportTableButton>
                        </Col>
                        <Col span={7} offset={8} xs={16} md={7} lg={7} xl={7}>
                            <SearchTableInput
                                columns={columns}
                                dataSource={activity}
                                setDataSource={setSearchDataSource}
                                inputProps={{
                                    placeholder: "Search this table...",
                                    prefix: <SearchOutlined />,
                                }}
                            />
                        </Col>
                    </Row>
                    <Form form={form} component={false}>
                        <Table
                            dataSource={searchDataSource}
                            columns={[
                                {
                                    title: 'Action',
                                    dataIndex: '',
                                    key: 'x',
                                    width: '100px',
                                    fixed: 'left',
                                    render: (row) => (
                                        <Dropdown overlay={
                                            <Menu
                                                items={
                                                    [
                                                        {
                                                            label: 'View',
                                                            key: '0',
                                                            icon: <FundViewOutlined />,
                                                            onClick: () => {
                                                                onViewTopic(row.activityType, row.type, row.moduleId);
                                                            }
                                                        },
                                                    ]}
                                            />
                                        }
                                            trigger={['click']}
                                        >
                                            <Button type="primary">
                                                <Space>
                                                    Action
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    )
                                },
                                {
                                    title: 'Status',
                                    key: 'status',
                                    dataIndex: 'status',
                                    width: '100px',
                                    fixed: 'left',
                                    sorter: (a, b) => a.status.localeCompare(b.status),
                                    filters: uniqueByKey(activity.map((e) => {
                                        return (e.status === 0 && { ...e, status: "ACTIVE" }) ||
                                            (e.status === 1 && { ...e, status: "DEACTIVATED" })
                                    }), act => act.status).map((e) => ({
                                        text: e.status,
                                        value: e.status
                                    })),
                                    filterSearch: true,
                                    onFilter: (value, record) => record.status === 0 ? 'ACTIVE' === value : 'DEACTIVATED' === value,
                                    render: (tags, index) => (
                                        <span>
                                            {
                                                tags === 0 && (<Tag color={Colors.blue} key={index.id}>
                                                    {'ACTIVE'}
                                                </Tag>) ||
                                                tags === 1 && (<Tag color={Colors.grey} key={index.id}>
                                                    {'DELETED'}
                                                </Tag>)
                                            }
                                        </span>
                                    ),
                                },
                                {
                                    title: 'Subject Name',
                                    key: 'subjectName',
                                    dataIndex: 'subjectName',
                                    width: '200px',
                                    sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
                                    filters: uniqueByKey(activity, act => act.subjectName).map((e) => ({
                                        text: e.subjectName,
                                        value: e.subjectName
                                    })),
                                    filterSearch: true,
                                    onFilter: (value, record) => record.subjectName.indexOf(value) === 0,
                                },
                                {
                                    title: 'Topic Name',
                                    key: 'topicName',
                                    dataIndex: 'topicName',
                                    width: '200px',
                                    sorter: (a, b) => a.topicName.localeCompare(b.topicName),
                                    filters: uniqueByKey(activity, act => act.topicName).map((e) => ({
                                        text: e.topicName,
                                        value: e.topicName
                                    })),
                                    filterSearch: true,
                                    onFilter: (value, record) => record.topicName.indexOf(value) === 0,
                                },
                                {
                                    title: 'Module Type',
                                    key: 'type',
                                    dataIndex: 'type',
                                    width: '200px',
                                    sorter: (a, b) => a.type.localeCompare(b.type),
                                    filters: uniqueByKey(activity, act => act.type).map((e) => ({
                                        text: e.type,
                                        value: e.type
                                    })),
                                    filterSearch: true,
                                    onFilter: (value, record) => record.type.indexOf(value) === 0,
                                },
                                {
                                    title: 'Activity Type',
                                    key: 'activityType',
                                    dataIndex: 'activityType',
                                    width: '200px',
                                    sorter: (a, b) => a.activityType.localeCompare(b.activityType),
                                    filters: uniqueByKey(activity, act => act.activityType).map((e) => ({
                                        text: e.activityType,
                                        value: e.activityType
                                    })),
                                    filterSearch: true,
                                    onFilter: (value, record) => record.activityType.indexOf(value) === 0,
                                },
                                {
                                    title: 'Direction',
                                    key: 'directions',
                                    dataIndex: 'directions',
                                    width: '200px',
                                    sorter: (a, b) => a.directions.localeCompare(b.directions),
                                    ellipsis: true,
                                },
                                {
                                    title: 'Retry',
                                    key: 'retryLimit',
                                    dataIndex: 'retryLimit',
                                    width: '200px',
                                    sorter: (a, b) => a.retryLimit.localeCompare(b.retryLimit),
                                },
                                {
                                    title: 'Score Validation',
                                    key: 'scoreValidation',
                                    dataIndex: 'scoreValidation',
                                    width: '200px',
                                    sorter: (a, b) => a.scoreValidation.localeCompare(b.scoreValidation),
                                    render(row) {
                                        return row === 0 ? 'No' : 'Yes'
                                    }
                                },
                                {
                                    title: 'Save Answers',
                                    key: 'isSaveAnswers',
                                    dataIndex: 'isSaveAnswers',
                                    width: '200px',
                                    sorter: (a, b) => a.isSaveAnswers.localeCompare(b.isSaveAnswers),
                                    render(row) {
                                        return row === 0 ? 'No' : 'Yes'
                                    }
                                },
                                {
                                    title: 'Upload Required',
                                    key: 'isUploadRequired',
                                    dataIndex: 'isUploadRequired',
                                    width: '200px',
                                    sorter: (a, b) => a.isUploadRequired.localeCompare(b.isUploadRequired),
                                    render(row) {
                                        return row === 0 ? 'No' : 'Yes'
                                    }
                                },
                            ]}
                            rowKey="id"
                            pagination={{
                                paginationSize: 10,
                            }}
                            scroll={{
                                y: 350,
                                x: '100vw',
                            }}
                            loading={isLoadingActivity}
                        />
                        <Drawer
                            title={activityType + ' - ' + parentMenuTitle}
                            placement="bottom"
                            closable={false}
                            onClose={onClose}
                            visible={visible}
                            key={parentMenuTitle}
                            className="demo"
                            height={childrenVisible || editDrawer ? "70%" : "92%"}
                            extra={
                                <Space>
                                    {activityType === 'POWTOON' &&
                                        <Button type="primary"
                                            onClick={() => {
                                                setChildrenVisible(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                    }
                                    {activityType !== 'POWTOON' &&
                                        <Button type="primary"
                                            onClick={() => {
                                                addModuleQuestion(activityType, moduleId)
                                            }}
                                        >
                                            Add
                                        </Button>
                                    }
                                </Space>
                            }
                        >
                            {activityType === 'POWTOON' && <PowtoonView searchViewDataSource={searchViewDataSource} pause={visible} />}
                            {activityType === 'Whack A Mole' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        dataSource={searchViewDataSource.activity.choices.filter((choice, index) => {
                                            return choice.status != 1
                                        })}
                                        columns={[
                                            {
                                                title: 'Choice',
                                                dataIndex: 'choiceId',
                                                key: 'choiceId',
                                                editable: true,
                                                render: (item, record, index) => {
                                                    return 'Choice ' + (index + 1) + '.'
                                                },
                                            },
                                            {
                                                title: 'Action',
                                                dataIndex: '',
                                                key: 'x',
                                                render: (row) => (
                                                    editingKeyQuestion.editable && editingKeyQuestion.questionId === row.questionId ? (
                                                        <Space>
                                                            <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestion(row.questionId)}>
                                                                <a>Save</a>
                                                            </Popconfirm>
                                                            <Typography.Link
                                                                onClick={cancelQuestion}
                                                            >
                                                                Cancel
                                                            </Typography.Link>
                                                        </Space>
                                                    ) : (
                                                        <Space>
                                                            <Typography.Link
                                                                className='update-profile__edit-button'
                                                                onClick={() => {
                                                                    // setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                    // setChoices(choice.isCorrect === 1 ? true : false);
                                                                    // setValue(choice.description ? 1 : 2)
                                                                    // form.setFieldsValue({ description: choice.description })
                                                                }}
                                                            >
                                                                Edit
                                                            </Typography.Link>
                                                            <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(row.questionId)}>
                                                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                            </Popconfirm>
                                                        </Space>
                                                    )
                                                )
                                            },
                                        ]}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => (
                                                <>
                                                    <Text>Answer: </Text>
                                                    <Row>
                                                        <Col xs={1} md={1} lg={1}>
                                                            {record.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                        </Col>
                                                        <Image
                                                            width={100}
                                                            src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                        // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                        />
                                                    </Row>
                                                </>
                                            ),
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="choiceId"
                                        pagination={{
                                            paginationSize: 10,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Matching Type' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity.questions}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <Text>Answer: </Text>
                                                    {searchViewDataSource.activity.choices.map((choice, index) => {
                                                        return (
                                                            <Row key={index}>
                                                                <Col xs={1} md={1} lg={1}>
                                                                    {choice.questionId === record.questionId ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                </Col>
                                                                {choice.description !== '' ? (
                                                                    <Col xs={22} md={22} lg={22}>{choice.description}</Col>
                                                                ) : (
                                                                    <Image
                                                                        width={200}
                                                                        src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                    // src={'http://' + window.location.hostname + ':9003/' + choice.image}
                                                                    />
                                                                )}
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'QuizShow' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    {editable ? (
                                                        <>
                                                            <Form.Item name="time" label="Time" {...config}>
                                                                <TimePicker
                                                                    style={{
                                                                        width: '90%',
                                                                    }}
                                                                    defaultValue={moment(convertMiliseconds(record.timeLimit), 'HH:mm:ss')}
                                                                    format={'mm:ss'}
                                                                />
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Time Limit : </h5>
                                                            {convertMilisecondsTime(record.timeLimit)}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={8} md={8} lg={8}>
                                                                                <Form.Item
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                >
                                                                                    <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                                                                        <Radio value={1}>Text</Radio>
                                                                                        <Radio value={2}>Image</Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={11} md={11} lg={11}>
                                                                                {value === 2 ? (
                                                                                    <Form.Item
                                                                                        name="upload"
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Please attach an image.',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Upload
                                                                                            listType="picture-card"
                                                                                            {...props}
                                                                                            maxCount={1}
                                                                                            name="upload"
                                                                                            style={{ width: '32px', height: '32px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please attach an image.',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <div>
                                                                                                <PlusOutlined />
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop: 8,
                                                                                                    }}
                                                                                                >
                                                                                                    Upload
                                                                                                </div>
                                                                                            </div>
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                ) : (
                                                                                    <Form.Item
                                                                                        name="description"
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Description is required",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={editingKeyChoices.description}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'True or False' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    {editable ? (
                                                        <>
                                                            <Text>Current Answer: </Text>
                                                            <Row>
                                                                <Col xs={1} md={1} lg={1}>
                                                                    <li>
                                                                        <input type="checkbox" id={`cb` + 1} onChange={(e) => checkBoxImage(e, 1)} />
                                                                        <label htmlFor={`cb` + 1}>
                                                                            <div className="icon">
                                                                                {choices === true ?
                                                                                    <CheckSquareTwoTone
                                                                                        style={{ fontSize: '20px' }}
                                                                                    /> :
                                                                                    <CloseSquareOutlined
                                                                                        style={{ fontSize: '20px' }}
                                                                                    />}
                                                                            </div>
                                                                        </label>
                                                                    </li>
                                                                </Col>
                                                                <Col xs={22} md={22} lg={22}>
                                                                    <Text>
                                                                        {choices === 1 ? 'True' : 'False'}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Text>Answer: </Text>
                                                            <Row>
                                                                <Col xs={1} md={1} lg={1}>
                                                                    {record.isTrue === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                </Col>
                                                                <Col xs={22} md={22} lg={22}>
                                                                    <Text>
                                                                        {record.isTrue === 1 ? 'True' : 'False'}
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Word Sentence Completion' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={19} md={19} lg={19}>
                                                                                <Form.Item
                                                                                    name="description"
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: "Description is required",
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input
                                                                                        defaultValue={editingKeyChoices.description}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}

                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Fill in the Blanks' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        columns={[
                                            {
                                                title: 'Question',
                                                dataIndex: 'question',
                                                key: 'questionId',
                                                render: (item, record, index) => {
                                                    return (index + 1) + '. ' + item
                                                }
                                            },
                                            {
                                                title: 'Action',
                                                dataIndex: '',
                                                key: 'x',
                                                render: (row) => (
                                                    editingKeyQuestion.editable && editingKeyQuestion.questionId === row.questionId ? (
                                                        <Space>
                                                            <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveQuestion(row.questionId)}>
                                                                <a>Save</a>
                                                            </Popconfirm>
                                                            <Typography.Link
                                                                onClick={cancelQuestion}
                                                            >
                                                                Cancel
                                                            </Typography.Link>
                                                        </Space>
                                                    ) : (
                                                        <Space>
                                                            <Typography.Link
                                                                className='update-profile__edit-button'
                                                                onClick={() => {
                                                                    // setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                    // setChoices(choice.isCorrect === 1 ? true : false);
                                                                    // setValue(choice.description ? 1 : 2)
                                                                    // form.setFieldsValue({ description: choice.description })
                                                                }}
                                                            >
                                                                Edit
                                                            </Typography.Link>
                                                            <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeQuestion(row.questionId)}>
                                                                <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                            </Popconfirm>
                                                        </Space>
                                                    )
                                                )
                                            },
                                        ]}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => (
                                                <>
                                                    <Text>Total Items: {record.totalItems}</Text>
                                                </>
                                            ),
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        pagination={{
                                            paginationSize: 10,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Open The Box' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={19} md={19} lg={19}>
                                                                                <Form.Item
                                                                                    name="description"
                                                                                    rules={[
                                                                                        {
                                                                                            required: true,
                                                                                            message: "Description is required",
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Input
                                                                                        defaultValue={editingKeyChoices.description}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}

                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Maze Chase' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={8} md={8} lg={8}>
                                                                                <Form.Item
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                >
                                                                                    <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                                                                        <Radio value={1}>Text</Radio>
                                                                                        <Radio value={2}>Image</Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={11} md={11} lg={11}>
                                                                                {value === 2 ? (
                                                                                    <Form.Item
                                                                                        name="upload"
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Please attach an image.',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Upload
                                                                                            listType="picture-card"
                                                                                            {...props}
                                                                                            maxCount={1}
                                                                                            name="upload"
                                                                                            style={{ width: '32px', height: '32px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please attach an image.',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <div>
                                                                                                <PlusOutlined />
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop: 8,
                                                                                                    }}
                                                                                                >
                                                                                                    Upload
                                                                                                </div>
                                                                                            </div>
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                ) : (
                                                                                    <Form.Item
                                                                                        name="description"
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Description is required",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={editingKeyChoices.description}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Spin A Wheel' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={8} md={8} lg={8}>
                                                                                <Form.Item
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                >
                                                                                    <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                                                                        <Radio value={1}>Text</Radio>
                                                                                        <Radio value={2}>Image</Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={11} md={11} lg={11}>
                                                                                {value === 2 ? (
                                                                                    <Form.Item
                                                                                        name="upload"
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Please attach an image.',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Upload
                                                                                            listType="picture-card"
                                                                                            {...props}
                                                                                            maxCount={1}
                                                                                            name="upload"
                                                                                            style={{ width: '32px', height: '32px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please attach an image.',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <div>
                                                                                                <PlusOutlined />
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop: 8,
                                                                                                    }}
                                                                                                >
                                                                                                    Upload
                                                                                                </div>
                                                                                            </div>
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                ) : (
                                                                                    <Form.Item
                                                                                        name="description"
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Description is required",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={editingKeyChoices.description}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            {activityType === 'Multiple Choice' && (
                                <ViewActivity
                                    searchViewDataSource={searchViewDataSource}
                                    onModuleEditDirection={onModuleEditDirection}
                                >
                                    <Table
                                        id="questionId"
                                        dataSource={searchViewDataSource.activity}
                                        deleteQuestion={
                                            (value) => {
                                                setDeleteModuleQuestionModalVisible(true)
                                                setQuestionId(value)
                                            }
                                        }
                                        columns={mergedColumns}
                                        defaultExpandAllRows={true}
                                        expandable={{
                                            expandedRowRender: (record) => {
                                                const editable = isEditing(record);
                                                return <>
                                                    {editable ? (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            <Form.Item
                                                                name="image"
                                                                validateTrigger={['onChange', 'onBlur']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please attach an image.',
                                                                    },
                                                                ]}
                                                            >
                                                                <Upload
                                                                    listType="picture-card"
                                                                    {...props}
                                                                    maxCount={1}
                                                                    name="upload"
                                                                    defaultFileList={[{
                                                                        uid: '-1',
                                                                        name: 'abc.png',
                                                                        status: 'done',
                                                                        url: process.env.REACT_APP_SCHOOL_BOOK + record.image,
                                                                        // url: 'http://' + window.location.hostname + ':9003/' + record.image,
                                                                    },]}
                                                                    style={{ width: '32px', height: '32px' }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please attach an image.',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <div>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>Question Image: </h5>
                                                            {record.image ? (
                                                                <Image
                                                                    width={200}
                                                                    src={process.env.REACT_APP_SCHOOL_BOOK + record.image}
                                                                // src={'http://' + window.location.hostname + ':9003/' + record.image}
                                                                />
                                                            ) : (
                                                                <Text>No Data Available</Text>
                                                            )}
                                                        </>
                                                    )}
                                                    <br />
                                                    <br />
                                                    <h5>Answer: </h5>
                                                    {record.choices.map((choice, index) => {
                                                        return (
                                                            <Form
                                                                name="edit-choice"
                                                                autoComplete="off"
                                                                form={form}
                                                                requiredMark={true}
                                                                key={index}
                                                            >
                                                                <Row>
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        null
                                                                    ) : (
                                                                        <Col xs={1} md={1} lg={1}>
                                                                            {choice.isCorrect === 1 ? <CheckSquareTwoTone style={{ fontSize: '20px' }} /> : <CloseSquareOutlined style={{ fontSize: '20px' }} />}
                                                                        </Col>
                                                                    )}
                                                                    {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                        <>
                                                                            <Col xs={8} md={8} lg={8}>
                                                                                <Form.Item
                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                >
                                                                                    <Radio.Group onChange={(e) => onChangeRadio(e, index)} value={value}>
                                                                                        <Radio value={1}>Text</Radio>
                                                                                        <Radio value={2}>Image</Radio>
                                                                                    </Radio.Group>
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col xs={1} md={1} lg={1}>
                                                                                <li>
                                                                                    <input type="checkbox" id={`cb` + index} onChange={(e) => checkBoxImage(e, index)} />
                                                                                    <label htmlFor={`cb` + index}>
                                                                                        <div className="icon">
                                                                                            {choices === true ?
                                                                                                <CheckSquareTwoTone
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                /> :
                                                                                                <CloseSquareOutlined
                                                                                                    style={{ fontSize: '20px' }}
                                                                                                />}
                                                                                        </div>
                                                                                    </label>
                                                                                </li>
                                                                            </Col>
                                                                            <Col xs={11} md={11} lg={11}>
                                                                                {value === 2 ? (
                                                                                    <Form.Item
                                                                                        name="upload"
                                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: 'Please attach an image.',
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Upload
                                                                                            listType="picture-card"
                                                                                            {...props}
                                                                                            maxCount={1}
                                                                                            name="upload"
                                                                                            style={{ width: '32px', height: '32px' }}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message: 'Please attach an image.',
                                                                                                },
                                                                                            ]}
                                                                                        >
                                                                                            <div>
                                                                                                <PlusOutlined />
                                                                                                <div
                                                                                                    style={{
                                                                                                        marginTop: 8,
                                                                                                    }}
                                                                                                >
                                                                                                    Upload
                                                                                                </div>
                                                                                            </div>
                                                                                        </Upload>
                                                                                    </Form.Item>
                                                                                ) : (
                                                                                    <Form.Item
                                                                                        name="description"
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: "Description is required",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={editingKeyChoices.description}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <Col xs={19} md={19} lg={19}>{choice.description}</Col>
                                                                    )}
                                                                    <Col xs={4} md={4} lg={4} style={{ paddingLeft: "14px" }}>
                                                                        {editingKeyChoices.editable && editingKeyChoices.choiceId === choice.choiceId ? (
                                                                            <Space>
                                                                                <Popconfirm title="Are you sure you want to save this?" onConfirm={() => saveChoices(choice.choiceId)}>
                                                                                    <a>Save</a>
                                                                                </Popconfirm>
                                                                                <Typography.Link
                                                                                    onClick={cancelChoices}
                                                                                >
                                                                                    Cancel
                                                                                </Typography.Link>
                                                                            </Space>
                                                                        ) : (
                                                                            <Space>
                                                                                <Typography.Link
                                                                                    className='update-profile__edit-button'
                                                                                    onClick={() => {
                                                                                        setEditingKeyChoices({ editable: true, choiceId: choice.choiceId, description: choice.description });
                                                                                        setChoices(choice.isCorrect === 1 ? true : false);
                                                                                        setValue(choice.description ? 1 : 2)
                                                                                        form.setFieldsValue({ description: choice.description })
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Typography.Link>
                                                                                <Popconfirm title="Are you sure you want to remove this?" onConfirm={() => removeChoices(choice.choiceId)}>
                                                                                    <a style={{ color: "#ff4d4f" }}>Remove</a>
                                                                                </Popconfirm>
                                                                            </Space>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )
                                                    })}
                                                </>
                                            },
                                        }}
                                        expandIcon={({ expanded, onExpand, record }) =>
                                            expanded ? (
                                                <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            ) : (
                                                <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                                            )
                                        }
                                        rowKey="questionId"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        pagination={{
                                            // paginationSize: 10,
                                            onChange: cancel,
                                        }}
                                        style={{ minWidth: '600px', maxWidth: '600px' }}
                                    />
                                </ViewActivity>
                            )}
                            <ActivityAdd
                                parentMenuTitle={parentMenuTitle}
                                childrenVisible={childrenVisible}
                                addModuleQuestionClose={addModuleQuestionClose}
                                visible={childrenVisible}
                                activityType={activityType}
                                moduleId={moduleId}
                                viewActivityModule={viewActivityModule}
                            />
                            <ActivityEdit
                                parentMenuTitle={parentMenuTitle}
                                childrenVisible={editDrawer}
                                onModuleEditDirectionClose={onModuleEditDirectionClose}
                                visible={editDrawer}
                                activityType={activityType}
                                moduleId={moduleId}
                                viewActivityModule={viewActivityModule}
                                searchViewDataSource={searchViewDataSource}
                                onEdit={onEdit}
                                editRequest={editRequest}
                                editMessage={editMessage}
                                isEditLoading={isEditLoading}
                                editModalVisible={editModalVisible}
                                handleEditOk={handleEditOk}
                            />
                        </Drawer>
                    </Form>
                    <CustomModal
                        isModalVisible={isModalVisible}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        title={message?.message}
                    >
                        <Text>{message?.message}</Text>
                    </CustomModal>
                    <CustomModal
                        isModalVisible={deleteModuleModalVisible}
                        handleOk={handleOkChoice}
                        handleCancel={handleCancelChoice}
                        title="Remove"
                    >
                        <Text>Do you want to remove this ?</Text>
                    </CustomModal>
                    <CustomModal
                        isModalVisible={deleteModuleQuestionModalVisible}
                        handleOk={handleOkQuestion}
                        handleCancel={handleCancelQuestion}
                        title="Remove"
                    >
                        <Text>Do you want to remove this ?</Text>
                    </CustomModal>
                </>
            )
            }
        </>
    );
}

export default ArchiveActivity;