import { Form, Row, Checkbox, Input, Typography, Upload, Button, Radio } from 'antd'
import {
    PlusCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react'
import API_CALL from '../../../../../helpers/api';
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import CustomModal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';

const { Text } = Typography;
const { TextArea } = Input;

const MTQuestion = ({ moduleId, module }) => {
    const authContext = useContext(AuthContext)
    const [question, setQuestion] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [value, setValue] = useState([1]);
    // const [choices, setChoices] = useState([false])
    const [form] = Form.useForm();
    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleMTQuestion`,
        },
        (data) => {
            console.log(data)
            setMessage(data);
            setIsModalVisible(true);
            setIsLoading(false);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => setIsModalVisible(false)
    const handleCancel = () => setIsModalVisible(false)

    const onChangeRadio = (e, index) => {
        let arrayValue = [...value]
        arrayValue[index] = e.target.value
        setValue(arrayValue)
        let arrayChoices = [...value]
        arrayChoices[index] = ''
        const newForm = form.getFieldsValue();
        newForm.answer = ''
        if (e.target.value === 2) {
            form.setFieldsValue(newForm);
        }
    }

    const questionOnChange = (e) => {
        setQuestion(e.target.value);
    }

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const props = {
        accept: ".png, .jpg, .jpeg, .gif, .tiff",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    useEffect(() => {
        form.setFieldsValue({ 'question': question })
    }, [])

    const onFinish = (values) => {
        console.log('Received values of form:', values);
        let formData = new FormData();

        formData.append("image", values?.image === undefined ? '' : values.image?.[0]?.originFileObj);
        formData.append("answerImage", values.answerImage?.file === undefined ? '' : values.answerImage?.file)
        formData.append("question", values.question);
        formData.append("answer", values?.answer || '');
        setIsLoading(true);
        addRequest.sendRequest(formData, { moduleId: moduleId })
    };

    return (
        <>
            <div className="heading">
                <h1>{module}</h1>
            </div>
            <Form
                name="dynamic_form_item"
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    name="image"
                    label="Upload Image: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                    multiple="false"
                >
                    <Upload.Dragger
                        {...props}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                            band files
                        </p>
                    </Upload.Dragger>
                </Form.Item>
                <br></br>
                <Form.Item
                    name="question"
                    label="Question"
                    rules={[
                        {
                            required: true,
                            message: "Please input question",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                    />
                </Form.Item>
                <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                >
                    <Text>Type of choice: </Text>
                    <Radio.Group onChange={(e) => onChangeRadio(e, 0)} value={value[0]}>
                        <Radio value={1}>Text</Radio>
                        <Radio value={2}>Image</Radio>
                    </Radio.Group>
                </Form.Item>
                {value[0] === 2 ? (<Form.Item
                    name="answerImage"
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                        {
                            required: true,
                            message: 'Please attach an image.',
                        },
                    ]}
                >
                    <Upload
                        listType="picture-card"
                        {...props}
                        maxCount={1}
                        name="upload"
                        style={{ width: '32px', height: '32px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please attach an image.',
                            },
                        ]}
                    >
                        <div>
                            <PlusOutlined />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Upload
                            </div>
                        </div>
                    </Upload>
                </Form.Item>) : (
                    <Form.Item
                        name="answer"
                        label="Answer"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please input text or delete this field.",
                            },
                        ]}
                        style={{
                            width: '100%',
                        }}
                    >
                        <Input
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                )}
                {/* <Form.Item
                    name="answer"
                    label="Answer"
                    rules={[
                        {
                            required: true,
                            message: "Please input question",
                        },
                    ]}
                >
                    <Input />
                </Form.Item> */}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                <CustomModal
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
                >
                    <Text>{message?.message}</Text>
                </CustomModal>
                <CustomSpinnerModal isLoading={isLoading} />
            </Form>
        </>
    )
}

export default MTQuestion