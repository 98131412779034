import { Form, Row, Card, Col, Radio, Input, Typography, InputNumber, Button, message as ANTMESSAGE, Space, Select } from 'antd'
import {
    PlusCircleOutlined,
    DeleteOutlined,
    InboxOutlined
} from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react'
import API_CALL from '../../../../../helpers/api';
import useRequest from '../../../../../hooks/use-request';
import CustomSpinnerModal from '../../../../Layout/Modal/Spinner';
import CustomModal from '../../../../Layout/Modal/Modal';
import { AuthContext } from '../../../../../store/use-context';
import Crossword from '@jaredreisinger/react-crossword';
import './style.css';

const { Text } = Typography;
const { TextArea } = Input;

const CWQuestion = ({ moduleId, module, viewAdd, viewActivity, onSubmit }) => {
    // console.log("viewAdd: ", viewAdd)
    const authContext = useContext(AuthContext)
    const [questionShowed, setQuestionShowed] = useState(null);
    // const [answer, setAnswer] = useState([]);
    const [data, setData] = useState({});
    const [moduleActivity, setModuleActivity] = useState(viewActivity);
    const [prevNumber, setPrevNumber] = useState(0);
    const [currNumber, setCurrNumber] = useState(0);
    const [prevAlignment, setPrevAlignment] = useState('ACROSS');
    const [currAlignment, setCurrAlignment] = useState('ACROSS');
    const [totalItems, setTotalItems] = useState(0);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCrossword, setIsLoadingCrossword] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const alignment = Form.useWatch('alignment', form);
    const row = Form.useWatch('row', form);
    const col = Form.useWatch('col', form);
    const number = Form.useWatch('number', form);
    const answer = Form.useWatch('answer', form);
    const question = Form.useWatch('question', form);
    const crossword = useRef();
    const viewActivityModule = useRequest(
        {
            method: 'GET',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')
                }/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/getModule`
        },
        (data) => {
            setModuleActivity(data);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err?.response?.data)
        }
    )
    const addRequest = useRequest(
        {
            method: 'POST',
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') ||
                (authContext.userType === "1" && 'admin-insti') ||
                (authContext.userType === "2" && 'admin-school') ||
                (authContext.userType === "3" && 'encoder')}/${(authContext.userType === "0" && 'modules') ||
                (authContext.userType === "3" && 'data')
                }/addModuleCWQuestion`,
        },
        (data) => {
            console.log(data)
            setMessage(data);
            setTotalItems(0)
            viewAdd === true && onSubmit()
            setIsModalVisible(true);
            setIsLoading(false);
            viewActivityModule.sendRequest(null, { moduleId })
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const handleOk = () => setIsModalVisible(false)
    const handleCancel = () => setIsModalVisible(false)

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };

    const props = {
        accept: "image/png",
        multiple: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 300 / 300 < 2;
            if (!isLt2M) {
                console.log('test')
            }
            return false;
        },
        onChange(info) {
            if (info.file.status !== "removed") {
                let reader = new FileReader();
                reader.readAsDataURL(info.file);
            }
        },
    };

    const onFinish = (values) => {
        setIsLoading(true);
        addRequest.sendRequest({
            question: values.question,
            answer: values?.answer,
            alignment: values.alignment ? values.alignment : "ACROSS",
            row: values.row ? values.row : 0,
            col: values.col ? values.col : 0,
            number: values.number ? values.number : 1,
        }, { moduleId: moduleId })
        form.resetFields()
    };

    useEffect(() => {
        if (viewAdd) {
            const groupedData = moduleActivity?.activity?.reduce((result, item, index) => {
                const { alignment } = item;
                if (!result[alignment?.toLowerCase()]) {
                    result[alignment?.toLowerCase()] = {};
                }
                result[alignment?.toLowerCase()][item?.numberVal] = {
                    questionId: item.questionId,
                    clue: item.question,
                    answer: item.answer.toUpperCase(),
                    row: item.rowVal,
                    col: item.colVal
                };
                return result;
            }, {});

            setData(groupedData)
            setIsLoadingCrossword(false)
        }
    }, [])

    useEffect(() => {
        if (!viewAdd) {
            const groupedData = moduleActivity?.activity?.reduce((result, item, index) => {
                const { alignment } = item;
                if (!result[alignment?.toLowerCase()]) {
                    result[alignment?.toLowerCase()] = {};
                }
                result[alignment?.toLowerCase()][item?.numberVal] = {
                    questionId: item.questionId,
                    clue: item.question,
                    answer: item.answer.toUpperCase(),
                    row: item.rowVal,
                    col: item.colVal
                };
                return result;
            }, {});

            setData(groupedData ? groupedData : {})
            setIsLoadingCrossword(false)
            console.log(groupedData ? groupedData : {})
        }
    }, [moduleActivity])

    useEffect(() => {
        if (number) setPrevNumber(currNumber)
        if (number) setCurrNumber(number)
    }, [number])

    useEffect(() => {
        if (alignment) setPrevAlignment(currAlignment)
        if (alignment) setCurrAlignment(alignment)
        if (alignment) setPrevNumber(currNumber)
        if (alignment) setCurrNumber(number)
    }, [alignment])

    useEffect(() => {
        if (!isLoadingCrossword && data.hasOwnProperty('down') && data.hasOwnProperty('across')) {
            const element = { ...data }

            if (prevAlignment !== currAlignment) {
                delete element[prevAlignment?.toLowerCase()][prevNumber]
                setData(element)
            }
        }
    }, [currAlignment])

    useEffect(() => {
        if (!isLoadingCrossword && data.hasOwnProperty('down') && data.hasOwnProperty('across')) {
            const element = { ...data }

            if (element[currAlignment?.toLowerCase()]?.hasOwnProperty(currNumber)) {
                form.setFieldsValue({ number: 0 })
                setData(element)
                setCurrNumber(0);
                ANTMESSAGE.warning(`${currAlignment} ${currNumber} is aldready existing.`)
            } else if (currNumber) {
                delete element[currAlignment?.toLowerCase()][prevNumber]
                setData(element)
            }

            if (element.hasOwnProperty(currAlignment?.toLowerCase()) && !element[currAlignment?.toLowerCase()]?.hasOwnProperty(currNumber) && currNumber) {
                element[currAlignment?.toLowerCase()][currNumber] = {
                    questionId: '',
                    clue: 'sample',
                    answer: 'sample',
                    row: 0,
                    col: 0
                };
                setData(element)
            }
        }
    }, [currAlignment, currNumber, prevNumber])

    useEffect(() => {
        if (!isLoadingCrossword && data.hasOwnProperty('down') && data.hasOwnProperty('across')) {
            const element = { ...data }
            if ((row || col || answer || question) && currNumber) {
                element[alignment?.toLowerCase()][currNumber] = {
                    questionId: '',
                    clue: question,
                    answer: answer,
                    row: row,
                    col: col
                };
                setData(element)
            }

            if (answer && question) {
                setTimeout(() => crossword?.current?.fillAllAnswers(), 300)
            }
        }
    }, [row, col, answer, question])

    useEffect(() => {
        if (crossword.current && !isLoadingCrossword) {
            setTimeout(() => crossword?.current?.fillAllAnswers(), 300)
        }
    }, [crossword.current])

    return (
        <div>
            <Row>
                {/* <div className="heading">
                <h1>{module}</h1>
            </div> */}
                {!isLoadingCrossword && data?.hasOwnProperty('down') && data?.hasOwnProperty('across') ? <div>
                    <Crossword
                        ref={crossword}
                        data={data}
                        theme={{
                            numberColor: 'rgba(0,0,0, 0.75)',
                            allowNonSquare: false
                        }}
                    />
                </div> : <div>
                    <h3>Requirement to view the crossword: </h3>
                    {!data.hasOwnProperty('across') ? <h4 style={{ color: 'red' }}>* You need to have 1 across question.</h4> : null}
                    {!data.hasOwnProperty('down') ? <h4 style={{ color: 'red' }}>* You need to have 1 down question.</h4> : null}
                </div>}
                <Form
                    name="dynamic_form_item"
                    onFinish={onFinish}
                    form={form}
                    initialValues={{ alignment: "ACROSS", row: 0, col: 0, number: 0, answer: 'sample', question: 'sample' }}
                >
                    <Row>
                        <Space>
                            <Col>
                                <p>Alignment</p>
                                <Form.Item
                                    name="alignment"
                                    title="Alignment"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        options={[
                                            {
                                                value: 'ACROSS',
                                                label: 'Across',
                                            },
                                            {
                                                value: 'DOWN',
                                                label: 'Down',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <p>Number</p>
                                <Form.Item
                                    name="number"
                                    title="Col"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <InputNumber
                                        min={0}
                                        max={999}
                                        controls={false}
                                        maxLength={3}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <p>Row</p>
                                <Form.Item
                                    name="row"
                                    title="Row"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <InputNumber
                                        min={0}
                                        max={999}
                                        controls={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <p>Col</p>
                                <Form.Item
                                    name="col"
                                    title="Col"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <InputNumber
                                        min={0}
                                        max={999}
                                        controls={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                    <br></br>
                    <Form.Item
                        name="question"
                        label="Question"
                    >
                        <TextArea
                            rows={4}
                        />
                    </Form.Item>
                    <Row>
                        <Space>
                            <Col>
                                <p>Answer</p>
                                <Form.Item
                                    name="answer"
                                    title="Answer"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input answer.",
                                        },
                                    ]}
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Input
                                        style={{
                                            width: '100%',
                                        }}
                                        onBlur={() => {
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                    <br></br>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                            Submit
                        </Button>
                    </Form.Item>
                    <CustomModal
                        isModalVisible={isModalVisible}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        title={message?.messagE === 'Added successfully' ? 'Success' : 'Error'}
                    >
                        <Text>{message?.messagE}</Text>
                    </CustomModal>
                    <CustomSpinnerModal isLoading={isLoading} />
                </Form>
            </Row>
        </div>
    )
}

export default CWQuestion