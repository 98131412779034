import {
    Row,
    Button,
    Col,
    Form,
    Input,
    Select,
    DatePicker,
    Typography,
} from 'antd'
import { useEffect, useState, useContext } from 'react'
import '../../../../assets/resources/style/form.css'
import moment from 'moment';
import useRequest from '../../../../hooks/use-request';
import CustomSpinnerModal from '../../../Layout/Modal/Spinner';
import Modal from '../../../Layout/Modal/Modal';
import API_CALL from "../../../../helpers/api";
import { AuthContext } from '../../../../store/use-context';
const { Text } = Typography
const { Option } = Select;

const Student = () => {
    const [section, setSection] = useState([]);
    const [school, setSchool] = useState([]);
    const [gradeLevel, setGradeLevel] = useState(1);
    const [profile, setProfile] = useState({});
    const [schoolId, setSchoolId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSchoolLoading, setIsSchoolLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [message, setMessage] = useState(null);
    const [form] = Form.useForm();
    const authContext = useContext(AuthContext);

    const handleOk = () => {
        setIsModalVisible(false);
        setMessage(null);
    };

    const addRequest = useRequest(
        {
            method: "POST",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/users/addStudent`,
        },
        (data) => {
            setMessage(data);
            setIsLoading(false);
            setIsModalVisible(true);
            form.resetFields();
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    )

    const getSectitons = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSections`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setSection(sortActive);
            setIsLoading(false);
        },
        (err) => {
            setIsLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    );

    const getSchools = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti') || (authContext.userType === "2" && 'admin-school') || (authContext.userType === "3" && 'encoder')}/data/getSchools`,
        },
        (data) => {
            const sortActive = data.sort((a, b) => {
                return a.status - b.status
            }).filter((e) => {
                return e.status === 0;
            })
            setSchool(sortActive);
            setIsSchoolLoading(false);
        },
        (err) => {
            setIsSchoolLoading(false);
            setIsModalVisible(true);
            setMessage(err.response.data)
        }
    );

    const getProfile = useRequest(
        {
            method: "GET",
            endPoint: `api/${(authContext.userType === "2" && 'admin-school')}/profile/getProfile`,
        },
        (data) => {
            setProfile(data);
            setIsLoading(false);
        },
        (err) => {
        }
    );

    const schoolHandler = (e) => {
        setSchoolId({ schoolId: e })
    }

    const gradeLevelOnChange = (e) => {
        console.log(e);
        form.setFieldsValue({ gradeLevel: e })
        setGradeLevel(e);
    }

    useEffect(() => {
        if ((authContext.userType === "0" && 'superadmin') || (authContext.userType === "1" && 'admin-insti')) {
            getSectitons.sendRequest();
            getSchools.sendRequest();
        } else {
            getProfile.sendRequest();
            getSectitons.sendRequest();
        }

        if (schoolId) {
            return
        }
    }, [schoolId, gradeLevel])


    if (isLoading) {
        return <CustomSpinnerModal isLoading={isLoading} />
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="63">+63</Option>
            </Select>
        </Form.Item>
    );

    const config = {
        rules: [
            {
                type: 'object',
                required: true,
                message: 'Please select date!',
            },
        ],
    };

    const onFinish = (values) => {
        console.log(values)

        addRequest.sendRequest({
            studentIdNumber: values.studentIdNumber,
            sectionId: values.section,
            schoolId: values.schoolId,
            firstName: values.firstName,
            middleName: values?.middleName ? values?.middleName : '',
            lastName: values.lastName,
            email: values.email,
            gender: values.gender,
            mobileNumber: values.prefix + values.phone,
            birthdate: moment(values.birthdate._d).format('yyyy-MM-DD'),
            gradeLevel: +values.gradeLevel,
            guardianName: values.guardianName,
            guardianContactNumber: values.guardianContactNumber,
            guardianEmail: values.guardianEmail,
        }, null)
        setIsLoading(true);
    };

    return (
        <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                prefix: '63',
                schoolId: profile.schoolId,
                gradeLevel: gradeLevel
            }}
            scrollToFirstError
        >
            <Row className='row'>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="studentIdNumber"
                        label="Student Id Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="schoolId"
                        label="School"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select school"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            onChange={schoolHandler}
                        >
                            {profile && <Option value={profile?.schoolId}>{profile?.name}</Option>}
                            {school.map((obj, index) => {
                                return (
                                    <Option key={index} value={obj.schoolId}>{obj.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="gradeLevel"
                        label="Grade Level"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            onChange={gradeLevelOnChange}
                            initialvalues={0}
                        >
                            {/* <Option value={99}>Kindergarten 1</Option> */}
                            {/* <Option value={98}>Kindergarten 2</Option> */}
                            <Option value={1}>Grade 1</Option>
                            <Option value={2}>Grade 2</Option>
                            <Option value={3}>Grade 3</Option>
                            <Option value={4}>Grade 4</Option>
                            <Option value={5}>Grade 5</Option>
                            <Option value={6}>Grade 6</Option>
                            <Option value={7}>Grade 7</Option>
                            <Option value={8}>Grade 8</Option>
                            <Option value={9}>Grade 9</Option>
                            <Option value={10}>Grade 10</Option>
                            {/* <Option value={11}>Grade 11</Option> */}
                            {/* <Option value={12}>Grade 12</Option> */}

                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Form.Item
                        name="section"
                        label="Section"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Select
                            showSearch
                            placeholder="Select section"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {/* {section.filter((obj) => {
                                console.log(obj)
                                return obj.schoolId === schoolId.schoolId
                            })} */}
                            {section.map((obj, index) => {
                                return (obj.gradeLevel === form.getFieldValue('gradeLevel') && obj.schoolId === schoolId.schoolId) && <Option key={index} value={obj.sectionId}>{obj.name}</Option>

                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row className='row'>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your first name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="middleName"
                        label="Middle Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your last name',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="phone"
                        label="Mobile Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <Form.Item
                        name="gender"
                        label="Gender"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select your gender'
                            }
                        ]}
                    >
                        <Select
                            initialvalues={0}
                        >
                            <Option value={0}>Male</Option>
                            <Option value={1}>Female</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={4}>
                    <Form.Item
                        name="birthdate"
                        label="Birthdate"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        {...config}
                        style={{ width: '100%', margin: 5 }}
                    >
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24} lg={8}>
                    <Form.Item
                        name="guardianName"
                        label="Guardian Name"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5, paddingRight: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your guardian name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="guardianContactNumber"
                        label="Guardian Mobile Number"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your guardian phone number!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%',
                            }}
                            maxLength={10}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <Form.Item
                        name="guardianEmail"
                        label="Guardian E-mail Address"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your guardian e-mail address!',
                            },
                        ]}
                        style={{ margin: 5 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24} lg={12}>
                    <Form.Item
                        name="address"
                        label="Address"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ margin: 5, paddingRight: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your address',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row className='button'>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Row>
            <Modal
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                title={message?.message === 'Added successfully' ? 'Success' : 'Error'}
            >
                <Text>{message?.message}</Text>
            </Modal>
            <CustomSpinnerModal isLoading={isLoading || isSchoolLoading} />
        </Form >
    )
}

export default Student